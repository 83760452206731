export const ActionTypes = {
    GET_FUNDRAISE_APPLICATIONS_LIST: "GET_FUNDRAISE_APPLICATIONS_LIST",
    EDIT_FUNDRAISE_APPLICATIONS: "EDIT_FUNDRAISE_APPLICATIONS",
    ADD_FUNDRAISE_APPLICATIONS_LIST: "ADD_FUNDRAISE_APPLICATIONS_LIST",
    DELETE_FUNDRAISE_APPLICATIONS: "DELETE_FUNDRAISE_APPLICATIONS"
};


export const getFundraiseApplicationsList = (fundraise) => {
    return {
       type: ActionTypes.GET_FUNDRAISE_APPLICATIONS_LIST,
       payload: fundraise,
    }
}

export const addFundraiseApplication = (fundraise) => {
    return {
       type: ActionTypes.ADD_FUNDRAISE_APPLICATIONS_LIST,
       payload: fundraise,
    }
}

export const editFundraiseApplication = (fundraise) => {
    return {
       type: ActionTypes.EDIT_FUNDRAISE_APPLICATIONS,
       payload: fundraise,
    }
}

export const deleteFundraiseApplicationRdx = (fundraise) => {
    return {
       type: ActionTypes.DELETE_FUNDRAISE_APPLICATIONS,
       payload: fundraise,
    }
}