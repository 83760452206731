import { GET_CHARITY_FUNDRAISE_API_URL } from "../components/Shared/constant";

const GetCharityFundraise = async (accessToken, userSearch, slug, userLimit, currentPage) => {
    const responce = window.axios.get(`${GET_CHARITY_FUNDRAISE_API_URL}${slug}/${userLimit}/${currentPage}/${userSearch === "" ? "0" : userSearch}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + accessToken,
    }}).then(function (result) {
        return {
          ...result.data,
          status:200
        };
    }).catch(function (result) {
        return {
          ...result?.response?.data,
          status:result?.response?.status
        }
    });
    return responce;
};
  
export default GetCharityFundraise;