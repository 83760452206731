/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';
import moment from "moment";
import { Form, Badge, Image, Collapse, FormControl } from 'react-bootstrap';
import FiltersForm from "../Common/FiltersForm";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import CommonPagination from "../Common/CommonPagination";
import CommonModal from "../Common/CommonModal";
import GetEmailCampaign from "../../Api/GetEmailCampaign";
import { setStoreMailCampaignList, setStoreMosaicList } from "../../redux/actions/campaignAction";
import { CAMPAIGN_MAIL_LIST, LIST_MOSAIC_SOURCE_IMAGE, LIST_SETTING_MODULE, MAIL_CAMPAIGN_MANAGEMENT, MOSAIC_MANAGEMENT, SETTING_MODULE, UPDATE_SETTING_MODULE } from "../Shared/constant";
import EyeIcon from "../../assets/img/edit.png";
import { GetRequestCallAPI } from "../../Api/GetRequest";
import Download from "../../assets/img/Download.svg";
import { getSettingModuleList } from "../Shared/utils";
import Delete from "../../assets/img/delete.png";
import { PostRequestAPI } from "../../Api/PostRequest";


const SettingModule = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { mosaicList, sponsorsData } = useSelector((state) => state.campaignReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const [ ShowModalTemplate, setShowModalTemplate ] = useState(null)
        const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [ paginationLength, setPaginationLength ] = useState({});

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
        fetchData(page,userLimit,userType)
    };
    
    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    const [abortController, setAbortController] = useState({});

    useEffect(() => {
        fetchData(1,userLimit,userType);
         return () => {
            if(abortController.abort){
                abortController.abort();
                setAbortController(new AbortController());
            }
        };
    }, [ userSearch ]);

    // Get data from api
    const fetchData = async (currentPage) => {
       dispatch(await getSettingModuleList())
        // console.log("ContactList", ContactList)
        // if(ContactList?.status === 200) {
        //     dispatch(setStoreMosaicList(ContactList?.data?.data));
        //     setPaginationLength(ContactList?.data?.pagination);
        // } else if(ContactList?.status === 403) {
        //     CommonLogout();
        //     dispatch(setStoreMosaicList([]));
        //     setPaginationLength({})
        // } else {
        //     dispatch(setStoreMosaicList([]));
        //     setPaginationLength({})
        // };
    };

    const callUserLimit = (e) =>{
        setUserLimit(e);
        fetchData(1, e,userType)
    }
      
    // const callDeleteSponsors = async (item) =>{
    //     const oldSponsors = sponsorsData?.facefunding_sponsors?.filter(item1=>item1?.id !== item?.id)
    //     const response = await PostRequestAPI(UPDATE_SETTING_MODULE, {data:{facefunding_sponsors: oldSponsors}}, accessToken);
    //     console.log("response", response)
    // }
        // Delete Campaign 
        const callDeleteSponsors = (Sponsors) => {
            setShowModal({
                ...showModal,
                open: !showModal?.open,
                title: "Are you sure?",
                description: "Do you really want to delete this sponsors",
                modalType: "not form",
                button: "Delete Sponsors",
                category: "Delete Sponsors",
                Data: Sponsors
            });
        };
    const FilterList = sponsorsData?.facefunding_sponsors?.filter((item, )=> item?.sponsors_name?.toLowerCase()?.includes(userSearch?.toLowerCase()))
    return(<>
        
        <FiltersForm 
            filterType={"( Sponsors Name )"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userLimit={userLimit} 
            setUserLimit={callUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            onAdd={()=>navigate(`/add${SETTING_MODULE}`)}
            add_text={"Add Sponsors"}
            pageTab
        />
        <div className="tableContent">
            <Scrollbars 
                style={{ height: paginationLength?.total_records > 10 ? "calc(100% - 87px)" : "calc(100% - 50px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                                 <thead>
                                     <tr>
                                         <th>Logo</th>
                                         <th>Sponsors Name</th>
                                         <th>Created At</th>
                                         <th>Action</th>
                                     </tr>
                                 </thead>
                        <tbody>
                            {FilterList && FilterList?.map((user, index) => {
                                return(<React.Fragment key={index}>
                                <tr style={{
                                        textTransform:"capitalize"
                                    }} className={activeTableDetails === user?.id ? "active" : ""}>
                                    <td >
                                        <div class="avatar">
                                            <img src={user?.sponsors_logo?.file_url_700} alt="FaceFund" class="userprofile"/>
                                        </div>
                                    </td>
                                    <td>{user?.sponsors_name}</td>
                                      <td>{moment(user.created_at).format('LL HH:mm A')}</td>
                                      <td>
                                        <button className="btn actionbtn" onClick={()=>navigate(`/edit${SETTING_MODULE}/${user?.id}`)} >
                                            <Image  src={EyeIcon} alt="delete icon" />
                                        </button>
                                        <button className="btn actionbtn" onClick={()=>callDeleteSponsors(user)} >
                                            <Image  src={Delete} alt="delete icon" />
                                        </button>
                                    </td>
                                </tr>
                            </React.Fragment>)
                            })}
                            {FilterList && FilterList.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>Sponsors Not Found !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
            {/* pagination */}
            {/* {parseInt(paginationLength?.total_records) > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={paginationLength}
                currentFunction={currentFunction}
            />)} */}
        </div>
        {showModal?.open && (<CommonModal onCloseModal={fetchData} showModal={showModal} setShowModal={setShowModal} />)}
    </>)
}

export default SettingModule;