export const ActionTypes = {
    IS_LOGIN: "IS_LOGIN",
    ADMIN_LOGOUT: "ADMIN_LOGOUT",
    SIDEBAR_CLOSE: "SIDEBAR_CLOSE",
    SELF_DETAILS: "SELF_DETAILS",
    GET_ACCESSTOKEN: "GET_ACCESSTOKEN",
    MOSAIC_LOGIN:"MOSAIC_LOGIN",
    PARENT_COMPAIGN_ID:"PARENT_COMPAIGN_ID"

};

export const checkLogin = (status) => {
    return {
        type: ActionTypes.IS_LOGIN,
        payload: status,
    }
}



export const sidebarClose = (sidebarstatus) => {
    return {
        type: ActionTypes.SIDEBAR_CLOSE,
        payload: sidebarstatus,
    }
}

export const getSelfDetails = (user) => {
    return {
        type: ActionTypes.SELF_DETAILS,
        payload: user,
    }
}

export const getAccessToken = (token) => {
    return {
        type: ActionTypes.GET_ACCESSTOKEN,
        payload: token,
    }
}

export const clearRedux = () => {
    return {
       type: ActionTypes.ADMIN_LOGOUT,
    }
}
export const checkMosaicLogin = (status) =>{
    return {
        type: ActionTypes.MOSAIC_LOGIN,
        payload: status,
    }
}
export const parentCompaignID = (id) => {
    return {
       type: ActionTypes.PARENT_COMPAIGN_ID,
       payload: id,
    }
}
