import React, { useState } from "react";
import { Form, Button, Image } from 'react-bootstrap';
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import CheckValid from "../Common/CheckValid";
import { MOSAIC_MANAGEMENT,DASHBOARD_URL } from "../Shared/constant";
import { useNavigate } from "react-router-dom";
import LoginAPI from "../../Api/LoginAPI";
import GuestLayout from "../Shared/GuestLayout";
import { useDispatch } from "react-redux";
import { getSelfDetails, getAccessToken, checkLogin,checkMosaicLogin,parentCompaignID } from "../../redux/actions/personalActions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LogoImage from "../../assets/img/Logo-reg.png";

const Login = () => {
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line
    const [ PasswordShow, setPasswordShow ] = useState(false);
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    
    // error message
    const [ errorEmail, setErrorEmail ] = useState("");
    const [ errorPassword, setErrorPassword ] = useState("");

    // sign in function
    const SignInButton = async () => {
        if(email !== "" && password !== "") {
            if(EmailRegex.test(email) === true && PasswordRegex.test(password) === true) {
                const jsonData = JSON.stringify({ email: email, password: password });
                const response = await LoginAPI(jsonData);
                if(response?.status === 200){
                    if(response?.data?.user?.permission==="mosaic-management" && response?.data?.user?.parent_campaign){
                        // "permission": "mosaic-management,donation-management",
                        setTimeout(()=>{
                            toast.success(response.msg);
                            Navigate(MOSAIC_MANAGEMENT);
                            dispatch(getSelfDetails(response?.data?.user));
                            dispatch(getAccessToken(response?.data?.access_token));
                            dispatch(parentCompaignID(response?.data?.user?.parent_campaign))
                            dispatch(checkMosaicLogin("yes"));
                            clearForm();
                        },100)
                    }else if(response?.data?.user?.permission==="mosaic-management,donation-management" && response?.data?.user?.parent_campaign){
                        setTimeout(()=>{
                            toast.success(response.msg);
                            Navigate(MOSAIC_MANAGEMENT);
                            dispatch(getSelfDetails(response?.data?.user));
                            dispatch(getAccessToken(response?.data?.access_token));
                            dispatch(parentCompaignID(response?.data?.user?.parent_campaign))
                            dispatch(checkMosaicLogin("yes"));
                            clearForm();
                        },100)
                    }else{
                        setTimeout(() => {
                            toast.success(response.msg);
                            Navigate(DASHBOARD_URL);
                            dispatch(getSelfDetails(response?.data?.user));
                            dispatch(getAccessToken(response?.data?.access_token));
                            dispatch(checkLogin("yes"));
                            clearForm();
                        }, 100);
                    }
                } else {
                    toast.error(response.error);
                };
            } else {
                CheckValid(email, {type: 'email', error: setErrorEmail });
                CheckValid(password, {type: 'password', error: setErrorPassword });
            }
        } else {
            CheckValid(email, {type: 'email', error: setErrorEmail });
            CheckValid(password, {type: 'password', error: setErrorPassword });
        };
    };

    // clear form
    const clearForm = () => {
        setEmail("");
        setErrorEmail("");
        setErrorPassword("");
        setPassword("");
    };

    return(<GuestLayout>
        <div className="loginWrapper">
        {/* <h1 className="logoheader">FaceFund</h1> */}
        <h1 className="logoheader">
            <Image src={LogoImage} alt="logo" />
        </h1>
        <div className="loginMainblog">
            <h4>Login to your account</h4>
            <div className="formloginset">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" value={email} onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: setErrorEmail })} onChange={(e) => setEmail(e.target.value)} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter email" />
                    {errorEmail && <div className="danger-colorset">{errorEmail}</div>}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <div className="passwordfiled">
                        <Form.Control type={PasswordShow ? "text" : "password"} value={password} onKeyUp={(e) => CheckValid(e.target.value, {type: 'password', error: setErrorPassword })} onChange={(e) => setPassword(e.target.value)} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Password" />
                        <div className="showpassbtn" onClick={() => setPasswordShow(!PasswordShow)}>
                            {PasswordShow ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path></svg>) :
                            (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg>)}
                        </div>
                    </div>
                    {errorPassword && <div className="danger-colorset">{errorPassword}</div>}
                </Form.Group>
                <Button onClick={SignInButton} className="signInbtn">Sign In</Button>
            </div>
        </div>
    </div>
    </GuestLayout>)
}

export default Login;