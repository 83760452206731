import { CAMPAIGN_MAIL_LIST } from "../components/Shared/constant";

const GetEmailCampaign = async (accessToken, userSearch, userType, userLimit, currentPage,{signal}={}) => {
    const response = window.axios.get(`${CAMPAIGN_MAIL_LIST}/${userLimit}/${currentPage}/${userSearch === "" ? "0" : userSearch}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + accessToken,
    }, signal:signal}).then(function (result) {
        return {
          ...result.data,
          status:200
        };
    }).catch(function (result) {
        return {
          ...result?.response?.data,
          status:result?.response?.status
        }
    });
    return response;
};
  
export default GetEmailCampaign;