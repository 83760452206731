/* eslint-disable */
import React, { useState, useEffect } from "react";
import GetFundraiseDonationsAPI from "../../Api/GetFundraiseDonationsAPI";
import FiltersForm from "../Common/FiltersForm";
import CommonLogout from "../Common/CommonLogout";
import { Scrollbars } from 'react-custom-scrollbars-2';
import moment from "moment";
import CommonPagination from "../Common/CommonPagination";
import { useDispatch } from "react-redux";
import { Image, Collapse, Form, Badge } from "react-bootstrap";
import MoreIcon from '../../assets/img/more_options.png';

const FundraiseDonationsTab = (props) => {
    const { slug, accessToken, setLoaderStart } = props;
    const dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ paginationLength, setPaginationLength ] = useState({});
    const [ charityFundraiseList, setCharityFundraiseList ] = useState([]);
    const [ activeTableDetails, setActiveTableDetails ] = useState("");

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
        fetchData(page,userLimit,userType);
    };

    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
        fetchData(1,userLimit,e.target.value);
    };
    const [abortController, setAbortController] = useState({});

    useEffect(() => {
        fetchData(1,userLimit,userType);
        return () => {
            if(abortController.abort){
                abortController.abort();
                setAbortController(new AbortController());
            }
        };
    }, [ userSearch ]);

    // Get data from api
    const fetchData = async (currentPage,userLimit,userType) => {
        setLoaderStart(true);
        const signal = abortController.signal;
        setCurrentPage(currentPage)
        const FundraisedonationList = await GetFundraiseDonationsAPI(accessToken, slug, userLimit, currentPage, {signal});
        if(FundraisedonationList?.status === 200) {
            setCharityFundraiseList(FundraisedonationList?.data);
            setPaginationLength(FundraisedonationList.pagination);
            setLoaderStart(false);
        } else if(FundraisedonationList?.status === 403) {
            CommonLogout();
            setCharityFundraiseList([]);
            setLoaderStart(false);
        } else {
            setCharityFundraiseList([]);
            setLoaderStart(false);
        };
    };

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    const callUserLimit = (e) =>{
        setUserLimit(e);
        fetchData(1, e,userType)
    }

    return(<div className="tabsWrappersnew">
        <FiltersForm 
            filterType={"Donations Fundraise (Name)"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={callUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
        />
        <div style={{ float: "left", width: "100%", position: "relative",height: "calc(100vh - 223px)" }}>
            <Scrollbars 
                style={{ maxHeight: paginationLength?.total_records > 10 ? "calc(100%)" : "calc(100%)"}} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Fundraise Name</th>
                                <th>Payment Method</th>
                            </tr>
                        </thead>
                        <tbody>
                            {charityFundraiseList && charityFundraiseList?.map((user, index) => {
                                return(<React.Fragment key={index}>
                                <tr>
                                    <td style={{ width: '10px' }}>
                                        <button 
                                            className={activeTableDetails === user?.id ? "btn actionbtn active" : "btn actionbtn"}
                                            onClick={() => activeTableDetails === user?.id ? ElseMoreDetails() : ViewMoreDetails(user?.id)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={activeTableDetails === user?.id ? true : false}
                                        >
                                            <Image src={MoreIcon} alt="more icon" />
                                        </button>
                                    </td>
                                    <td>{user?.user_name}</td>
                                    <td>{user?.email}</td>
                                    <td>{user?.fundraise}</td>
                                    <td>{user.payment_method}</td>
                                </tr>
                                <Collapse in={activeTableDetails === user?.id ? true : false}>
                                    <tr className="tablecollapsewraps" id={"tablecollpase"+user?.id}>
                                        <td colSpan="8">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Transaction id</th>
                                                        <td>{user?.transaction_id}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Registered At</th>
                                                        <td>{moment(user.created_at).format('LL')}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Comment</th>
                                                        <td>
                                                            <Form className="switchbtn">
                                                                <Badge bg={user?.is_comment ? "primary" : "danger"}>{user?.is_comment ? "Yes" : "No"}</Badge>
                                                            </Form>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Anonymous</th>
                                                        <td>
                                                            <Form className="switchbtn">
                                                                <Badge bg={user?.is_anonymous ? "primary" : "danger"}>{user?.is_anonymous ? "Yes" : "No"}</Badge>
                                                            </Form>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </Collapse>
                            </React.Fragment>)
                            })}
                            {charityFundraiseList.length === 0 && (<tr><td style={{ textAlign: "center" }} colSpan={7}>Donations fundraise not found..</td></tr>)}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
            {/* pagination */}
            {parseInt(paginationLength?.total_records) > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={paginationLength}
                currentFunction={currentFunction}
            />)}
        </div>
    </div>)
}

export default FundraiseDonationsTab;