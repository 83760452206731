export const ActionTypes = {
    GET_FUNDRAISE_LIST: "GET_FUNDRAISE_LIST",
    EDIT_FUNDRAISE: "EDIT_FUNDRAISE",
    ADD_FUNDRAISE_LIST: "ADD_FUNDRAISE_LIST",
    DELETE_FUNDRAISE: "DELETE_FUNDRAISE",
    FUNDRAISE_ACTION_OPTIONS: "FUNDRAISE_ACTION_OPTIONS",
    SELECT_FUNDRAISE: "SELECT_FUNDRAISE",
    GET_FUNDRAISE_LIST_IDWISE: "GET_FUNDRAISE_LIST_IDWISE",
    SELECT_FUNDRAISE_UPDATE: "SELECT_FUNDRAISE_UPDATE",
    ADD_FUNDRAISE_UPDATES: "ADD_FUNDRAISE_UPDATES",
    FUNDRAISE_UPDATES_OPTIONS: "FUNDRAISE_UPDATES_OPTIONS",
    GET_FUNDRAISE_UPDATE_LIST: "GET_FUNDRAISE_UPDATE_LIST",
    EDIT_FUNDRAISE_UPDATE_LIST: "EDIT_FUNDRAISE_UPDATE_LIST",
    DELETE_FUNDRAISE_UPDATE_LIST: "DELETE_FUNDRAISE_UPDATE_LIST"
};

export const addFundraiseUpdatesList = (updates) => {
    return {
       type: ActionTypes.ADD_FUNDRAISE_UPDATES,
       payload: updates,
    }
}

export const deleteFundraiseUpdatesList = (deleteupdates) => {
    return {
       type: ActionTypes.DELETE_FUNDRAISE_UPDATE_LIST,
       payload: deleteupdates,
    }
}

export const getFundraiseUpdateList = (updates) => {
    return {
        type: ActionTypes.GET_FUNDRAISE_UPDATE_LIST,
        payload: updates,
    }
}

export const editFundraiseUpdateLists = (updates) => {
    return {
        type: ActionTypes.EDIT_FUNDRAISE_UPDATE_LIST,
        payload: updates,
    }
}

export const selectFundraiseUpdatesOptions = (options) => {
    return {
        type: ActionTypes.FUNDRAISE_UPDATES_OPTIONS,
        payload: options,
    }
}

export const selectFundraiseUpdate = (updates) => {
    return {
       type: ActionTypes.SELECT_FUNDRAISE_UPDATE,
       payload: updates,
    }
}

export const getFundraiseListIDWISE = (fundraise) => {
    return {
       type: ActionTypes.GET_FUNDRAISE_LIST_IDWISE,
       payload: fundraise,
    }
}

export const optionsFundraise = (options) => {
    return {
       type: ActionTypes.FUNDRAISE_ACTION_OPTIONS,
       payload: options,
    }
}

export const selectFundraise = (select) => {
    return {
       type: ActionTypes.SELECT_FUNDRAISE,
       payload: select,
    }
}

export const addFundraiseList = (fundraise) => {
    return {
       type: ActionTypes.ADD_FUNDRAISE_LIST,
       payload: fundraise,
    }
}

export const getFundraiseList = (fundraise) => {
    return {
       type: ActionTypes.GET_FUNDRAISE_LIST,
       payload: fundraise,
    }
}

export const editFundraiseList = (fundraise) => {
    return {
       type: ActionTypes.EDIT_FUNDRAISE,
       payload: fundraise,
    }
}

export const deleteFundraiseList = (fundraise_delete) => {
    return {
       type: ActionTypes.DELETE_FUNDRAISE,
       payload: fundraise_delete,
    }
}


