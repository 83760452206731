/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Image, Collapse } from "react-bootstrap";
import EditIcon from "../../assets/img/edit.png";
import MoreIcon from '../../assets/img/more_options.png';
import ViewIcon from '../../assets/img/show.png';
import Form from 'react-bootstrap/Form';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Badge from 'react-bootstrap/Badge';
import FiltersForm from "../Common/FiltersForm";
import GetCharity from "../../Api/GetCharity";
import { useSelector, useDispatch } from "react-redux";
import { getCharityList, getCharityListIDWise, getCharityTabs } from "../../redux/actions/charityActions";
import { useNavigate, Link } from "react-router-dom";
import CommonModal from "../Common/CommonModal";
import Avatar from "../Common/Avatar";
import CommonLogout from "../Common/CommonLogout";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import DeleteIcon from "../../assets/img/delete.png";
import CommonPagination from "../Common/CommonPagination";

const CharityManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { charityList } = useSelector((state) => state.charityReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [ paginationLength, setPaginationLength ] = useState({});

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    
    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    useEffect(() => {
        fetchData();
    }, [ userSearch, userType, userLimit, currentPage ]);

    // Get data from api
    const fetchData = async () => {
        const charityList = await GetCharity(accessToken, userSearch, userType, userLimit, currentPage);
        if(charityList?.status === 200) {
            dispatch(getCharityList(charityList.data));
            dispatch(getCharityListIDWise(charityList.data))
            setPaginationLength(charityList.pagination);
        } else if(charityList?.status === 403) {
            CommonLogout();
            dispatch(getCharityList([]));
            dispatch(getCharityListIDWise([]));
        } else {
            dispatch(getCharityList([]));
            dispatch(getCharityListIDWise([]));
        };
    };

    // Charity active or deactive
    const CharityActivStatus = (Charity) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            description: `Do you really want to ${Charity?.is_active ? 'deactivate' : 'active'}`,
            modalType: "not form",
            button: "Yes",
            category: "Charity Active",
            Data: Charity
        });
    };

    // Delete charity 
    const DeleteCharity = (Charity) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            description: "Do you really want to delete",
            modalType: "not form",
            button: "Delete Charity",
            category: "Delete Charity",
            Data: Charity
        });
    };

    // add charity
    const AddCharity = () => {
        navigate("/add/charity");
        dispatch(getCharityTabs("add-charity"));
    };

    return(<>
        <FiltersForm 
            filterType={"Charity (Name)"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
            AddCharity={AddCharity}
        />
        <div className="tableContent">
            <Scrollbars 
                style={{ height: paginationLength?.total_records > 10 ? "calc(100% - 87px)" : "calc(100% - 50px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Picture</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>User Active</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {charityList && charityList?.map((user, index) => {
                                return(<React.Fragment key={index}>
                                <tr>
                                    <td>
                                        <Avatar type={"product"} src={user?.avatar} title={user?.name}/>
                                    </td>
                                    <td>{user?.name}</td>
                                    <td>{user?.email}</td>
                                    <td>
                                        {user?.phone === null ? "-" : "+"+user?.phone_code + " " + user?.phone}
                                    </td>
                                    <td>
                                        <Form className="switchbtn">
                                            <Form.Check 
                                                type="switch"
                                                id="custom-switch"
                                                checked={user?.is_active}
                                                onChange={e => {}}
                                                onClick={() => CharityActivStatus(user)}
                                            />
                                            <Badge bg={user?.is_active ? "primary" : "danger"}>{user?.is_active ? "Yes" : "No"}</Badge>
                                        </Form>
                                    </td>
                                    <td>
                                        <Link className="btn actionbtn" onClick={() => dispatch(getCharityTabs("view-charity"))} to={"/view/charity/" + user?.slug} >
                                            <Image src={ViewIcon} alt="view icon" />
                                        </Link>
                                        <Link className="btn actionbtn" onClick={() => dispatch(getCharityTabs("edit-charity"))} to={"/edit/charity/" + user?.slug} >
                                            <Image src={EditIcon} alt="edit icon" />
                                        </Link>
                                        <button className="btn actionbtn" onClick={() => DeleteCharity(user)}>
                                            <Image src={DeleteIcon} alt="delete icon" />
                                        </button>
                                    </td>
                                </tr>
                            </React.Fragment>)
                            })}
                            {charityList && charityList.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>Charity Not Found !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
            {/* pagination */}
            {parseInt(paginationLength?.total_records) > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={paginationLength}
                currentFunction={currentFunction}
            />)}
        </div>
        {showModal?.open && (<CommonModal showModal={showModal} setShowModal={setShowModal} />)}
    </>);
}

export default CharityManagement;