/* eslint-disable */
import React, { useState } from "react";
import { Image } from "react-bootstrap";
import EditIcon from "../../assets/img/edit.png";
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import { useDispatch, useSelector } from "react-redux";
import { SelectPackagesTab } from "../../redux/actions/packageAction";
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import DeleteIcon from "../../assets/img/delete.png";
import { PostRequestAPI } from "../../Api/PostRequest";
import { toast } from 'react-toastify';
import { EDIT_CAMPAIGN_API_URL, FREE_COUPON } from "../Shared/constant";


const PackageItem = (props) => {
    const dispatch = useDispatch()
    const { element, Deletepackage, CharityActivStatus } = props;
  return (
   <>
            <tr>
            <td style={{
                textTransform:"capitalize"
            }}>{element?.name}</td>
            <td style={{
                textTransform:"capitalize"
            }}>{element?.quantity}</td>
            {!props?.hidePrice && <td style={{
                textTransform:"capitalize"
            }}>{element?.amount}</td>}
            {props?.hidePrice && <td style={{
                textTransform:"capitalize"
            }}>{element?.code}</td>}
            <td style={{
                textTransform:"capitalize"
            }}>{moment(element.created_at).format('LL HH:mm A')}</td>
            <td>
                <Form className="switchbtn">
                    <Form.Check 
                        type="switch"
                        id="custom-switch"
                        checked={element?.is_active}
                        onChange={e => {}}
                        onClick={() => CharityActivStatus(element)}
                    />
                    <Badge bg={element?.is_active ? "primary" : "danger"}>{element?.is_active ? "Yes" : "No"}</Badge>
                </Form>
            </td>
            <td className="space-nowrap text-nowrap">
                <div className="d-flex">
                    <Link className="btn actionbtn" 
                    onClick={() => dispatch(SelectPackagesTab("edit-package"))}
                     to={props?.hidePrice?`/update${FREE_COUPON}/${element?.id}`:"/package/update/" + element?.id} 
                     >
                        <Image src={EditIcon} alt="edit icon" />
                    </Link>
                    <button className="btn actionbtn" 
                    onClick={() => Deletepackage(element)}
                    >
                        <Image src={DeleteIcon} alt="delete icon" />
                    </button> 
                </div>
            </td>
        </tr>
   </>
  )
}

export default PackageItem