/* eslint-disable eqeqeq */
import React, { useState } from 'react'
import SettingModule from './SettingModule'
import BlogList from './BlogList'
import { useLocation } from 'react-router-dom';

export default function SettingTab() {
    const location = useLocation();

    const [tabSelect, setTabSelect] = useState(location?.state || "sponsors");
    const listTab = [
        {
            title:"Sponsors",
            key:"sponsors"
        },
        {
            title:"Blogs",
            key:"blogs"
        },
    ];
    
  return (
    <React.Fragment>
        <ul className="tabcomnsection">
            {listTab?.map((item , index)=>(
                <React.Fragment key={index}>
                    <li className={`${tabSelect == item?.key && "active"} text-capitalized`} onClick={()=>setTabSelect(item?.key)} >
                        {item?.title}
                    </li>
                </React.Fragment>
            ))}
        </ul>
        {tabSelect === "sponsors" && (
            <SettingModule/>
        )}
        {tabSelect === "blogs" && (
            <BlogList/>
        )}
    </React.Fragment>
  )
}
