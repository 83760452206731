export const ActionTypes = {
    GET_USER_LIST: "GET_USER_LIST",
    EDIT_USER_LIST: "EDIT_USER_LIST",
    GET_USERLIST_WISE_ID: "GET_USERLIST_WISE_ID"
};

export const getUserLists = (users) => {
    return {
       type: ActionTypes.GET_USER_LIST,
       payload: users,
    }
}

export const getUserListIDWise = (users) => {
    return {
        type: ActionTypes.GET_USERLIST_WISE_ID,
        payload: users,
    }
}

export const editUserLists = (users) => {
    return {
       type: ActionTypes.EDIT_USER_LIST,
       payload: users,
    }
}