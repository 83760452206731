/* eslint-disable */
import { ActionTypes } from "../actions/charityActions";

const initailData = {
    charityList: [],
    charityListIdWise: {},
    charityTabs: "edit-charity",
    selectedCharity: {}
};

export const charityReducers = (state = initailData, action) => {
    switch (action.type) {
        case ActionTypes.GET_CHARITY_LIST:
            return {
                ...state,
                charityList: action.payload,
            };
        case ActionTypes.SELECTED_CHARITY:
            return {
                ...state,
                selectedCharity: action.payload,
            };
        case ActionTypes.SELECT_CHARITY_TAB:
            return {
                ...state,
                charityTabs: action.payload,
            };
        case ActionTypes.ADD_CHARITY:
            const CharityListX = [...state.charityList];
            CharityListX.unshift(action.payload);
            return {
                ...state,
                charityList: CharityListX,
            };
        case ActionTypes.GET_CHARITY_LIST_ID_WISE:
            const getcharityObject = {}
            action.payload && action.payload.map((elm) => {
                getcharityObject[elm.slug] = elm;
            });
            return {
                ...state,
                charityListIdWise: getcharityObject,
            };
        case ActionTypes.EDIT_CHARITY:
            const charityListOld = [...state.charityList]; 
            const updateCharityyList = charityListOld.map((elm) => {
                if(elm?.id === action?.payload?.id) {
                    return{
                        ...elm,
                        avatar: action?.payload?.avatar,
                        name: action?.payload?.name,
                        email: action?.payload?.email,
                        ein: action?.payload?.ein,
                        phone: action?.payload?.phone,
                        phone_code: action?.payload?.phone_code,
                        country: action?.payload?.country,
                        description: action?.payload?.description,
                        slug: action?.payload?.slug,
                        is_active: action?.payload?.is_active
                    }
                }else 
                return elm;
            });
            return {
                ...state,
                charityList: updateCharityyList,
            };
        case ActionTypes.DELETE_CHARITY: 
            const CharityListXd = [...state.charityList];
            const NewUpdatedCharityList = [];
            CharityListXd && CharityListXd.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedCharityList.push(elm);
                }
            }); 
            return {
                ...state,
                charityList: NewUpdatedCharityList,
            };
        default:
        return state;
    }
}