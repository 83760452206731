/* eslint-disable */
import React, { useState } from "react";
import { Image } from "react-bootstrap";
import EditIcon from "../../assets/img/edit.png";
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import { useDispatch, useSelector } from "react-redux";
import { SelectPackagesTab } from "../../redux/actions/packageAction";
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import DeleteIcon from "../../assets/img/delete.png";
import { PostRequestAPI } from "../../Api/PostRequest";
import { toast } from 'react-toastify';
import { EDIT_CAMPAIGN_API_URL } from "../Shared/constant";
import { SelectOrderTab } from "../../redux/actions/orderAction";


const PaymentItems = (props) => {
    const dispatch = useDispatch()
    const { element} = props;
  return (
   <>
            <tr>
            <td style={{
                textTransform:"capitalize"
            }}>{element.payment_intent ? element?.payment_intent : "-"}</td>
            <td style={{
                textTransform:"capitalize"
            }}> {element.payment_type ? element?.payment_type : "-" }</td>
            <td >
            {/* {element.amount/100} */}
            ${Number(element.amount / 100)?.toFixed(2)}
            </td>
            <td style={{
                textTransform:"capitalize"
            }}>{element.coupon ?element?.coupon :"-"}</td>
            <td className="space-nowrap text-nowrap">
                <Form className="switchbtn">
                    
                    <Badge bg={element?.is_paid ? "primary" : "danger"}>{element?.is_paid ? "Paid" : "Unpaid"}</Badge>
                </Form>
            </td>
            <td>{moment(element.created_at).format('LL HH:mm A')}</td>

            

        </tr>
   </>
  )
}

export default PaymentItems