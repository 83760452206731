/* eslint-disable */
import React from "react";
import { Form, Image } from 'react-bootstrap';
import DefaultImage from "../../assets/img/DefaultProduct.jpg";
import Avatar from "../Common/Avatar";

const ViewCharity = (props) => {
    const { AvatarImage, EditCharitys, description } = props;

    return(<div className="tabsWrappers">
    <div className="formloginset">
        <div className="row">
            <div className="col-md-12">
                <Form.Group className="mb-3" >
                    <Form.Label>Charity Avatar</Form.Label>
                    {(AvatarImage !== undefined && AvatarImage !== "") ? (<div className="imageuploads">
                        <Image src={AvatarImage} alt="charity avatar" />
                    </div>) :
                    (<div className="uploadbuttons">
                        <div className="uploadfilebox">
                            <Image src={DefaultImage} alt="upload image" />
                        </div>
                    </div>)}
                </Form.Group>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <Form.Group className="mb-3" >
                    <Form.Label>Charity Name</Form.Label>
                    <div className="charityName">{EditCharitys?.name}</div>
                </Form.Group>
            </div>
            <div className="col-md-6">
                <Form.Group className="mb-3" >
                    <Form.Label>Email address</Form.Label>
                    <div className="charityName">{EditCharitys?.email}</div>
                </Form.Group>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <Form.Group className="mb-3" >
                    <Form.Label>EIN(Employer Identification Number)</Form.Label>
                    <div className="charityName">{EditCharitys?.ein}</div>
                </Form.Group>
            </div>
            <div className="col-md-6">
                <Form.Group className="mb-3" >
                    <Form.Label>Phone number</Form.Label>
                    <div className="charityName">{"+" + EditCharitys?.phone_code + " " +EditCharitys?.phone}</div>
                </Form.Group>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <Form.Group className="mb-3" >
                    <Form.Label>Description</Form.Label>
                    <div className="charityName" dangerouslySetInnerHTML={{__html: description}}></div>
                </Form.Group>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <Form.Group className="mb-3" >
                    <Form.Label>Country</Form.Label>
                    <div className="charityName">{EditCharitys?.country}</div>
                </Form.Group>
            </div>
            <div className="col-md-6">
                <Form.Group className="mb-3" >
                    <Form.Label>Address</Form.Label>
                    <div className="charityName">{EditCharitys?.address}</div>
                </Form.Group>
            </div>
        </div>
    </div>
</div>)
}

export default ViewCharity;