/* eslint-disable */
import React, { useState } from "react";
import { Image } from "react-bootstrap";
import EditIcon from "../../assets/img/edit.png";
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import { useDispatch, useSelector } from "react-redux";
import { SelectPackagesTab } from "../../redux/actions/packageAction";
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import DeleteIcon from "../../assets/img/delete.png";
import { PostRequestAPI } from "../../Api/PostRequest";
import { toast } from 'react-toastify';
import { EDIT_CAMPAIGN_API_URL } from "../Shared/constant";
import { SelectOrderTab } from "../../redux/actions/orderAction";
import EyeIcon from "../../assets/img/EyeIcon.svg";



const OrderItems = (props) => {
    const dispatch = useDispatch()
    const { element} = props;
    // console.log("element",element)
  return (
   <>
            <tr>
            <td style={{
                textTransform:"capitalize"
            }}>{element?.first_name}</td>
            <td style={{
                textTransform:"capitalize"
            }}>{element?.last_name}</td>
            <td >{element?.email}</td>
            <td style={{
                textTransform:"capitalize"
            }}>
                {element?.is_free ?"-":(
                    <React.Fragment>
                            {element?.bundle_package?.name}, Qty:-{element?.bundle_package?.quantity}, ${element?.bundle_package?.amount}
                    </React.Fragment>
                )}

            </td>
            <td>{element?.coupon}</td>
            <td className="space-nowrap text-nowrap">
                <Form className="switchbtn">
                    
                    <Badge bg={element?.is_paid ? "primary" :  element?.is_free ?"warning":"danger"}>{element?.is_free && !element?.is_paid ?"Free": (element?.is_paid ? "Paid" : "Unpaid")}</Badge>
                </Form>
            </td>

            <td>{moment(element.created_at).format('LL HH:mm A')}</td>            
            <td className="space-nowrap text-nowrap">
                <div className="d-flex">
                    <Link className="btn actionbtn" 
                    onClick={() => dispatch(SelectOrderTab("view-package"))}
                     to={"/order/list/" + element?.id} 
                     >
                        {/* <Image src={EditIcon} alt="edit icon" /> */}
                        <Image  src={EyeIcon} alt="delete icon" />
                  
                    {/* <button className="btn actionbtn" 
                    >
                       View
                    </button>  */}
                    </Link>
                </div>
            </td>
        </tr>
   </>
  )
}

export default OrderItems