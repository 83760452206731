import { ActionTypes } from "../actions/orderAction";

const initailData = {
    orderList: [],
    CurrenUrlBaseForOrder: "",
};
export const orderReducers = (state = initailData, action) => {
    switch (action.type) {
        case ActionTypes.GET_ORDER_LIST:
            return {
                ...state,
                orderList: action.payload,
            };
        case ActionTypes.ORDER_CURRENT_TAB:
            return {
                ...state,
                CurrenUrlBaseForOrder: action.payload,
            };
        default:
        return state;
    }
}