export const ActionTypes = {
    GET_PAYMENT_LIST: "GET_PAYMENT_LIST",
    PAYMENT_CURRENT_TAB: "PAYMENT_CURRENT_TAB",
};
export const SelectPaymentTab = (urls) => {
    return {
       type: ActionTypes.PAYMENT_CURRENT_TAB,
       payload: urls,
    }
};

export const getPaymentList = (list) => {
    return {
       type: ActionTypes.GET_PAYMENT_LIST,
       payload: list,
    }
};