/* eslint-disable no-mixed-operators */

import { toast } from "react-toastify";
import { GetRequestCallAPI } from "../../Api/GetRequest";
import { setStoreBlogListData, setStoreSponsorsListData } from "../../redux/actions/campaignAction";
import { BLOG_LIST_API_URL, LIST_SETTING_MODULE } from "./constant";

/* eslint-disable eqeqeq */
export function uuidV4() { // Public Domain/MIT
    let d = new Date().getTime();//Timestamp
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}
export const getSettingModuleList = () =>(async (dispatch, getState)=>{
    const {accessToken} =  getState()?.personalReducers;
    const response = await GetRequestCallAPI(`${LIST_SETTING_MODULE}`, accessToken );
    if(response?.status === 200) {
        dispatch(setStoreSponsorsListData(response?.data?.data?.data));
    } else if(response?.status === 403) {
        dispatch(setStoreSponsorsListData({facefunding_sponsors:[]}));
    } else {
        dispatch(setStoreSponsorsListData({facefunding_sponsors:[]}));
    };
})

export const getBlogListData = (userType, page, limit, search) =>(async (dispatch, getState)=>{
    const {accessToken} =  getState()?.personalReducers;
    const response = await GetRequestCallAPI(`${BLOG_LIST_API_URL}/${userType}/${limit}/${page}/${search == "" ?0:search}`, accessToken );
    if(response?.status === 200) {
        dispatch(setStoreBlogListData(response?.data));
    } else if(response?.status === 403) {
        dispatch(setStoreBlogListData());
    } else {
        dispatch(setStoreBlogListData());
    };
})

export const AuthenticateResponse = (response, state, dataReturn) => {
    console.log("response", response);
    const errorData = {};
  
    if (response?.status == 400) {
      if (typeof response?.data?.error === "string") {
        if (dataReturn) {
          errorData.error = response?.data?.error;
        } else {
          toast.error(response?.data?.error);
        }
      } else if (typeof response?.data?.error === "object") {
        for (const [key, value] of Object.entries(response?.data?.error)) {
          if (dataReturn) {
            errorData[key] = value;
          } else {
            toast.error(`${key}: ${value}`);
          }
        }
      }
  
      if (typeof response?.msg === "object") {
        if (response?.msg.mobile) {
          if (dataReturn) {
            errorData.mobile = response?.msg.mobile;
          } else {
            toast.error(response?.msg.mobile);
          }
        }
      } else if (typeof response?.msg === "string") {
        if (dataReturn) {
          errorData.mobile = response?.msg;
        } else {
          toast.error(response?.msg);
        }
      }
    } else if (response?.status == 403) {
      if (typeof response?.data?.error === "string") {
        if (dataReturn) {
          errorData.error = response?.data?.error;
        } else {
          toast.error(response?.data?.error);
        }
        if (state.redirect) {
          return state.redirect();
        }
      } else if (typeof response?.data?.error === "object") {
        for (const [key, value] of Object.entries(response?.data?.error)) {
          if (dataReturn) {
            errorData[key] = value;
          } else {
            toast.error(`${key}: ${value}`);
          }
        }
      }
    }
  
    if (dataReturn) {
      return errorData;
    }
  };