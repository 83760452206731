/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import CheckValid from "../Common/CheckValid";
import { Scrollbars } from 'react-custom-scrollbars-2';
import GetFundraiseDetails from "../../Api/GetFundraiseDetails";
import EditFundraise from "../../Api/EditFundraise";
import AddFundraise from "../../Api/AddFundraise";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Edit from "./Edit";
import View from "./View";
import Settings from "./Settings";
import Updates from "./Updates";
import { addFundraiseList, editFundraiseList, optionsFundraise, selectFundraise, selectFundraiseUpdate, selectFundraiseUpdatesOptions } from "../../redux/actions/fundActions";
import { useNavigate, useLocation } from "react-router-dom";
import { FUNDRSISERS_MANAGEMENT_URL } from "../Shared/constant";
import CommonLoader from "../Common/CommonLoader";
import FundraiseDonationsTab from "./FundraiseDonationsTab";

const FundraisersEdit = () => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const LocationUrl = useLocation()?.pathname.split('/')[3];
    const CurrentActionUrl = useLocation()?.pathname.split('/')[1] + '/' + useLocation()?.pathname.split('/')[2];
    const AddFundraiseUrl = useLocation()?.pathname;
    const { selfInfo, accessToken } = useSelector((state) => state.personalReducers);
    const { fundraiseOptionMenu, selectedFundraise } = useSelector((state) => state.fundraiseReducers);
    const [ MainTab, setMainTab ] = useState("dashboard");
    const [ coverImage, setCoverImage ] = useState("");
    const [ fundEditState, setFundEditState ] = useState({
        title: "",
        amount: "",
        category: "",
        fundraise_for: "", 
        country: ""
    });
    const [ story, setStory ] = useState("");

    // error message
    const [ errortitle, setErrortTitle ] = useState("");
    const [ errorAmount, setErrorAmount ] = useState("");
    const [ errorDescription, setErrorDescription ] = useState(false);
    const [ errorCategory, setErrorCategory ] = useState("");
    const [ errorCountry, setErrorCountry ] = useState("");
    const [ errorAvatar, setErrorAvatar ] = useState("");
    const [ errorFundRaiseFor, setErrorFundRaiseFor ] = useState("");

    const [ LoaderStart, setLoaderStart ] = useState(false);

    // get fundraise details
    const fetchData = async () => {
        if(CurrentActionUrl === "view/fundraiser" || CurrentActionUrl === "edit/fundraiser" ) {
            setLoaderStart(true);
            const fundraisget = await GetFundraiseDetails(accessToken, LocationUrl);
            if(fundraisget) {   
                dispatch(selectFundraise(fundraisget?.data));
                setCoverImage((fundraisget?.data?.cover_image === "" || fundraisget?.data?.cover_image === null || fundraisget?.data?.cover_image === undefined) ? "" : fundraisget?.data?.cover_image)
                setFundEditState({
                    ...fundEditState,
                    title: (fundraisget?.data?.title === "" || fundraisget?.data?.title === null || fundraisget?.data?.title === undefined) ? "" : fundraisget?.data?.title,
                    amount: (fundraisget?.data?.amount === "" || fundraisget?.data?.amount === null || fundraisget?.data?.amount === undefined) ? "" : fundraisget?.data?.amount,
                    category: (fundraisget?.data?.category === "" || fundraisget?.data?.category === null || fundraisget?.data?.category === undefined) ? "" : fundraisget?.data?.category,
                    fundraise_for: (fundraisget?.data?.fundraise_for === "" || fundraisget?.data?.fundraise_for === null || fundraisget?.data?.fundraise_for === undefined) ? "" : fundraisget?.data?.fundraise_for, 
                    country: (fundraisget?.data?.country === "" || fundraisget?.data?.country === null || fundraisget?.data?.country === undefined) ? "" : fundraisget?.data?.country
                });
                setStory((fundraisget?.data?.description === "" || fundraisget?.data?.description === null || fundraisget?.data?.description === undefined) ? "" : fundraisget?.data?.description)
                setTimeout(() => {
                    setLoaderStart(false);
                }, 1000);
            };
        }
    };

    useEffect(() => {
        if(MainTab === "dashboard") {
            fetchData();
        };
    }, [ accessToken, MainTab ]);
    
    // create and save funcraisers function
    const saveFundraisrs = async () => {
        if((coverImage !== "" && coverImage !== null) && fundEditState?.title !== "" && fundEditState?.amount !== "" && story !== "" && fundEditState?.category !== "" && fundEditState?.fundraise_for !== "" && fundEditState?.country !== "") {
            setErrorDescription(false);
            if(CurrentActionUrl === "add/fundraiser") {
                const jsonData = JSON.stringify({ 
                    cover_image: coverImage,
                    title: fundEditState?.title,
                    country: fundEditState?.country,
                    category: fundEditState?.category,
                    fundraise_for: fundEditState?.fundraise_for,
                    amount: fundEditState?.amount,
                    description: story
                });
                const response = await AddFundraise(accessToken, jsonData);
                if(response?.status === 200){
                    setTimeout(() => {
                        toast.success(response.msg);
                        dispatch(addFundraiseList(response?.data));
                        Navigate(FUNDRSISERS_MANAGEMENT_URL);
                    }, 100);
                } else {
                    toast.error(response?.error || response.data.error);
                };
            } else {
                const jsonData = JSON.stringify({ 
                    cover_image: coverImage,
                    title: fundEditState?.title,
                    country: fundEditState?.country,
                    category: fundEditState?.category,
                    fundraise_for: fundEditState?.fundraise_for,
                    amount: fundEditState?.amount,
                    description: story,
                    owner: selfInfo?.id,
                    slug: LocationUrl
                });
                const response = await EditFundraise(accessToken, jsonData);
                if(response?.status === 200){
                    setTimeout(() => {
                        toast.success(response.msg);
                        dispatch(editFundraiseList(response?.data));
                        Navigate(FUNDRSISERS_MANAGEMENT_URL);
                    }, 100);
                } else {
                    toast.error(response?.error || response.data.error);
                };
            }
        } else {
            CheckValid(coverImage, {type: 'coverMedia', error: setErrorAvatar });
            CheckValid(fundEditState?.title, {type: 'name', error: setErrortTitle });
            CheckValid(fundEditState?.amount, {type: "amount", error: setErrorAmount });
            setErrorDescription(true);
            CheckValid(fundEditState?.country, {type: 'country', error: setErrorCountry });
            CheckValid(fundEditState?.category, {type: 'categoryname', error: setErrorCategory });
            CheckValid(fundEditState?.fundraise_for, {type: 'fundraiseforname', error: setErrorFundRaiseFor });
        };
    };

    // cancel fundraise function
    const cancelFundraisrs = () => {
        setTimeout(() => {
            Navigate(FUNDRSISERS_MANAGEMENT_URL);
            setCoverImage("");
            setFundEditState({
                ...fundEditState,
                title: "",
                amount: "",
                category: "",
                fundraise_for: "", 
                country: ""
            });
            setStory("");
        }, 100);
    };

    // update tab
    const UpdateTab = () => {
        setMainTab("updates");
        dispatch(selectFundraiseUpdate(""));
        dispatch(selectFundraiseUpdatesOptions("view-updates"))
    };

    return(<>
            <section className="formsectionset">
                <ul className="mainTabs">
                    <li className={MainTab === "dashboard" ? "active" : ""} onClick={() => setMainTab("dashboard")}>Dashboard</li>
                    <li className={MainTab === "donations" ? "active" : ""} onClick={() => setMainTab("donations")}>Donations</li>
                    <li className={MainTab === "updates" ? "active" : ""} onClick={() => UpdateTab()}>Updates</li>
                    <li className={MainTab === "transfers" ? "active" : ""} onClick={() => setMainTab("transfers")}>Transfers</li>
                </ul>
                <div className="maintabcontent">
                    {MainTab === "dashboard" ? (<div className="tabsWrapperset">
                            <ul className="tabcomnsection">
                                <li className={fundraiseOptionMenu === "edit-fundraiser" ? "active" : ""} onClick={() => dispatch(optionsFundraise("edit-fundraiser"))}>{AddFundraiseUrl === "/add/fundraiser" ? "Add" : "Edit"}</li>
                                {AddFundraiseUrl !== "/add/fundraiser" && <li className={fundraiseOptionMenu === "view-fundraiser" ? "active" : ""} onClick={() => dispatch(optionsFundraise("view-fundraiser"))}>View</li>}
                                {AddFundraiseUrl !== "/add/fundraiser" && <li className={fundraiseOptionMenu === "settings-fundraiser" ? "active" : ""} onClick={() => dispatch(optionsFundraise("settings-fundraiser"))}>Settings</li>}
                                <div className="tabheadebuttns">
                                    <Button className="btn-link savchanges" onClick={() => cancelFundraisrs()}>Back</Button>
                                    {fundraiseOptionMenu === "edit-fundraiser" && (<Button className="savchanges" onClick={() => saveFundraisrs()}>{AddFundraiseUrl === "/add/fundraiser" ? "Add" : "Save Changes"}</Button>)}
                                </div>
                            </ul>
                            <div className="tabcontentnew">
                                {(fundraiseOptionMenu === "edit-fundraiser") ? (
                                <Scrollbars 
                                    style={{ height: "calc(100vh - 230px)" }} 
                                    className="ScrollbarsWrapper"
                                    renderView={props => <div {...props} className="view"/>}
                                >
                                    <Edit 
                                        coverImage={coverImage} 
                                        setCoverImage={setCoverImage}
                                        fundEditState={fundEditState} 
                                        setFundEditState={setFundEditState} 
                                        story={story} 
                                        setStory={setStory}
                                        setErrortTitle={setErrortTitle}
                                        setErrorAmount={setErrorAmount}
                                        setErrorCategory={setErrorCategory}
                                        setErrorCountry={setErrorCountry}
                                        errortitle={errortitle}
                                        errorAmount={errorAmount}
                                        errorDescription={errorDescription}
                                        errorCategory={errorCategory}
                                        errorCountry={errorCountry}
                                        errorAvatar={errorAvatar}
                                        setErrorFundRaiseFor={setErrorFundRaiseFor}
                                        errorFundRaiseFor={errorFundRaiseFor}
                                        selectedFundraise={selectedFundraise}
                                    />
                                </Scrollbars>) : 
                                (fundraiseOptionMenu === "view-fundraiser") ? (
                                <Scrollbars 
                                    style={{ height: "calc(100vh - 245px)" }} 
                                    className="ScrollbarsWrapper"
                                    renderView={props => <div {...props} className="view"/>}
                                >
                                    <View setLoaderStart={setLoaderStart} setMainTab={setMainTab} selectedFundraise={selectedFundraise} />
                                </Scrollbars>) : 
                                (<Scrollbars 
                                    style={{ height: "calc(100vh - 245px)" }}
                                    className="ScrollbarsWrapper"
                                    renderView={props => <div {...props} className="view"/>}
                                >
                                    <Settings />
                                </Scrollbars>)}
                        </div>
                    </div>) :
                    MainTab === "donations" ? (<div className="tabsWrapperset">
                        <FundraiseDonationsTab slug={LocationUrl} accessToken={accessToken} setLoaderStart={setLoaderStart} />
                    </div>) :
                    MainTab === "updates" ? (<div className="tabsWrapperset">
                        <Updates setLoaderStart={setLoaderStart} />
                    </div>) :
                    (<div className="tabsWrapperset"></div>)}
                </div>
        </section>
        {/* common loader */}
        {LoaderStart && <CommonLoader classNameS={"fundraiseLoader"} />}
    </>);
}

export default FundraisersEdit;