/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { Link } from 'react-router-dom'
import { ADMIN_BLOG_MANAGEMENT_URL, TEMPLATE_SUCCESS_POPUP } from '../Shared/constant'
import { Button, Form, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import ReactQuill from 'react-quill'
import UploadFile from "../../Api/UploadFile";
import LoaderImage from "../../assets/img/spinner_transferent.svg";
import DefaultImage from "../../assets/img/DefaultProduct.jpg";
import UploadImageIcon from "../../assets/img/uploadImage.png";
import GetCampaign from '../../Api/GetCampaign'
import { setStoreCampaignListOption } from '../../redux/actions/campaignAction'

export default function SuccessPopupDynamic() {
    const modules = {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image'],
          ['clean']
        ],
    }
    
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]
    const dispatch = useDispatch()
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { CurrenUrlBase } = useSelector((state) => state.campaignReducers);
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [formData, setFormData] = useState({
        title:"",
        description:"",
        logo:[],
        campaign:""
    });
    const [error, setError] = useState({
        title:"",
        description:"",
        logo:[],
    });

    const onChange = (e) =>{
        const cleanedValue = e.target.value.replace(/\s+/g, ' ');
        e.target.value = cleanedValue;
        if(e.target.value == " "){
            e.target.value = "";
        }
        const inputValue = {
            [e.target.name]:e.target.value
        }
        setFormData((data)=>({
            ...data,
            ...inputValue,
        }))
        setError((data)=>({
            ...data,
            [e.target.name]:""
        }))
    }

    const UploadImage = async (e, url) => {
        const files = e.target.files[0];
        const formData1 = new FormData();
        formData1.append("file", files, files.name);
        setUploadFileLoader(true);
        const fileresponse = await UploadFile(accessToken, formData1);
        if(fileresponse) {
            const oldLogo = formData?.logo;
            oldLogo?.push(fileresponse);
            setFormData((data)=>({
                ...data,
                logo: oldLogo,
            }))
        };
        e.target.value = null;
        setUploadFileLoader(false);
    }
    const callRemoveSponsorsLogo = (e) =>{
        const data1 = formData?.logo((item , index)=>index !== e);
        setFormData((data)=>({
            ...data,
            logo:data1
        }))
    }
    const validation = () =>{
        let val = true;
        const amountError = formData?.description?.includes("{amount}");
        if(!amountError){
            error.description = <small>Please enter <b className='px-1'>{`{amount}`}</b> with in this bracket to specify the donation amount show dynamic </small>;
        }
        if(formData?.title == ""){
            error.title = "Enter title";
            val = false;
        }
        if(formData?.description == "<p><br></p>" || formData?.description == ""){
            error.description = "Enter email content";
            val = false;
        }
        setError((data)=>({
            ...data,
            ...error,
        }))
        return val;
    }
    const onSubmit = ( e ) =>{
        e.preventDefault();
        if(validation()){

        }
    }

    return (
    <>
        <ul className="tabcomnsection">
                <li className={CurrenUrlBase === "add-campaign" ? "active" : ""} >Template Success Popup</li>
            <div className="tabheadebuttns">
            
                <Button className="savchanges" onClick={onSubmit} >Save</Button>
                <Link className="btn savchanges linksmanage" to={TEMPLATE_SUCCESS_POPUP} >Back</Link>
            </div>

        </ul>
        <section className="formsectionset">
        <div className="tabcontent">
            <Scrollbars
                    style={{ height: "calc(100vh - 162px)" }} 
                    className="ScrollbarsWrapper"
                    renderView={props => <div {...props} className="view"/>}
                >
                        <div className="formloginset formeditset">
                            <form onSubmit={onSubmit} className="row">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-required">Title</h4>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="mb-3" >
                                            <Form.Control 
                                                type="text" 
                                                value={formData?.title} 
                                                onChange={onChange} 
                                                placeholder="Enter title" 
                                                name='title'
                                            />
                                            {error?.title && <div className="danger-colorset">{error?.title}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-required">Email Content</h4>
                                        </div>
                                        <small>Please enter <b className='px-1'>{`{amount}`}</b> with in this bracket to specify the donation amount show dynamic </small>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="editorBox">
                                            <ReactQuill
                                                value={formData?.description}
                                                onChange={(e)=>onChange({target:{name:"description", value:e}})}
                                                modules={modules}
                                                formats={formats}
                                            />
                                            {error?.description && <div className="danger-colorset">{error?.description}</div>}
                                        </div>
                                    </div>
                                </div>
                            </form> 
                    </div> 
                </Scrollbars>
            </div>
        </section>
                
        </>
    )
}
