export const ActionTypes = {
    GET_CONTACT_LIST: "GET_CONTACT_LIST",
    EDIT_CONTACT_LIST: "EDIT_CONTACT_LIST",
    DELETE_CONTACT_LIST: "DELETE_CONTACT_LIST"
};

export const getContactList = (list) => {
    return {
       type: ActionTypes.GET_CONTACT_LIST,
       payload: list,
    }
}

export const editContactListAction = (list) => {
    return {
       type: ActionTypes.EDIT_CONTACT_LIST,
       payload: list,
    }
}

export const deleteContactListAction = (deletelist) => {
    return {
       type: ActionTypes.DELETE_CONTACT_LIST,
       payload: deletelist,
    }
}