/* eslint-disable */
import React, { useState, useEffect } from "react";
import GetCharityFundraise from "../../Api/GetCharityFundraise";
import FiltersForm from "../Common/FiltersForm";
import CommonLogout from "../Common/CommonLogout";
import { Scrollbars } from 'react-custom-scrollbars-2';
import Avatar from "../Common/Avatar";
import moment from "moment";
import CommonPagination from "../Common/CommonPagination";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { optionsFundraise } from "../../redux/actions/fundActions";
import { Image } from "react-bootstrap";
import ViewIcon from '../../assets/img/show.png';

const CharityFundraise = (props) => {
    const { slug, accessToken, setLoaderStart } = props;
    const dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ paginationLength, setPaginationLength ] = useState({});
    const [ charityFundraiseList, setCharityFundraiseList ] = useState([]);

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    useEffect(() => {
        fetchData();
    }, [ userSearch, userType, userLimit, currentPage ]);

    // Get data from api
    const fetchData = async () => {
        setLoaderStart(true);
        const charityFundraiseList = await GetCharityFundraise(accessToken, userSearch, slug, userLimit, currentPage);
        if(charityFundraiseList?.status === 200) {
            setCharityFundraiseList(charityFundraiseList?.data);
            setPaginationLength(charityFundraiseList.pagination);
            setTimeout(() => {
                setLoaderStart(false);
            }, 200);
        } else if(charityFundraiseList?.status === 403) {
            CommonLogout();
            setCharityFundraiseList([]);
            setTimeout(() => {
                setLoaderStart(false);
            }, 200);
        } else {
            setCharityFundraiseList([]);
            setTimeout(() => {
                setLoaderStart(false);
            }, 200);
        };
    };

    return(<div className="tabsWrappersnew">
            <FiltersForm 
                filterType={"Charity fundraise (Name)"}
                userSearch={userSearch}
                setUserSearch={setUserSearch}
                userType={userType} 
                setUserType={setUserType}
                userLimit={userLimit} 
                setUserLimit={setUserLimit}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                ClearSearchFiled={ClearSearchFiled}
                SelectUserTypeFnct={SelectUserTypeFnct}
            />
            <div style={{ float: "left", width: "100%", position: "relative",height: "calc(100vh - 223px)" }}>
                <Scrollbars 
                    style={{ maxHeight: paginationLength?.total_records > 10 ? "calc(100%)" : "calc(100%)"}} 
                    className="ScrollbarsWrapper"
                    renderView={props => <div {...props} className="view"/>}
                >
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Picture</th>
                                    <th style={{ width: "20%" }}>Fundraiser Title</th>
                                    <th>Amount</th>
                                    <th>Country</th>
                                    <th>Category</th>  
                                    <th>Created At</th>               
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {charityFundraiseList && charityFundraiseList?.map((user, index) => {
                                    return(<React.Fragment key={index}>
                                    <tr>
                                        <td>
                                            <Avatar type={"product"} src={user?.cover_image} title={user?.title}/>
                                        </td>
                                        <td>{user?.title}</td>
                                        <td>${user?.amount === null ? "00" : user?.amount} / {user?.raised_amount === null ? "00" : user?.raised_amount}</td>
                                        <td>{user?.country}</td>
                                        <td>{user?.category}</td>
                                        <td>{moment(user.created_at).format('LL')}</td>
                                        <td>
                                            <Link className="btn actionbtn" onClick={() => dispatch(optionsFundraise("view-fundraiser"))} to={"/view/fundraiser/" + user?.slug} >
                                                <Image src={ViewIcon} alt="view icon" />
                                            </Link>
                                        </td>
                                    </tr>
                                </React.Fragment>)
                                })}
                                {charityFundraiseList.length === 0 && (<tr><td style={{ textAlign: "center" }} colSpan={7}>Charity fundraise not found..</td></tr>)}
                            </tbody>
                        </table>
                    </div>
                </Scrollbars>
                {/* pagination */}
                {parseInt(paginationLength?.total_records) > 10 && (<CommonPagination 
                    currentPage={currentPage}
                    paginationLength={paginationLength}
                    currentFunction={currentFunction}
                />)}
            </div>
    </div>)
};

export default CharityFundraise;

    