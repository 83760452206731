export default function createSlug(value, allowUnicode = false) {
    // Convert value to a string
    value = String(value);
  
    // Normalize based on allowUnicode flag
    if (allowUnicode) {
      value = value.normalize("NFKC");
    } else {
      // Convert to ASCII by stripping diacritic marks
      value = value.normalize("NFKD").replace(/[\u0300-\u036f]/g, "");
    }
  
    // Remove characters that aren’t alphanumerics, underscores, or hyphens, and convert to lowercase
    value = value.toLowerCase().replace(/[^\w\s-]/g, "");
  
    // Replace spaces or repeated dashes with a single dash, then strip leading/trailing dashes or underscores
    return value.replace(/[-\s]+/g, "-").replace(/^[-_]+|[-_]+$/g, "");
  }