import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useSelector } from "react-redux";

const AuthLayout = ({children}) => {
    const { sidebaropen } = useSelector((state) => state.personalReducers); 

    return(<section className="adminmain">
        <Sidebar />
        <div className={sidebaropen ? "panelwrapper active" : "panelwrapper"}>
            <Header />
            <div className="panelContentwrapper">
                {children}
            </div>
        </div>
    </section>)
}

export default AuthLayout;