/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';
import moment from "moment";
import { Form, Badge, Image, Collapse, FormControl } from 'react-bootstrap';
import FiltersForm from "../Common/FiltersForm";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import CommonPagination from "../Common/CommonPagination";
import CommonModal from "../Common/CommonModal";
import { BLOG_MANAGEMENT } from "../Shared/constant";
import EyeIcon from "../../assets/img/edit.png";
import { GetRequestCallAPI } from "../../Api/GetRequest";
import Download from "../../assets/img/Download.svg";
import { getBlogListData, getSettingModuleList } from "../Shared/utils";
import Delete from "../../assets/img/delete.png";
import { PostRequestAPI } from "../../Api/PostRequest";


const BlogList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { blogList } = useSelector((state) => state.campaignReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
        const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [ paginationLength, setPaginationLength ] = useState({});

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
        fetchData(page,userLimit,userType, userSearch)
    };
    
    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    const [abortController, setAbortController] = useState({});

    useEffect(() => {
        fetchData(1,userLimit,userType, userSearch);
         return () => {
            if(abortController.abort){
                abortController.abort();
                setAbortController(new AbortController());
            }
        };
    }, [ userSearch, userType ]);

    // Get data from api
    const fetchData = async (currentPage, page, userType, userSearch) => {
       dispatch(await getBlogListData(userType, currentPage, page, userSearch))
    };

    const callUserLimit = (e) =>{
        setUserLimit(e);
        fetchData(1, e, userType, userSearch)
    }

    const callDeleteSponsors = (Blog) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            description: "Do you really want to delete this sponsors",
            modalType: "not form",
            button: "Delete Blog",
            category: "Delete Blog Data",
            Data: Blog
        });
    };

    const CharityActivStatus = (Charity) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            description: `Do you really want to ${Charity?.is_active ? 'deactivate' : 'active'}`,
            modalType: "not form",
            button: "Yes",
            category: "Blog update",
            Data: Charity
        });
    };
    
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    return(<>
        <FiltersForm 
            filterType={"( Blog Title )"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userLimit={userLimit} 
            setUserLimit={callUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
            onAdd={()=>navigate(`/add${BLOG_MANAGEMENT}`, {state:null})}
            add_text={"Add Blog"}
        />
        <div className="tableContent">
            <Scrollbars 
                style={{ height: blogList?.pagination?.total_records > 10 ? "calc(100% - 132px)" : "calc(100% - 100px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Blog Image</th>
                                <th>Blog Title</th>
                                <th>Created At</th>
                                <th>Action</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {blogList?.data && blogList?.data?.map((user, index) => {
                                return(<React.Fragment key={index}>
                                <tr style={{
                                        textTransform:"capitalize"
                                    }} className={activeTableDetails === user?.id ? "active" : ""}>
                                    <td >
                                        <div class="avatar">
                                            <img src={user?.avatar} alt="FaceFund" class="userprofile"/>
                                        </div>
                                    </td>
                                    <td>{user?.title}</td>
                                    <td>{moment(user.created_at).format('LL HH:mm A')}</td>
                                    <td>
                                        <Form className="switchbtn">
                                            <Form.Check 
                                                type="switch"
                                                id="custom-switch"
                                                checked={user?.is_active}
                                                onChange={e => {}}
                                                onClick={() => CharityActivStatus(user)}
                                            />
                                            <Badge bg={user?.is_active ? "primary" : "danger"}>{user?.is_active ? "Yes" : "No"}</Badge>
                                        </Form>
                                    </td>
                                    <td>
                                        <button className="btn actionbtn" onClick={()=>navigate(`/edit${BLOG_MANAGEMENT}/${user?.id}`, {state: user})} >
                                            <Image  src={EyeIcon} alt="delete icon" />
                                        </button>
                                        <button className="btn actionbtn" onClick={()=>callDeleteSponsors(user)} >
                                            <Image  src={Delete} alt="delete icon" />
                                        </button>
                                    </td>
                                </tr>
                            </React.Fragment>)
                            })}
                            {blogList?.data && blogList?.data.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>Blog Not Found !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
            {/* pagination */}
            {parseInt(blogList?.pagination?.total_records) > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={blogList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        {showModal?.open && (<CommonModal onCloseModal={()=>fetchData(1,userLimit,userType, userSearch)} showModal={showModal} setShowModal={setShowModal} />)}
    </>)
}

export default BlogList;