/* eslint-disable */
import { ActionTypes } from "../actions/athleteAction";

const initailData = {
    athleteList: [],
    athleteListIdWise: {},
    athleteTabs: "edit-athlete",
    selectedAthlete: {}
};

export const athleteReducers = (state = initailData, action) => {
    switch (action.type) {
        case ActionTypes.GET_ATHLETE_LIST:
            return {
                ...state,
                athleteList: action.payload,
            };
        case ActionTypes.SELECTED_ATHLETE:
            return {
                ...state,
                selectedAthlete: action.payload,
            };
        case ActionTypes.SELECT_ATHLETE_TAB:
            return {
                ...state,
                athleteTabs: action.payload,
            };
        case ActionTypes.ADD_ATHLETE:
            const AthleteListX = [...state.athleteList];
            AthleteListX.unshift(action.payload);
            return {
                ...state,
                athleteList: AthleteListX,
            };
        case ActionTypes.GET_ATHLETE_LIST_ID_WISE:
            const getAthleteObject = {}
            action.payload && action.payload.map((elm) => {
                getAthleteObject[elm.slug] = elm;
            });
            return {
                ...state,
                athleteListIdWise: getAthleteObject,
            };
        case ActionTypes.EDIT_ATHLETE:
            const athleteListOld = [...state.athleteList]; 
            const updateAthleteList = athleteListOld.map((elm) => {
                if(elm?.id === action?.payload?.id) {
                    return{
                        ...elm,
                        avatar: action?.payload?.avatar,
                        name: action?.payload?.name,
                        email: action?.payload?.email,
                        ein: action?.payload?.ein,
                        phone: action?.payload?.phone,
                        phone_code: action?.payload?.phone_code,
                        country: action?.payload?.country,
                        description: action?.payload?.description,
                        slug: action?.payload?.slug,
                        is_active: action?.payload?.is_active
                    }
                }else 
                return elm;
            });
            return {
                ...state,
                athleteList: updateAthleteList,
            };
        case ActionTypes.DELETE_ATHLETE: 
            const AthleteListXd = [...state.athleteList];
            const NewUpdatedAthleteList = [];
            AthleteListXd && AthleteListXd.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedAthleteList.push(elm);
                }
            }); 
            return {
                ...state,
                athleteList: NewUpdatedAthleteList,
            };
        default:
        return state;
    }
}