import React from "react";
import { Modal } from "react-bootstrap";

const PreviewImage = ({ closePopup, selectedImage }) => {
  return (
    <>
      <Modal
        centered
        className="modal-preview modal-preview-new"
        show={true}
        onHide={closePopup}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}

      >
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={closePopup}
        ></button>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              

              margin: "20px",
              marginBottom: "20px",
            }}
          >
            {selectedImage && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <img
                  src={selectedImage}
                  alt="Selected Avatar"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PreviewImage;
