import { DELETE_CAMPAIGN_LIST_API_URL } from "../components/Shared/constant";

const DeleteCampaign = async (accessToken, slug) => {
    const responce = window.axios.get(`${DELETE_CAMPAIGN_LIST_API_URL}/${slug}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + accessToken,
    }}).then(function (result) {
        return {
          ...result.data,
          status:200
        };
    }).catch(function (result) {
        return {
          ...result?.response?.data,
          status:result?.response?.status
        }
    });
    return responce;
};
  
export default DeleteCampaign;