/* eslint-disable */
import { ActionTypes } from "../actions/contactusActions";

const initailData = {
    contactList: [],
};

export const contactUsReducers = (state = initailData, action) => {
    switch (action.type) {
        case ActionTypes.GET_CONTACT_LIST:
            return {
                ...state,
                contactList: action.payload,
            };
        case ActionTypes.EDIT_CONTACT_LIST:
            const contactListOld = [...state.contactList]; 
            const updateContactList = contactListOld.map((elm) => {
                if(elm?.id === action?.payload?.id) {
                    return{
                        ...elm,
                        is_contacted: action?.payload?.is_contacted,
                        is_active: action?.payload?.is_active,
                        slug: action?.payload?.slug,
                    }
                }else 
                return elm;
            });
            return {
                ...state,
                contactList: updateContactList,
            };
        case ActionTypes.DELETE_CONTACT_LIST:
            const contactListOlds = [...state.contactList]; 
            const NewUpdatedContactList = [];
            contactListOlds && contactListOlds.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedContactList.push(elm);
                }
            });
            return {
                ...state,
                contactList: NewUpdatedContactList,
            };
        default:
        return state;
    }
}