/* eslint-disable */
import { ActionTypes } from "../actions/donationAction";

const initailData = {
    donationsList: []
};

export const donationReducers = (state = initailData, action) => {
    switch (action.type) {
        case ActionTypes.GET_DONATIONS_LIST:
            return {
                ...state,
                donationsList: action.payload,
            };
            case ActionTypes.UPDATE_DONATATAION_NEW_LIST:
                const donataionListOld = [...state.donationsList]; 
                const updatedonatationList = donataionListOld.map((elm) => {
                    if(elm?.id === action?.payload?.id) {
                        return{
                            ...elm,
                            is_active: action?.payload?.is_active
                        }
                    }else 
                    return elm;
                });
                
                return {
                    ...state,
                    donationsList: updatedonatationList,
                };
        default:
        return state;
    }
}