export const ActionTypes = {
    GET_CHARITY_LIST: "GET_CHARITY_LIST",
    GET_CHARITY_LIST_ID_WISE: "GET_CHARITY_LIST_ID_WISE",
    EDIT_CHARITY: "EDIT_CHARITY",
    ADD_CHARITY: "ADD_CHARITY",
    DELETE_CHARITY: "DELETE_CHARITY",
    SELECT_CHARITY_TAB: "SELECT_CHARITY_TAB",
    SELECTED_CHARITY: "SELECTED_CHARITY"
};

export const selectCharityList = (charity) => {
    return {
       type: ActionTypes.SELECTED_CHARITY,
       payload: charity,
    }
}

export const getCharityList = (charity) => {
    return {
       type: ActionTypes.GET_CHARITY_LIST,
       payload: charity,
    }
}

export const getCharityListIDWise = (charity) => {
    return {
       type: ActionTypes.GET_CHARITY_LIST_ID_WISE,
       payload: charity,
    }
}

export const getCharityTabs = (tabs) => {
    return {
       type: ActionTypes.SELECT_CHARITY_TAB,
       payload: tabs,
    }
}

export const editCharityListFnct = (charity) => {
    return {
       type: ActionTypes.EDIT_CHARITY,
       payload: charity,
    }
}

export const addCharityFnct = (charity) => {
    return {
       type: ActionTypes.ADD_CHARITY,
       payload: charity,
    }
}

export const deleteCharityFnct = (charity) => {
    return {
       type: ActionTypes.DELETE_CHARITY,
       payload: charity,
    }
}