/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { BLOG_ADD_API_URL, BLOG_GET_API_URL, BLOG_UPDATE_API_URL, MOSAIC_MANAGEMENT, SETTING_MODULE, UPDATE_SETTING_MODULE } from '../Shared/constant'
import { Button, Form, Modal, Image, Table, Badge, FormControl } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import LoaderImage from "../../assets/img/spinner_transferent.svg";
import DefaultImage from "../../assets/img/DefaultProduct.jpg";
import UploadImageIcon from "../../assets/img/uploadImage.png";
import GetCampaign from '../../Api/GetCampaign'
import { setStoreCampaignListOption } from '../../redux/actions/campaignAction'
import { PostRequestAPI } from '../../Api/PostRequest'
import { toast } from 'react-toastify'
import CommonLoader from '../Common/CommonLoader'
import UploadFile from '../../Api/UploadFile'
import { AuthenticateResponse, getSettingModuleList, uuidV4 } from '../Shared/utils.jsx'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { GetRequestCallAPI } from '../../Api/GetRequest.jsx'

export default function AddBlog() {

    const location = useLocation();
    const param = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { CurrenUrlBase, sponsorsData } = useSelector((state) => state.campaignReducers);
    const [ loader, setLoader ] = useState(false);
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [formData, setFormData] = useState({
        avatar:null,
        title: "",
        description: "",
        meta_title: "",
        meta_description: "",
        content: "",
        keywords: "",
    });
    const [error, setError] = useState({
        avatar:null,
        title: "",
        description: "",
        meta_title: "",
        meta_description: "",
        content: "",
        keywords: "",
    });

    useEffect(()=>{
        if(location.state){
            GetBlogDetails();
            console.log("location.state", location.state)
        }
    },[location.state])
    useEffect(()=>{
        fetchList()
    },[]);

    const GetBlogDetails = async () =>{
        setLoader(true)
        const response = await GetRequestCallAPI(`${BLOG_GET_API_URL}/${location.state?.slug}`);
        if(response?.status === 200){
            const BlogDetails = response?.data?.data
            setFormData((data)=>({
                ...data,
                avatar: {view_file_url: BlogDetails?.avatar},
                title: BlogDetails?.title,
                description: BlogDetails?.description,
                meta_title: BlogDetails?.meta_title,
                meta_description: BlogDetails?.meta_description,
                content: BlogDetails?.content,
                keywords: BlogDetails?.keywords,
                content: BlogDetails?.content,
            }))
        }else{
            navigate(SETTING_MODULE, { replace: true, state:"blogs" })
        }
        setLoader(false)
    }
    const fetchList = async () =>{
        dispatch(await getSettingModuleList());
    }

    const onChange = (e) => {
        let cleanedValue = e.target.value;
    
        const inputValue = {
            [e.target.name]: cleanedValue
        }
    
        setFormData((data) => ({
            ...data,
            ...inputValue,
        }));
    
        setError((data) => ({
            ...data,
            [e.target.name]: ""
        }));
    }    

    const allowedFileTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];

    const UploadImage = async (e, url) => {
        if (e.target.files?.length) {
            const file = e.target.files[0];


            // Check for file size (5MB = 5 * 1024 * 1024 bytes)
            const maxFileSize = 5 * 1024 * 1024;  
            if (file.size > maxFileSize) { 
                toast.error("File size should not exceed 5MB");
                return;  
            } 
            
            
            if (!allowedFileTypes.includes(file.type)) {
                toast.info('Invalid file type. Please select an image file (jpg, jpeg, png, webp).');
                e.target.value = null;
                return;
            }
    
            const formData1 = new FormData();
            formData1.append("file", file, file.name);
            setUploadFileLoader(true);
    
            try {
                const response = await UploadFile(accessToken, formData1);
                if (response) {
                    setFormData((data) => ({
                        ...data,
                        avatar: response,
                    }));
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            } finally {
                e.target.value = null;
                setUploadFileLoader(false);
            }
        }
    };    
    const callRemoveAvatar = (e) =>{
        setFormData((data)=>({
            ...data,
            avatar:null
        }))
    }
    const validation = () =>{
        let val = true;
        if(!formData?.avatar){
            error.avatar = "Upload blog image";
            val = false;
        }
        if(formData?.title == ""){
            error.title = "Enter title";
            val = false;
        }
        if(formData?.description == "" || formData?.description == "<p><br></p>"){
            error.description = "Enter description";
            val = false;
        }
        if(formData?.meta_title == ""){
            error.meta_title = "Enter meta title";
            val = false;
        }
        if(formData?.meta_description == ""){
            error.meta_description = "Enter meta description";
            val = false;
        }
        setError((data)=>({
            ...data,
            ...error,
        }))
        return val;
    }
    const onSubmit = async ( e ) =>{
        e.preventDefault();
        if(validation()){
            setLoader(true)
            
            if(param?.blog_id){
                const payload = {
                    avatar: formData?.avatar?.view_file_url,
                    title: formData?.title,
                    description: formData?.description,
                    meta_title: formData?.meta_title,
                    meta_description: formData?.meta_description,
                    content: formData?.content,
                    keywords: formData?.keywords,
                    blog_id: param?.blog_id,
                }
                const response = await PostRequestAPI(`${BLOG_UPDATE_API_URL}`, payload, accessToken);
                console.log("response", response)

                if(response?.status == 200){    
                    toast.success("Blog updated successfully.");
                    navigate(SETTING_MODULE, { replace: true, state:"blogs" })
                }else{
                    AuthenticateResponse(response)
                }
            }else{
                const payload = {
                    ...formData,
                    avatar: formData?.avatar?.view_file_url
                }
                const response = await PostRequestAPI(BLOG_ADD_API_URL, payload, accessToken);
                if(response?.status == 200){    
                    toast.success("Blog added successfully.");
                    navigate(SETTING_MODULE, { replace: true, state:"blogs" })
                }else{
                    AuthenticateResponse(response)

                }
            }
            setLoader(false)
        }
    }

    return (
    <>
        <ul className="tabcomnsection">
            <li className={ "active text-capitalized"} >
                {param?.id ? "Update Blog":"Create Blog"}
            </li>
            <div className="tabheadebuttns">
                <Button className="savchanges" onClick={onSubmit} disabled={loader} >{param?.id ?"Update":`Save`}</Button>
                <Link className="btn savchanges linksmanage" state={"blogs"} to={SETTING_MODULE} >Back</Link>
            </div>

        </ul>
        <section className="formsectionset">
        <div className="tabcontent">
            <Scrollbars
                    style={{ height: "calc(100vh - 162px)" }} 
                    className="ScrollbarsWrapper"
                    renderView={props => <div {...props} className="view"/>}
                >
                        <div className="formloginset formeditset">
                            <form onSubmit={onSubmit} className="row">
                                <div className='row'>
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-required">Blog Image</h4>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="" >
                                            {!formData?.avatar && (
                                                <div className="uploadbuttons me-2 mb-2"
                                                onClick={() => document.getElementById('fileInput14')?.click()}
                                                >
                                                    <div className={UploadFileLoader ? "fileloader active" : "fileloader"}>
                                                        {UploadFileLoader ? 
                                                        (<Image src={LoaderImage} className="uploadImgIconse uploadimagload" alt="upload image" />) : 
                                                        (<>
                                                            <Image src={UploadImageIcon} className="uploadImgIconse" alt="upload image" />
                                                            <input type="file" id="fileInput14" className="uploadfilInput" onChange={(e) => UploadImage(e, "SponsorsLogo")} accept=".png, .jpg, .jpeg, .webp"/>
                                                        </>)}
                                                    </div>
                                                    <div className="uploadfilebox">
                                                        <Image src={DefaultImage} alt="upload image" />
                                                    </div>
                                                </div>
                                            )}
                                            {formData?.avatar && (
                                                <React.Fragment>
                                                    <div className="imageuploads bg-grey me-2 mb-2">
                                                        <Image src={formData?.avatar?.view_file_url} alt="charity avatar" />
                                                        <span className="closeIcon" onClick={() => callRemoveAvatar()}></span>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </Form.Group>
                                        {error?.avatar && <div className="danger-colorset">{error?.avatar}</div>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-required">Blog Title</h4>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="mb-3" >
                                            <FormControl
                                                type="text" 
                                                value={formData?.title} 
                                                onChange={onChange} 
                                                placeholder="Blog Title" 
                                                name='title'
                                            />
                                            {error?.title && <div className="danger-colorset">{error?.title}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-required">Blog Description</h4>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="mb-3" >
                                            <ReactQuill
                                                className={`react-quill blog-details blog_details ${
                                                    error?.desc ? "is-invalid" : ""
                                                }`}
                                                theme="snow"
                                                value={formData?.description}
                                                onChange={(e) => onChange({ target: { name: "description", value: e, }, }) }
                                                modules={{
                                                    toolbar: [
                                                        [{ header: [1, 2, false] }], [ "bold", "italic", "underline", "strike", "blockquote", ], [ { list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, ], ["link", "image"], ["clean"], ],
                                                }}
                                                formats={[ "header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image", ]}
                                                placeholder={"Enter description"}
                                            />
                                            {error?.description && <div className="danger-colorset">{error?.description}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-required">Meta Title</h4>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="mb-3" >
                                            <FormControl
                                                type="text" 
                                                value={formData?.meta_title} 
                                                onChange={onChange} 
                                                placeholder="Meta Title" 
                                                name='meta_title'
                                            />
                                            {error?.meta_title && <div className="danger-colorset">{error?.meta_title}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-required">Meta Description</h4>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="mb-3" >
                                            <FormControl
                                                type="text" 
                                                value={formData?.meta_description} 
                                                onChange={onChange} 
                                                placeholder="Meta Description" 
                                                name='meta_description'
                                                as={"textarea"}
                                            />
                                            {error?.meta_description && <div className="danger-colorset">{error?.meta_description}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-required">Content</h4>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="mb-3" >
                                            <FormControl
                                                type="text" 
                                                value={formData?.content} 
                                                onChange={onChange} 
                                                placeholder="Content" 
                                                name='content'
                                            />
                                            {error?.content && <div className="danger-colorset">{error?.content}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-required">Keywords</h4>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="mb-3" >
                                            <FormControl
                                                type="text" 
                                                value={formData?.keywords} 
                                                onChange={onChange} 
                                                placeholder="Keywords" 
                                                name='keywords'
                                            />
                                            {error?.keywords && <div className="danger-colorset">{error?.keywords}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                        </form>
                    </div> 
                </Scrollbars>
            </div>
        </section>
        {loader && <CommonLoader classNameS={"charityLoader"}  />}
        </>
    )
}
