/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';
import moment from "moment";
import { Form, Badge, Image, Collapse, FormControl } from 'react-bootstrap';
import FiltersForm from "../Common/FiltersForm";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { getContactList } from "../../redux/actions/contactusActions";
import GetContactList from "../../Api/GetContactList";
import DeleteIcon from "../../assets/img/delete.png";
import MoreIcon from '../../assets/img/more_options.png';
import CommonPagination from "../Common/CommonPagination";
import CommonModal from "../Common/CommonModal";
import GetEmailCampaign from "../../Api/GetEmailCampaign";
import { setStoreMailCampaignList } from "../../redux/actions/campaignAction";
import { CAMPAIGN_MAIL_LIST, MAIL_CAMPAIGN_MANAGEMENT } from "../Shared/constant";
import EditIcon from "../../assets/img/edit.png";
import EyeIcon from "../../assets/img/EyeIcon.svg";
import ModalTemplate from "./ModalTemplate";

const EmailManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { mailCampaignList, campaignList } = useSelector((state) => state.campaignReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const [ ShowModalTemplate, setShowModalTemplate ] = useState(null)
        const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [ paginationLength, setPaginationLength ] = useState({});

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
        fetchData(page,userLimit,userType)
    };
    
    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
        fetchData(1,userLimit,e.target.value);
    };
    const [abortController, setAbortController] = useState({});

    useEffect(() => {
        fetchData(1,userLimit,userType);
         return () => {
            if(abortController.abort){
                abortController.abort();
                setAbortController(new AbortController());
            }
        };
    }, [ userSearch ]);

    // Get data from api
    const fetchData = async (currentPage,userLimit,userType) => {
        const signal = abortController.signal;
        setCurrentPage(currentPage)
        const ContactList = await GetEmailCampaign(accessToken, userSearch, userType, userLimit, currentPage,{signal});
        if(ContactList?.status === 200) {
            dispatch(setStoreMailCampaignList(ContactList.data));
            console.log("ContactList",ContactList)
            setPaginationLength(ContactList.pagination);
        } else if(ContactList?.status === 403) {
            CommonLogout();
            dispatch(setStoreMailCampaignList([]));
            setPaginationLength({})
        } else {
            dispatch(setStoreMailCampaignList([]));
            setPaginationLength({})
        };
    };

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // user active or deactive
    const ContactActivStatus = (user, type) => {
        if(type === "active_action") {
            setShowModal({
                ...showModal,
                open: !showModal?.open,
                title: "Are you sure?",
                description: `Do you really want to mark this as ${user?.is_active ? 'inactive?' : 'active?'}`,
                modalType: "not form",
                button: "Yes",
                category: "Contact Active",
                Data: user
            });
        } else if(type === "contacted_action") {
            setShowModal({
                ...showModal,
                open: !showModal?.open,
                title: "Are you sure?",
                description: `${user?.is_contacted ? 'Do you really not want to be contacted about this request?' : 'Do you really want to be contacted about this request?'}`,
                modalType: "not form",
                button: "Yes",
                category: "Contacted Us",
                Data: user
            });
        } else {
            setShowModal({
                ...showModal,
                open: !showModal?.open,
                title: "Are you sure?",
                description: "Do you really want to delete?",
                modalType: "not form",
                button: "Yes",
                category: "Delete contact",
                Data: user
            });
        }
    };

    const callUserLimit = (e) =>{
        setUserLimit(e);
        fetchData(1, e,userType)
    }

    return(<>
        <FiltersForm 
            filterType={"Contact(Subject )"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={callUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
            onAdd={()=>navigate(`/add${MAIL_CAMPAIGN_MANAGEMENT}/email`)}
            add_text={"Add Mail"}
        />
        <div className="tableContent">
            <Scrollbars 
                style={{ height: paginationLength?.total_records > 10 ? "calc(100% - 87px)" : "calc(100% - 50px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                {/* <th></th> */}
                                <th>Subject</th>
                                <th>Sponsors Logo</th>
                                <th>Campaign</th>
                                <th>Type</th>
                                {/* <th>Body</th> */}
                                <th>Created At</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {mailCampaignList && mailCampaignList?.map((user, index) => {
                                return(<React.Fragment key={index}>
                                <tr className={activeTableDetails === user?.id ? "active" : ""}>
                                    {/* <td style={{ width: '10px' }}>
                                        <button 
                                            className={activeTableDetails === user?.id ? "btn actionbtn active" : "btn actionbtn"}
                                            onClick={() => activeTableDetails === user?.id ? ElseMoreDetails() : ViewMoreDetails(user?.id)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={activeTableDetails === user?.id ? true : false}
                                        >
                                            <Image src={MoreIcon} alt="more icon" />
                                        </button>
                                    </td> */}
                                    <td>{user?.subject}</td>
                                    <td>
                                        
                                        {
                                            user?.presented_by?.length===0 ?  <React.Fragment>-</React.Fragment> :
                                            (
                                                user?.presented_by ? (
                                            <img src={user?.presented_by[0]} className="image_table" alt={user?.subject} />
                                        ):(
                                            <React.Fragment>-</React.Fragment>
                                        )
                                            )
                                        }
                                    </td>
                                    <td>{user?.campaign}</td>
                                    <td>{user?.type}</td>
                                    {/* <td>
                                        <span style={{height:70, width: 200, overflowY: 'auto', overflowX: 'hidden', fontSize:12, scrollbarWidth:2}} readOnly className="form-control" disabled dangerouslySetInnerHTML={{__html:user?.body}}  />
                                    </td> */}
                                    <td>{moment(user.created_at).format('LL HH:mm A')}</td>
                                    <td>
                                        
                                        <button className="btn actionbtn" onClick={()=>setShowModalTemplate(user)} >
                                            <Image  src={EyeIcon} alt="delete icon" />
                                        </button>
                                        <Link className="btn actionbtn" to={`/edit${MAIL_CAMPAIGN_MANAGEMENT}/${user?.id}`} state={user} >
                                            <Image src={EditIcon} alt="edit icon" />
                                        </Link>
                                    </td>
                                </tr>
                                <Collapse in={activeTableDetails === user?.id ? true : false}>
                                    <tr className="tablecollapsewraps" id={"tablecollpase"+user?.id}>
                                        <td colSpan="8">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Message</th>
                                                        <td>{user?.message === null ? "-" : user?.message}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </Collapse>
                            </React.Fragment>)
                            })}
                            {mailCampaignList && mailCampaignList.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>Mail Campaign Not Found !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
            {/* pagination */}
            {parseInt(paginationLength?.total_records) > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={paginationLength}
                currentFunction={currentFunction}
            />)}
        </div>
        {showModal?.open && (<CommonModal showModal={showModal} setShowModal={setShowModal} />)}
        {ShowModalTemplate && (<ModalTemplate data={ShowModalTemplate} setClose={setShowModalTemplate} />)}
    </>)
}

export default EmailManagement;