/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Form, Image, Button } from 'react-bootstrap';
import { useDispatch,useSelector } from "react-redux";
import { useNavigate,Link, useParams } from "react-router-dom";
import { ORDER_LIST, PACKAGE_MANAGEMENT } from "../Shared/constant";
import { AddPackageList, SelectPackagesTab, UpdatePackageX } from "../../redux/actions/packageAction";
import AddPackage from "../../Api/AddPackage";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CommonLoader from "../Common/CommonLoader";
import EditPackage from "../../Api/Editpackage";
import { SelectOrderTab } from "../../redux/actions/orderAction";
import GetOrderDetails from "../../Api/GetOrderDetails";
import Scrollbars from "react-custom-scrollbars-2";
import moment from "moment";
import Badge from 'react-bootstrap/Badge';


const ViewOrder = () =>{
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const {roomId} = useParams()

    const {CurrenUrlBaseForOrder} = useSelector((state)=>state.orderReducers)
    const { accessToken } = useSelector((state) => state.personalReducers);
    const {orderList} = useSelector((state)=>state.orderReducers)

    const [ paginationLength, setPaginationLength ] = useState({});
    const [singleorderData,setSingleOrderData] = useState([])
    const [loading,setLoading] = useState(true)



    const GetOrder = async() =>{
        const responce = await GetOrderDetails(accessToken,roomId)
        if(responce?.status === 200){
            setSingleOrderData(responce.data)
        }
        setLoading(false)
    }

    useEffect(()=>{
        if(roomId){
            GetOrder()
        }

    },[])
    const coupon = "";
    // var NewAr = []
    const [NewAr, setNewAr] = useState([]);
    const [coupons, setCoupons] = useState([]);


        const fetchCouponsWisedata = () => {
        const updatedCoupons = [];

        orderList.forEach((ele) => {
            if (ele?.id === roomId) {
                const newItem = {
                    bundle_package: ele?.bundle_package,
                    coupon: ele?.coupon,
                    id: ele?.id
                };

                setNewAr((prevNewAr) => [...prevNewAr, newItem]);

                if (ele?.bundle_order_id === NewAr[0]?.id) {
                    var userCoupon
                    for (let i = 1; i <= ele?.bundle_package?.quantity; i++) {
                        updatedCoupons.push(`${ele?.coupon}-${i}`);
                    }
                }
            }
        });

        setCoupons(updatedCoupons); 
    };

    useEffect(() => {
        fetchCouponsWisedata();
    }, []);



    // var filteredOrders = [];
    // function filterOrders() {
    
    //     singleorderData.forEach(order => {
    //         const { bundle_order_id, campaign_id, email, coupon, is_used, id, created_at, updated_at } = order;
    
    //         if (coupons.includes(order?.coupon)) {
                
    //             // console.log("filteredOrders",filteredOrders)
    //         }else{
    //             console.log(filteredOrders.push({
    //                 ...order,
    //                 coupons:coupons
    //             }));
    //         }
    //                         console.log("filteredOrders",filteredOrders)

    //     });
    
    //     return filteredOrders;
    // }
    // filterOrders()
        return(
        <>

<ul className="tabcomnsection">
        {CurrenUrlBaseForOrder === "view-package" ? (
            <li className={CurrenUrlBaseForOrder === "view-package" ? "active" : ""} onClick={() => dispatch(SelectOrderTab("add-package"))}>View</li>
        ) : (<></>)}
         <div className="tabheadebuttns">
        
           
            <Link className="btn savchanges linksmanage" to={"/admin/package-order"} >Back</Link>
        </div> 
    </ul>
    <div className="tableContent">
            <Scrollbars 
                style={{ height: paginationLength?.total_records > 10 ? "calc(100% - 87px)" : "calc(100% - 50px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Coupon</th>
                                <th>Status</th> 
                                <th>Email</th>
                                <th>Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {singleorderData && singleorderData?.map((element, index) => {

                                return(<>
                                <tr>
            <td>{element?.coupon}</td>
            <td>
                <Form className="switchbtn">
                    
                    <Badge bg={element?.is_used ? "primary" : "danger"}>{element?.is_used ? "Used" : "Unused"}</Badge>
                </Form>
            </td>
                                     <td>{element.email ? element.email :"-"} </td>
                                    <td>{element.created_at ? moment(element.created_at).format('LL') :"-"  }</td> 
            </tr>

                                </>)
                            })}
{
    loading ? <tr><td style={{ textAlign: "center" }} colSpan={8}>Loading...</td></tr> : 
                            (singleorderData && singleorderData.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>This coupon has not been used by any user yet.</td></tr>)
}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>

        </div>




        </>
    )
}
export default ViewOrder