export const ActionTypes = {
    GET_PACKAGES_LIST: "GET_PACKAGES_LIST",
    ADD_PACKAGES_NEW_LIST: "ADD_PACKAGES_NEW_LIST",
    PACKAGES_CURRENT_TAB: "PACKAGES_CURRENT_TAB",
    GET_PACKAGES_LIST_ID_WISE: "GET_PACKAGES_LIST_ID_WISE",
    DELETE_PACKAGES_LIST: "DELETE_PACKAGES_LIST",
    UPDATE_PACKAGES_NEW_LIST: "UPDATE_PACKAGES_NEW_LIST",
    SET_STORE_PACKAGES_LIST_OPTION: "SET_STORE_PACKAGES_LIST_OPTION",
    SET_STORE_UPDATE_PACKAGES_SEQUENCE:"SET_STORE_UPDATE_PACKAGES_SEQUENCE",
    SET_STORE_MAIL_PACKAGES_LIST:"SET_STORE_MAIL_PACKAGES_LIST",
    SET_STORE_MOSAIC_LIST:"SET_STORE_MOSAIC_LIST",
    SET_STORE_FREE_PACKAGE_LIST:"SET_STORE_FREE_PACKAGE_LIST",
    SET_DELETE_COUPON_LIST:"SET_DELETE_COUPON_LIST",
    SET_UPDATE_COUPON_LIST:"SET_UPDATE_COUPON_LIST",

};

export const SelectPackagesTab = (urls) => {
    return {
       type: ActionTypes.PACKAGES_CURRENT_TAB,
       payload: urls,
    }
};

export const getPackagesList = (list) => {
    return {
       type: ActionTypes.GET_PACKAGES_LIST,
       payload: list,
    }
};
export const DeletePackageList = (Campaign) => {
    return {
        type: ActionTypes.DELETE_PACKAGES_LIST,
        payload: Campaign,
    }
};
export const AddPackageList = (Package) => {
    return {
        type: ActionTypes.ADD_PACKAGES_NEW_LIST,
        payload: Package,
    }
};
export const UpdatePackageX = (Campaign) => {
    return {
        type: ActionTypes.UPDATE_PACKAGES_NEW_LIST,
        payload: Campaign,
    }
};
export const setStoreFreeCouponList = (Campaign) => {
    return {
        type: ActionTypes.SET_STORE_FREE_PACKAGE_LIST,
        payload: Campaign,
    }
};
export const setDeleteCouponList = (Campaign) => {
    return {
        type: ActionTypes.SET_DELETE_COUPON_LIST,
        payload: Campaign,
    }
};
export const setUpdateCouponList = (Campaign) => {
    return {
        type: ActionTypes.SET_UPDATE_COUPON_LIST,
        payload: Campaign,
    }
};

// export const setStoreMailCampaignList = (list) => {
//     return {
//        type: ActionTypes.SET_STORE_MAIL_CAMPAIGN_LIST,
//        payload: list,
//     }
// };
// export const setStoreMosaicList = (list) => {
//     return {
//        type: ActionTypes.SET_STORE_MOSAIC_LIST,
//        payload: list,
//     }
// };
// export const setStoreUpdateCampaignSequence = (list) => {
//     return {
//        type: ActionTypes.SET_STORE_UPDATE_CAMPAIGN_SEQUENCE,
//        payload: list,
//     }
// };




// export const GetCampaignListIDWise = (Campaign) => {
//     return {
//         type: ActionTypes.GET_CAMPAIGN_LIST_ID_WISE,
//         payload: Campaign,
//     }
// };


// export const setStoreCampaignListOption = (Campaign) => {
//     return {
//         type: ActionTypes.SET_STORE_CAMPAIGN_LIST_OPTION,
//         payload: Campaign,
//     }
// };