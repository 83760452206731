/* eslint-disable */
import { ActionTypes } from "../actions/fundActions";

const initailData = {
    fundraiserList: [],
    selectedFundraise: {},
    fundraiseListIdWise: {},
    fundraiseOptionMenu: "edit-fundraiser",
    fundraiseUpdateSelect: {},
    fundraiseUpdatesOptions: "view-updates",
    fundraiseUpdateList: []
};

export const fundraiseReducers = (state = initailData, action) => {
    switch (action.type) {
        case ActionTypes.GET_FUNDRAISE_LIST:
            return {
                ...state,
                fundraiserList: action.payload,
            };
        case ActionTypes.GET_FUNDRAISE_UPDATE_LIST:
            return {
                ...state,
                fundraiseUpdateList: action.payload,
            };
        case ActionTypes.ADD_FUNDRAISE_UPDATES:
            const fundraislistUpdatesNew = [...state.fundraiseUpdateList];
            fundraislistUpdatesNew.unshift(action.payload);
            return {
                ...state,
                fundraiseUpdateList: fundraislistUpdatesNew,
            };
        case ActionTypes.EDIT_FUNDRAISE_UPDATE_LIST:
            const OldfundraisUpdateslist = [...state.fundraiseUpdateList];
            const updateUpdatefundraiseList = OldfundraisUpdateslist.map((elm) => {
                if(elm?.id === action?.payload?.id) {
                    return{
                        ...elm,
                        description: action?.payload?.description,
                        fundraise: action?.payload?.fundraise,
                        image: action?.payload?.image,
                        to_donors: action?.payload?.to_donors,
                        updated_at: action?.payload?.updated_at,
                    }
                }else 
                return elm;
            });
            return {
                ...state,
                fundraiseUpdateList: updateUpdatefundraiseList,
            };
        case ActionTypes.SELECT_FUNDRAISE_UPDATE:
            return {
                ...state,
                fundraiseUpdateSelect: action.payload,
            };
        case ActionTypes.DELETE_FUNDRAISE_UPDATE_LIST:
            const CurrentUpdateList = [...state.fundraiseUpdateList];
            const NewUpdatedFundraiseLists = [];
            CurrentUpdateList && CurrentUpdateList.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedFundraiseLists.push(elm);
                }
            });

            return {
                ...state,
                fundraiseUpdateList: NewUpdatedFundraiseLists,
            }
        case ActionTypes.GET_FUNDRAISE_LIST_IDWISE:
            const fundraiseObjectBase = {};
            action.payload && action.payload.map((elm) => {
                fundraiseObjectBase[elm.slug] = elm;
            });
            return {
                ...state,
                fundraiseListIdWise: fundraiseObjectBase,
            };
        case ActionTypes.SELECT_FUNDRAISE:
            return {
                ...state,
                selectedFundraise: action.payload,
            };
        case ActionTypes.FUNDRAISE_ACTION_OPTIONS:
            return {
                ...state,
                fundraiseOptionMenu: action.payload,
            };
        case ActionTypes.FUNDRAISE_UPDATES_OPTIONS:
            return {
                ...state,
                fundraiseUpdatesOptions: action.payload,
            };
        case ActionTypes.ADD_FUNDRAISE_LIST:
            const fundraislistNew = [...state.fundraiserList];
            fundraislistNew.unshift(action.payload);
            return {
                ...state,
                fundraiserList: fundraislistNew,
            };
        case ActionTypes.EDIT_FUNDRAISE:
            const Oldfundraislist = [...state.fundraiserList];
            const updatefundraiseList = Oldfundraislist.map((elm) => {
                if(elm?.id === action?.payload?.id) {
                    return{
                        ...elm,
                        slug : action.payload.slug,
                        country : action.payload.country,
                        category : action.payload.category,
                        fundraise_for : action.payload.fundraise_for,
                        amount : action.payload.amount,
                        cover_image : action.payload.cover_image,
                        title : action.payload.title,
                        description : action.payload.description,
                        owner : action.payload.owner,
                        is_active : action.payload.is_active,
                        accept_donations : action.payload.accept_donations,
                        is_comments : action.payload.is_comments
                    }
                }else 
                return elm;
            });
            const fundraiseObjectBaseUpdate = {};
            updatefundraiseList && updatefundraiseList.map((elm) => {
                if(elm?.id === action?.payload?.id) {
                    fundraiseObjectBaseUpdate[elm.slug] = elm;
                };
            });
            return {
                ...state,
                fundraiserList: updatefundraiseList,
                fundraiseListIdWise: fundraiseObjectBaseUpdate,
            };
        case ActionTypes.DELETE_FUNDRAISE:
            const fundraiseLists = [...state.fundraiserList]; 
            const NewUpdatedFundraiseList = [];
            fundraiseLists && fundraiseLists.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedFundraiseList.push(elm);
                }
            });

            return {
                ...state,
                fundraiserList: NewUpdatedFundraiseList,
            };
        default:
        return state;
    }
}