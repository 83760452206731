/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Button, Form, Image } from 'react-bootstrap';
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { Scrollbars } from 'react-custom-scrollbars-2';
import AddBlog from "../../Api/AddBlog";
import GetBlogDetails from "../../Api/GetBlogDetails";
import EditBlogAPI from "../../Api/EditBlogAPI";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { editBlogLists, addBlogList } from "../../redux/actions/blogActions";
import CommonModal from "../Common/CommonModal";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ADMIN_BLOG_MANAGEMENT_URL } from "../Shared/constant";
import UploadFile from "../../Api/UploadFile";
import DefaultImage from "../../assets/img/DefaultProduct.jpg";
import UploadImageIcon from "../../assets/img/uploadImage.png";
import LoaderImage from "../../assets/img/spinner_transferent.svg";
import CommonLoader from "../Common/CommonLoader";

const EditBlog = () => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const Location = useLocation()?.pathname.split('/')[3];
    const EditsLocation = useLocation()?.pathname;
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { AllTotalCategory } = useSelector((state) => state.categoryReducers);
    const [ AvatarImage, setAvatarImage ] = useState("")
    const [ AddBlogsState, setAddBlogsState ] = useState({
        title: "",
        category: "",
    });
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [ description, setDescription ] = useState("");
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [ LoaderStart, setLoaderStart ] = useState(false);

    // error message
    const [ errorAvatar, setErrorAvatar ] = useState("");
    const [ errorname, setErrorName ] = useState("");
    const [ errorDescription, setErrorDescription ] = useState(false);
    const [ errorCategory, setErrorCategory ] = useState("");

    // last key code check and remove value
    const ValueKeyPress = (e) => {
        const code = e.keycode || e.which;
        if(code === 8 && e.target.innerHTML === "<p><br></p>") {
            setDescription("");
        };
    };

    // editor code
    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link"
    ];
    const modules = {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" }
          ],
          ["link"],
          ["clean"]
        ]
    }; 

    // get fundraise details
    const fetchData = async () => {
        setLoaderStart(true);
        const Blogdetailsget = await GetBlogDetails(accessToken, Location);
        if(Blogdetailsget) {   
            setAvatarImage((Blogdetailsget?.data?.avatar === "" || Blogdetailsget?.data?.avatar === null || Blogdetailsget?.data?.avatar === undefined) ? "" : Blogdetailsget?.data?.avatar);
            setAddBlogsState({
                ...AddBlogsState,
                title: (Blogdetailsget?.data?.title === "" || Blogdetailsget?.data?.title === null || Blogdetailsget?.data?.title === undefined) ? "" : Blogdetailsget?.data?.title,
                category: (Blogdetailsget?.data?.category === "" || Blogdetailsget?.data?.category === null || Blogdetailsget?.data?.category === undefined) ? "" : Blogdetailsget?.data?.category,
            });
            setDescription((Blogdetailsget?.data?.description === "" || Blogdetailsget?.data?.description === null || Blogdetailsget?.data?.description === undefined) ? "" : Blogdetailsget?.data?.description);
            setTimeout(() => {
                setLoaderStart(false);
            }, 1000);
        };
    };

    useEffect(() => {
        if(EditsLocation !== "/add/blog") {
            fetchData();
        };
    }, [ accessToken, Location ]);

    // upload image 
    const UploadImage = async (e) => {
        setUploadFileLoader(true);
        const files = e.target.files[0];
        const formData = new FormData();
        formData.append("file", files, files.name);
        const fileresponse = await UploadFile(accessToken, formData);
        if(fileresponse) {
            setUploadFileLoader(false);
            setAvatarImage(fileresponse?.file_url_400);
            setErrorAvatar("");
        };
    };

    // select category
    const SelectCategory = (event) => {
        setAddBlogsState({...AddBlogsState, category: event.target.value});
        CheckValid(event.target.value, {type: 'selectcategoryname', error: setErrorCategory });
    };

    // edit user function
    const EditData = async () => {
        if((AvatarImage !== "" && AvatarImage !== null) && AddBlogsState?.title !== "" && description !== "" && AddBlogsState?.category !== "") {
            setErrorDescription(false);
            if(EditsLocation === "/add/blog") {
                const jsonData = JSON.stringify({ 
                    category : AddBlogsState?.category,
                    avatar: AvatarImage,
                    title: AddBlogsState?.title,
                    description: description,
                });
                const response = await AddBlog(accessToken, jsonData);
                if(response?.status === 200){
                    setTimeout(() => {
                        toast.success(response.msg);
                        dispatch(addBlogList(response?.data));
                        Navigate(ADMIN_BLOG_MANAGEMENT_URL);
                    }, 100);
                } else {
                    toast.error(response?.error || response.data.error);
                };
            } else {
                const jsonData = JSON.stringify({ 
                    category : AddBlogsState?.category,
                    avatar: AvatarImage,
                    title: AddBlogsState?.title,
                    description: description,
                    slug: Location
                });
                const response = await EditBlogAPI(accessToken, jsonData);
                if(response?.status === 200){
                    setTimeout(() => {
                        toast.success(response.msg);
                        dispatch(editBlogLists(response?.data));
                        Navigate(ADMIN_BLOG_MANAGEMENT_URL);
                    }, 100);
                } else {
                    toast.error(response?.error || response.data.error);
                };
            }
        } else {
            CheckValid(AvatarImage, {type: 'avatar', error: setErrorAvatar });
            CheckValid(AddBlogsState?.title, {type: 'name', error: setErrorName });
            CheckValid(AddBlogsState?.category, {type: 'categoryname', error: setErrorCategory });
            setErrorDescription(true);
        };
    };

    return(<>
            <div className="tabheadse">
                <Link className="btn actionbtn" to={ADMIN_BLOG_MANAGEMENT_URL} >Back</Link>
            </div>
            <section className="formsectionset">
                <div className="tabcontent">
                    <Scrollbars 
                        style={{ height: "calc(100vh - 162px)" }} 
                        className="ScrollbarsWrapper"
                        renderView={props => <div {...props} className="view"/>}
                    >
                       <div className="tabsWrappers">
                            <div className="formloginset">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Blog Avatar</Form.Label>
                                            {(AvatarImage !== undefined && AvatarImage !== "") ? (<div className="imageuploads">
                                                <Image src={AvatarImage} alt="charity avatar" />
                                                <span className="closeIcon" onClick={() => setAvatarImage("")}></span>
                                            </div>) :
                                            (<div className="uploadbuttons"
                                            onClick={() => document.getElementById('fileInput12')?.click()}

                                            >
                                                <div className={UploadFileLoader ? "fileloader active" : "fileloader"}>
                                                    {UploadFileLoader ? 
                                                    (<Image src={LoaderImage} className="uploadImgIconse uploadimagload" alt="upload image" />) : 
                                                    (<>
                                                        <Image src={UploadImageIcon} className="uploadImgIconse" alt="upload image" />
                                                        <input type="file" id="fileInput12" className="uploadfilInput" onChange={(e) => UploadImage(e)} accept=".svg, .png, .jpg, .jpeg, .webp"/>
                                                    </>)}
                                                </div>
                                                <div className="uploadfilebox">
                                                    <Image src={DefaultImage} alt="upload image" />
                                                </div>
                                            </div>)}
                                            {errorAvatar && <div className="danger-colorset">{errorAvatar}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Blog Title</Form.Label>
                                            <Form.Control type="text" value={AddBlogsState?.title} onKeyUp={(e) => CheckValid(e.target.value, {type: 'name', error: setErrorName })} onChange={(e) => setAddBlogsState({...AddBlogsState, title: e.target.value})} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter First Name" />
                                            {errorname && <div className="danger-colorset">{errorname}</div>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="mb-3 formeditsetwps" >
                                            <Form.Label>Category</Form.Label>
                                            <select className="form-control" value={AddBlogsState?.category} onChange={(e) => SelectCategory(e)} aria-label="Default select example">
                                                <option value="">Select Category</option>
                                                {AllTotalCategory && AllTotalCategory.map((elm, index) => {
                                                    return(<option  value={elm?.name} key={index}>{elm?.name}</option>)
                                                })}
                                            </select>
                                            {errorCategory && <div className="danger-colorset">{errorCategory}</div>}
                                        </Form.Group>
                                    </div>
                                </div>                                    
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Description</Form.Label>
                                            <div className="editorBox">
                                                <ReactQuill 
                                                    formats={formats}
                                                    modules={modules}
                                                    theme="snow" 
                                                    value={description} 
                                                    onChange={setDescription} 
                                                    onKeyDown={(e) =>setTimeout(()=> ValueKeyPress(e), 10)}
                                                />
                                            </div>
                                            {(errorDescription && description === "") && <div className="danger-colorset">Description cannot be empty</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Button variant="primary" onClick={() => EditData()}>
                                            {EditsLocation === "/add/blog" ? "Add Blog" : "Save Changes"}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                </Scrollbars>
            </div>
        </section>
        {showModal?.open && (<CommonModal showModal={showModal} setShowModal={setShowModal} />)}
        {/* common loader */}
        {LoaderStart && <CommonLoader classNameS={"blogLoader"} />}
    </>);
}

export default EditBlog;