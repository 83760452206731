/* eslint-disable */
import { ActionTypes } from "../actions/dashboardAction";

const initailData = {
    dashboardDetails: {},
};

export const dashboardReducers = (state = initailData, action) => {
    switch (action.type) {
        case ActionTypes.GET_DASHBOARD_COUNT_LIST:
            return {
                ...state,
                dashboardDetails: action.payload,
            };
        default:
        return state;
    }
};