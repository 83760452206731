/* eslint-disable */
import React, { useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getDonationsList } from "../../redux/actions/donationAction";
import moment from "moment";
import GetFundDonations from "../../Api/GetFundDonations";
import { useSelector } from "react-redux";

const Exportpopupmodel = (props) => {
  const {
    showModal2,
    setShowModal2,
    paginationLength,
    setCurrentPage,
    setPaginationLength,
    userType,
    FilterForm,
    abortController,
    userSearch,
    paymentMethod,
    downloadData
  } = props;
const dispatch = useDispatch()
const { accessToken} = useSelector((state) => state.personalReducers);
const [loadingStates, setLoadingStates] = useState([false, false, false, false, false]);
const [successStates, setSuccessStates] = useState([false, false, false, false, false]);
const [buttonsDisabled, setButtonsDisabled] = useState(false); // New state for disabling buttons



  const totalPortions = Math.ceil(paginationLength.total_records / paginationLength.record_limit);
  const handleClose = () => {
      setShowModal2({ ...showModal2, open: false, Data: {} });
      setLoadingStates(Array(totalPortions).fill(false));
      setSuccessStates(Array(totalPortions).fill(false));
      setButtonsDisabled(false); // Re-enable buttons when closing the modal

  };
  const handleExportClick = async (portion) => {
    let portionLimit;

    // if (portion === totalPortions) {
    //     portionLimit = paginationLength.total_records - (paginationLength.record_limit * (portion - 1));
    // } else {
    //     portionLimit = paginationLength.record_limit;
    // }
    portionLimit = paginationLength.record_limit
    
    const offset = (portion - 1) * paginationLength.record_limit; 
    setButtonsDisabled(true); 
    const updatedLoadingStates = [...loadingStates];
    updatedLoadingStates[portion - 1] = true;
    setLoadingStates(updatedLoadingStates);

    // Call fetchData with dynamic portionLimit
    await fetchData(portion, portionLimit, userType, { ...FilterForm, res: `export-${showModal2?.type}`, portion, offset });
   
};
const updateLoadingAndSuccessStates = (portion, isLoading, isSuccess) => {
    // Update loading state
    const updatedLoadingStates = [...loadingStates];
    updatedLoadingStates[portion - 1] = isLoading;
    setLoadingStates(updatedLoadingStates);

    // Update success state
    const updatedSuccessStates = [...successStates];
    updatedSuccessStates[portion - 1] = isSuccess;
    setSuccessStates(updatedSuccessStates);
};
const fetchData = async (portion, recordLimit, userType, FilterForm, type) => {
    const signal = abortController?.signal;

    setCurrentPage(portion);

    const DonationsLists = await GetFundDonations(accessToken, userSearch, userType, recordLimit, portion, { signal }, FilterForm, paymentMethod);
    // console.log("DonationsLists",DonationsLists)
    if (DonationsLists?.status === 200) {
        setButtonsDisabled(false);
        updateLoadingAndSuccessStates(portion, false, true);
        if (FilterForm?.res === "json") {
            dispatch(getDonationsList(DonationsLists.data));
            setPaginationLength(DonationsLists.pagination);
        } else if (DonationsLists?.data) {
          if(type==="avatar"){
            downloadData(DonationsLists?.data, `DONATION_${moment().format("YYYY-MM-DD HHmmss a")}.zip`);
        }else{
            const ResultFile = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + DonationsLists?.data;
            var link = document.createElement('a');
            link.href = ResultFile;
            const name_time = moment().format('YYYY-MM-DD-HHmmssA')
            link.download = `donation-emails-${name_time}.xlsx`;
            link.click();
        }
            // downloadData(DonationsLists?.data, `DONATION_${moment().format("YYYY-MM-DD HHmmss a")}.zip`);
        }
    } else if (DonationsLists?.status === 403) {
        setButtonsDisabled(false);
        updateLoadingAndSuccessStates(portion, false, false);
        CommonLogout();
        if (FilterForm?.res === "json") {
            dispatch(getDonationsList([]));
            setPaginationLength({});
        }
    } else {
        setButtonsDisabled(false);
        updateLoadingAndSuccessStates(portion, false, false);
        if (FilterForm?.res === "json") {
            dispatch(getDonationsList([]));
            setPaginationLength({});
        }
    }
};


  return (
    <>
      <Modal
        className="commonModal"
        show={showModal2?.open}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              width: showModal2?.title === "Are you sure?" ? "100%" : "",
            }}
          >
            {showModal2?.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="notformsection ExportModel_wrapper">
            {[...Array(totalPortions)].map((_, portion) => {
                            return (
                                <div key={portion + 1}>
                                    <Button
                                        className="btn btn-primary cmnfilterHeadbtn"
                                        style={{
                                            gap: "1rem",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                        onClick={() => handleExportClick(portion + 1)}
                                        // disabled={loadingStates[portion]}
                                        // disabled={loadingStates[portion]}
                                        disabled={buttonsDisabled}
                                    >
                                        Export Avatar of Page {portion + 1} 
                                        {loadingStates[portion] && (
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                style={{ marginLeft: "10px" }}
                                            />
                                        )}
                                    </Button>
                                </div>
                            );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Exportpopupmodel;
