/* eslint-disable */
import { ActionTypes } from "../actions/categoryActions";

const initailData = {
    categoryList: [],
    AllTotalCategory: [],
    categoryListId: {},
};

export const categoryReducers = (state = initailData, action) => {
    switch (action.type) {
        case ActionTypes.GET_CATEGORY_LIST:
            return {
                ...state,
                categoryList: action.payload,
            };
        case ActionTypes.GET_ALL_CATEGORY_LIST:
            return {
                ...state,
                AllTotalCategory: action.payload,
            };
        case ActionTypes.ADD_CATEGORY_LIST:
            const oldCategory = [...state.categoryList];
            oldCategory.unshift(action.payload);
            return {
                ...state,
                categoryList: oldCategory,
            };
        case ActionTypes.GET_CATEGORY_LIST_ID_WISE:
            let arraycategorylist = {};
            action.payload && action.payload.map((elm) => {
                arraycategorylist[elm.slug] = elm;
            });
            return {
                ...state,
                categoryListId: arraycategorylist,
            };
        case ActionTypes.EDIT_CATEGORY_LIST:
            const categoryListOld = [...state.categoryList]; 
            const updateCategoryList = categoryListOld.map((elm) => {
                if(elm?.id === action?.payload?.id) {
                    return{
                        ...elm,
                        name : action.payload.name,
                        slug : action.payload.slug,
                        is_active : action.payload.is_active,
                        updated_at : action.payload.updated_at,
                    }
                }else 
                return elm;
            });
            return {
                ...state,
                categoryList: updateCategoryList,
            };
        case ActionTypes.DELETE_CATEGORY_LIST:
            const categoryLists = [...state.categoryList]; 
            const NewUpdatedCategoryList = [];
            categoryLists && categoryLists.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedCategoryList.push(elm);
                }
            });

            return {
                ...state,
                categoryList: NewUpdatedCategoryList,
            };
        default:
        return state;
    }
}