import { ActionTypes } from "../actions/paymentAction";

const initailData = {
    paymentList: [],
    CurrenUrlBaseForPayment: "",
};
export const paymentReducers = (state = initailData, action) => {
    switch (action.type) {
        case ActionTypes.GET_PAYMENT_LIST:
            return {
                ...state,
                paymentList: action.payload,
            };
        case ActionTypes.PAYMENT_CURRENT_TAB:
            return {
                ...state,
                CurrenUrlBaseForPayment: action.payload,
            };
        default:
        return state;
    }
}