import React, { useState } from "react";
import { Button, Form } from 'react-bootstrap';
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { Scrollbars } from 'react-custom-scrollbars-2';
import EditUserList from "../../Api/EditUserList";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { clearRedux, checkLogin } from "../../redux/actions/personalActions";
import { editUserLists } from "../../redux/actions/usersActions";
import CountrySelectInput from "../Common/CountrySelectInput/CountryMobileInput";
import { Country }  from 'country-state-city';
import LogoutAPI from "../../Api/LogoutAPI";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { LOGIN_URL } from "../Shared/constant";
import CommonModal from "../Common/CommonModal";
import { USER_MANAGEMENT_URL } from "../Shared/constant";

const UserEdit = () => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const Location = useLocation()?.pathname.split('/')[3];
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { userListId } = useSelector((state) => state.userReducers);
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
    const [ EditUser, setEditUser ] = useState({
        fname: userListId[Location]?.first_name === null ? "" : userListId[Location] && userListId[Location]?.first_name,
        lname: userListId[Location]?.last_name === null ? "" : userListId[Location]?.last_name,
        email: userListId[Location]?.email === null ? "" : userListId[Location]?.email,
        phone: userListId[Location]?.phone === null ? "" : userListId[Location]?.phone,
        phone_code: userListId[Location]?.phone_code === null ? "" : userListId[Location]?.phone_code,
        country: userListId[Location]?.country === null ? "" : userListId[Location]?.country,
    });
    const [ comonTab, setComonTab ] = useState("account");
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });

    // error message
    const [ errorFname, setErrorFname ] = useState("");
    const [ errorLname, setErrorLname ] = useState("");
    const [ errorEmail, setErrorEmail ] = useState("");
    const [ errorPhone, setErrorPhone ] = useState("");
    const [ errorCountry, setErrorCountry ] = useState("");

    // select country 
    const SelectCoutry = (event) => {
        setEditUser({...EditUser, country: event.target.value});
        CheckValid(event.target.value, {type: 'country', error: setErrorCountry });
    };

    // edit user function
    const EditData = async () => {
        if(EditUser?.fname !== "" && EditUser?.lname !== "" && EditUser?.email !== "" && EditUser?.phone !== "" && EditUser?.country !== "") {
            if(EmailRegex.test(EditUser?.email) === true && EditUser?.phone.length === 10) {
                const jsonData = JSON.stringify({ 
                    first_name: EditUser?.fname, 
                    last_name: EditUser?.lname,
                    email: EditUser?.email, 
                    phone: EditUser?.phone,
                    phone_code: EditUser?.phone_code,
                    country: EditUser?.country,
                    user_id: userListId[Location]?.id
                });
                const response = await EditUserList(accessToken, jsonData);
                if(response?.status === 200){
                    setTimeout(() => {
                        toast.success(response.msg);
                        dispatch(editUserLists(response.data));
                        Navigate(USER_MANAGEMENT_URL);
                    }, 100);
                } else if(response?.status === 200) {
                    const responce = await LogoutAPI(accessToken);
                    toast.success(responce.msg);
                    dispatch(clearRedux());
                    dispatch(checkLogin('no'));
                    Navigate(LOGIN_URL);
                } else {
                    toast.error(response?.error || response.data.error);
                };
            } else {
                CheckValid(EditUser?.email, {type: 'email', error: setErrorEmail });
                CheckValid(EditUser?.phone, {type: 'phone', error: setErrorPhone });
            }
        } else {
            CheckValid(EditUser?.fname, {type: 'fname', error: setErrorFname });
            CheckValid(EditUser?.lname, {type: 'lname', error: setErrorLname });
            CheckValid(EditUser?.email, {type: 'email', error: setErrorEmail });
            CheckValid(EditUser?.phone, {type: 'phone', error: setErrorPhone });
            CheckValid(EditUser?.country, {type: 'country', error: setErrorCountry });
        };
    };

    // user active and deactivated
    const UserActivStatus = () => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            description: "Do you really want to deactivate",
            modalType: "not form",
            button: "Yes",
            category: "User Active",
            Data: userListId[Location]
        });
    };

    return(<>
            <section className="formsectionset">
                <ul className="tabcomnsection">
                    <li className={comonTab === "account" ? "active" : ""} onClick={() => setComonTab("account")}>Account</li>
                    {/* <li className={comonTab === "userFundraise" ? "active" : ""} onClick={() => setComonTab("userFundraise")}>User Fundraise</li>
                    <li className={comonTab === "bankDetails" ? "active" : ""} onClick={() => setComonTab("bankDetails")}>Bank Details</li> */}
                    <div className="tabheadebuttns">
                        <Link className="btn savchanges linksmanage" to={USER_MANAGEMENT_URL} >Back</Link>
                    </div>
                </ul>
                <div className="tabcontent">
                    <Scrollbars 
                        style={{ height: "calc(100%)" }} 
                        className="ScrollbarsWrapper"
                        renderView={props => <div {...props} className="view"/>}
                    >
                        {comonTab === "account" ? (<div className="tabsWrappers">
                            <div className="formloginset">
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group className="mb-3" >
                                            <Form.Label className="label-required">Full Name</Form.Label>
                                            <Form.Control type="text" value={EditUser?.fname} onKeyUp={(e) => CheckValid(e.target.value, {type: 'fname', error: setErrorFname })} onChange={(e) => setEditUser({...EditUser, fname: e.target.value})} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter First Name" />
                                            {errorFname && <div className="danger-colorset">{errorFname}</div>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>&nbsp;</Form.Label>
                                            <Form.Control type="text" value={EditUser?.lname} onKeyUp={(e) => CheckValid(e.target.value, {type: 'lname', error: setErrorLname })} onChange={(e) => setEditUser({...EditUser, lname: e.target.value})} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter Last Name" />
                                            {errorLname && <div className="danger-colorset">{errorLname}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form.Group className="mb-3" >
                                            <Form.Label className="label-required">Email address</Form.Label>
                                            <Form.Control type="text" value={EditUser?.email} onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: setErrorEmail })} onChange={(e) => setEditUser({...EditUser, email: e.target.value})} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter Email address" />
                                            {errorEmail && <div className="danger-colorset">{errorEmail}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form.Group className="mb-3" >
                                            <Form.Label className="label-required">Phone number</Form.Label>
                                            <CountrySelectInput phone={EditUser} setPhone={setEditUser} CheckValid={CheckValid} setErrorPhone={setErrorPhone} />
                                            {errorPhone && <div className="danger-colorset">{errorPhone}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form.Group className="mb-3" >
                                            <Form.Label className="label-required">Country</Form.Label>
                                            <select className="form-control" defaultValue={EditUser?.country} onChange={(e) => SelectCoutry(e)} aria-label="Default select example">
                                                <option value="">Select Country</option>
                                                {Country.getAllCountries().map((elm, index) => {
                                                    return(<option value={elm.name}  key={index}>{elm.name}</option>)
                                                })}
                                            </select>
                                            {errorCountry && <div className="danger-colorset">{errorCountry}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="deactivatewrapper">
                                            <h4>Deactivate your account will remove all of your activity and campaigns, and you will no longer be able to sign in with this account.</h4>
                                            {<button onClick={() => UserActivStatus()}>{userListId[Location]?.is_active === true ? "Deactivate Account" : "Activate Account"}</button>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Button className="widthfullbtn" variant="primary" onClick={() => EditData()}>
                                            Save Changes
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>) : comonTab === "userFundraise" ? 
                        (<div className="tabsWrapper">User Fundraise</div>) : 
                        (<div className="tabsWrapper">Bank Details</div>)}
                </Scrollbars>
            </div>
        </section>
        {showModal?.open && (<CommonModal showModal={showModal} setShowModal={setShowModal} />)}
    </>);
}

export default UserEdit;