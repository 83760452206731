export const ActionTypes = {
    GET_BLOG_LIST: "GET_BLOG_LIST",
    ADD_BLOG_LIST: "ADD_BLOG_LIST",
    EDIT_BLOG_LIST: "EDIT_BLOG_LIST",
    DELETE_BLOG_LIST: "DELETE_BLOG_LIST"
};

export const getBlogList = (blogs) => {
    return {
       type: ActionTypes.GET_BLOG_LIST,
       payload: blogs,
    }
};

export const addBlogList = (addblogs) => {
    return {
        type: ActionTypes.ADD_BLOG_LIST,
        payload: addblogs,
    }
};

export const editBlogLists = (editblogs) => {
    return {
       type: ActionTypes.EDIT_BLOG_LIST,
       payload: editblogs,
    }
};

export const deleteBlogLists = (deleteblogs) => {
    return {
        type:ActionTypes.DELETE_BLOG_LIST,
        payload: deleteblogs,
    }
};