/* eslint-disable */
import { ActionTypes } from "../actions/blogActions";

const initailData = {
    blogList: []
};

export const blogReducers = (state = initailData, action) => {
    switch (action.type) {
        case ActionTypes.GET_BLOG_LIST:
            return {
                ...state,
                blogList: action.payload,
            };
        case ActionTypes.ADD_BLOG_LIST:
            const oldBlog = [...state.blogList];
            oldBlog.unshift(action.payload);
            return {
                ...state,
                blogList: oldBlog,
            };
        case ActionTypes.EDIT_BLOG_LIST:
            const BlogListOld = [...state.blogList]; 
            const updateBlogList = BlogListOld.map((elm) => {
                if(elm?.id === action?.payload?.id) {
                    return{
                        ...elm,
                        name : action.payload.name,
                        slug : action.payload.slug,
                        is_active : action.payload.is_active,
                        updated_at : action.payload.updated_at,
                    }
                }else 
                return elm;
            });
            return {
                ...state,
                blogList: updateBlogList,
            };
        case ActionTypes.DELETE_BLOG_LIST:
            const blogsLists = [...state.blogList]; 
            const NewUpdatedBlogList = [];
            blogsLists && blogsLists.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedBlogList.push(elm);
                }
            });
            return {
                ...state,
                blogList: NewUpdatedBlogList,
            };
        default:
        return state;
    }
}