import React from "react";

const CommonLoader = (props) => {
    const { classNameS, } = props;

    return(<div className={`commonLoader ${classNameS}`}>
        <div className="loader"></div>
    </div>)
};

export default CommonLoader;