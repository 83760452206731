import React, { useState } from "react";
import { Form, Badge } from 'react-bootstrap';
import CommonModal from "../Common/CommonModal";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Settings = () => {
    const CurrentSlug = useLocation()?.pathname.split('/')[3];
    const { fundraiseListIdWise } = useSelector((state) => state.fundraiseReducers);
    const SelectedFundraise = fundraiseListIdWise[CurrentSlug];
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });

    // category activate & deactivate function
    const AllowFundraiseSettings = (type) => {
        if(type === "allow_active") {
            setShowModal({
                ...showModal,
                open: !showModal?.open,
                title: "Are you sure?",
                description: `Do you really want to ${SelectedFundraise?.is_active ? "deactivate" : "active"}`,
                modalType: "not form",
                button: "Yes",
                category: "fundraiser active",
                Data: SelectedFundraise
            });
        } else if(type === "allow_comments") {
            setShowModal({
                ...showModal,
                open: !showModal?.open,
                title: "Are you sure?",
                description: `Do you really want to ${SelectedFundraise?.is_comments ? "block comment" : "allow comment"}`,
                modalType: "not form",
                button: "Yes",
                category: "fundraiser comment",
                Data: SelectedFundraise
            });
        } else {
            setShowModal({
                ...showModal,
                open: !showModal?.open,
                title: "Are you sure?",
                description: `Do you really want to ${SelectedFundraise?.accept_donations ? "block donations" : "allow donations"}`,
                modalType: "not form",
                button: "Yes",
                category: "fundraiser donations",
                Data: SelectedFundraise
            });
        };
    };

    return(<div className="tabsWrapper">
        <ul className="formsetting">
            <li>
                <h4>Allow for active fundraiser</h4>
                <Form className="switchbtn">
                    <Form.Check 
                        type="switch"
                        id="custom-switch"
                        checked={SelectedFundraise?.is_active}
                        onChange={e => {}}
                        onClick={() => AllowFundraiseSettings("allow_active")}
                    />
                    <Badge bg={SelectedFundraise?.is_active ? "primary" : "danger"}>{SelectedFundraise?.is_active ? "Yes" : "No"}</Badge>
                </Form>
            </li>
            <li>
                <h4>Allow words of support for the fundraiser.</h4>
                <Form className="switchbtn">
                    <Form.Check 
                        type="switch"
                        id="custom-switch"
                        checked={SelectedFundraise?.is_comments}
                        onChange={e => {}}
                        onClick={() => AllowFundraiseSettings("allow_comments")}
                    />
                    <Badge bg={SelectedFundraise?.is_comments ? "primary" : "danger"}>{SelectedFundraise?.is_comments ? "Yes" : "No"}</Badge>
                </Form>
            </li>
            <li>
                <h4>Allow donations for the fundraiser.</h4>
                <Form className="switchbtn">
                    <Form.Check 
                        type="switch"
                        id="custom-switch"
                        checked={SelectedFundraise?.accept_donations}
                        onChange={e => {}}
                        onClick={() => AllowFundraiseSettings("allow_donations")}
                    />
                    <Badge bg={SelectedFundraise?.accept_donations ? "primary" : "danger"}>{SelectedFundraise?.accept_donations ? "Yes" : "No"}</Badge>
                </Form>
            </li>
        </ul>
        {showModal?.open && (<CommonModal showModal={showModal} setShowModal={setShowModal} />)}
    </div>)
}

export default Settings;