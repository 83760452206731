/* eslint-disable */
import React, { useState,useEffect,useRef } from "react";
import { Image } from "react-bootstrap";
import CloseIcon from '../../assets/img/closebutton.png';
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import AddIcon from "../../assets/img/plus.png";
import { FUNDRSISERS_MANAGEMENT_URL } from "../Shared/constant";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import date_time from "../../assets/img/date_time.png";
import { useLocation } from "react-router-dom";
import moment from "moment";
import 'moment-timezone';

const FiltersForm = (props) => {
    const {AddPackage, filterType, userSearch, AddCampaign, AddAthleteTbs, AddCategory, AddBlog, AddBlogCategory, AddFundraise, AddCharity, setUserSearch, userType, userLimit, setUserLimit, ClearSearchFiled, SelectUserTypeFnct,loading, setPaymentMethod, paymentMethod,showDesign,handleExport } = props;
    const { campaignOptionList } = useSelector((state) => state.campaignReducers);
    const { is_mosaic,selfInfo } = useSelector((state) => state.personalReducers);
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [inputSearch, setInputSearch] = useState("");
    const [isOthersSelected, setIsOthersSelected] = useState(false);
    const timeZone = moment.tz.guess();
    const offset = moment.tz(new Date(), timeZone).format('Z');
    const wrapperRef = useRef(null);




    const handleSelectChange = (e) => {
        const value = e.target.value;
        if (value === "others") {
          setIsOthersSelected(true); // Show input field when "Others" is selected
          setUserLimit(""); // Clear the userLimit value
        } else {
          setUserLimit(value); // Set the selected value
        }
      };
    
      const handleInputChange = (e) => {
        let value = e.target.value;
        if (/^\d*$/.test(value)) { // Allow only numeric input
          const numValue = parseInt(value, 10);
          if (numValue >= 1 && numValue <= 400) {
            setUserLimit(value); // Set if within range
          } else if (value === "") {
            setUserLimit(""); // Allow empty input for typing
          }
        }
      };
      const handleCancelClick = () => {
        setIsOthersSelected(false); // Hide input field and show select
        setUserLimit("10"); // Reset to default or previous selected value
      };
    
    const onChange = (dates, name) => {
      props?.setFilterForm({
        ...props?.FilterForm,
        [name]: dates ? dates: null,
      });
    };
    const onChangeText = (e) => {
      props?.setFilterForm({
        ...props?.FilterForm,
        [e.target.name]: e.target.value
      });

    };
    const onChangeInput = (event) =>{
        // setUserSearch(e.target.value)
        const { value } = event.target;
        setInputSearch(value);

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setTypingTimeout(setTimeout(() => setUserSearch(value), 1000));
    }
    const onClear = () =>{
        ClearSearchFiled();
        setInputSearch("");
    }
    // console.log("selfInfo",selfInfo)
    const location = useLocation();

    // Check if the current path includes '/donations-management'
    const isDonationsManagement = location.pathname.includes('/donations-management');


    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            props.setShowDesign(false); // Close the popup when clicking outside
        }
    };

    useEffect(() => {
        // Add event listener to detect clicks outside of the component
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

  
    return(<div className="card-header-New">
    {
        is_mosaic==="yes" ?
         null:
        (filterType === "Payment List" ? null :
        (filterType !== "Donations Fundraise (Name)" && (<div className="searchBoxwithbtn"
        style={{
            width:isDonationsManagement  ? "200px" :"260px"
        }}
        >
            <input type="text" className="form-control" value={inputSearch} onKeyDown={(e) => EmptySpaceFieldValid(e)} onChange={onChangeInput} placeholder={`Search ${filterType} ...`}/>
            {userSearch === "" ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                <path d="M21 21l-6 -6"></path>
            </svg>) : (<img src={CloseIcon} className="icon searchclear" onClick={onClear} alt="close" />)}
        </div>)))            
    }
 {
    isDonationsManagement && selfInfo?.permission==="mosaic-management,donation-management" ? 
       (filterType === "Payment List" ? null :
        (filterType !== "Donations Fundraise (Name)" && (<div className="searchBoxwithbtn"
         style={{
            width:isDonationsManagement  ? "200px" :"260px"
        }}
        >
            <input type="text" className="form-control" value={inputSearch} onKeyDown={(e) => EmptySpaceFieldValid(e)} onChange={onChangeInput} placeholder={`Search ${filterType} ...`}/>
            {userSearch === "" ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                <path d="M21 21l-6 -6"></path>
            </svg>) : (<img src={CloseIcon} className="icon searchclear" onClick={onClear} alt="close" />)}
        </div>))):null
 }



        <div className="card-header-right">
            {(props?.FilterForm?.start_date !== undefined && !props?.pageTab) && (
                <div className="time-picker-input me-2" style={{float:"left"}}>
                    <ReactDatePicker
                        selected={props?.FilterForm?.start_date}
                        onChange={(e)=>onChange(e, "start_date")}
                        icon={
                            <span className="icon-span">
                                <img src={date_time} alt="time" />
                            </span>
                        }
                        showIcon
                        placeholderText="Start Date"
                        isClearable={props?.FilterForm?.start_date ? true: false}
                        maxDate={props?.FilterForm?.end_date || new Date()}
                        startDate={props?.FilterForm?.start_date || null}
                        endDate={props?.FilterForm?.end_date || null}
                        dateFormat="dd-MM-yyyy"
                        timeIntervals={1}
                        showTimeSelect
                        timeFormat={timeZone ==="Asia/Calcutta" ? "hh:mm aa" : "HH:mm"}                    />
                </div>
            )}
            {( props?.FilterForm?.end_date !== undefined && !props?.pageTab) && (
                <div className="time-picker-input me-2" style={{float:"left"}}>
                    <ReactDatePicker
                        startDate={props?.FilterForm?.start_date}
                        endDate={props?.FilterForm?.end_date}
                        minDate={props?.FilterForm?.start_date || null}
                        selected={props?.FilterForm?.end_date}
                        onChange={(e)=>onChange(e, "end_date")}
                        icon={
                            <span className="icon-span">
                                <img src={date_time} alt="time" />
                            </span>
                        }
                        showIcon
                        placeholderText="End Date"
                        isClearable={props?.FilterForm?.end_date ? true: false}
                        maxDate={new Date()}
                        dateFormat="dd-MM-yyyy"
                        timeIntervals={1}
                        showTimeSelect
                        timeFormat={timeZone ==="Asia/Calcutta" ? "hh:mm aa" : "HH:mm"}
                    />
                </div>
            )}
            {
                selfInfo?.permission==="mosaic-management,donation-management" ?  null:

            (props?.FilterForm?.campaign !== undefined|| filterType==="Search Order (Email,coupons)" || filterType ==="Package Name" || filterType ==="Coupon Name") && !props?.pageTab  &&(
                <select
                    type="text" 
                    value={props?.FilterForm?.campaign} 
                    onChange={onChangeText} 
                    placeholder="Select campaign" 
                    name='campaign'
                    className="form-control userLimitselect select-custom pe-4 me-2" 
                    style={{
                        width:isDonationsManagement  ? "90px" :"160px"

                    }}
                >
                    <option value={""} >All</option>
                    {campaignOptionList?.map((item, index)=>(
                        <React.Fragment key={index}>
                            <option value={item?.value} >{item?.label}</option>
                        </React.Fragment>
                    ))}
                </select>
            )
            }
            {
                selfInfo?.permission==="mosaic-management,donation-management" ?  null:
            !props?.pageTab && (
              <React.Fragment>
                {(filterType === "Donation(Name,email,amount)")  &&  (
                    <select className="form-control activestatusselet me-2" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
                        <option value="all">All</option>
                        <option value="redeem">Redeem</option>
                        <option value="card">Card</option>
                    </select>
                )}
              </React.Fragment>
            )
            }
            {!props?.pageTab && (
            
                (filterType === "Contact(Subject )"|| filterType==="(Mosaic Name & Compaign)") ? null : 
                (filterType !== "Donations Fundraise (Name)")  &&  (<select className="form-control activestatusselet me-2" value={userType} onChange={(e) => SelectUserTypeFnct(e)}>
                <option value="all">All</option>
                {filterType === "Donation(Name,email,amount)" ? (<>
                    <option value="anonymous">Anonymous</option>
                    <option value="user">User</option>
                </>) : (<>
                {(filterType === "Payment List" || filterType === "Search Order (Email,coupons)")  ?null:(<>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </>)
                }
                </>)}
                {
                    (filterType === "Payment List")  ? (<>
                        <option value="paid">Paid</option>
                    <option value="unpaid">Unpaid</option>
                    </>):null
                }
                {filterType === "Search Order (Email,coupons)"  ? (
                    <>
                        <option value="paid">Paid</option>
                        <option value="free">Free</option>
                    </>
                ):null}
            </select>)
            )}

            {!props?.pageTab &&  !isOthersSelected  &&(

            <select className="form-control userLimitselect" 
            // value={userLimit} 
            // onChange={(e) => setUserLimit(e.target.value)}
            value={userLimit}
          onChange={handleSelectChange}
            >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="60">60</option>
                <option value="70">70</option>
                <option value="80">80</option>
                <option value="90">90</option>
                <option value="100">100</option> 
                <option value="others">Other</option> 
                
            </select>
            )}
            {!props?.pageTab && isOthersSelected && (
        <div className="penigantatain_wrapper">
          <input
            type="text"
            className="peginataion_input"
            value={userLimit}
            onChange={handleInputChange}
            placeholder="Limit"
          />
          <button onClick={handleCancelClick} className="peginatation_close_btn">
            X
          </button>
        </div>
      )}
            {props?.onExport && (<button className="btn btn-primary cmnfilterHeadbtn" 
            // disabled={loading} onClick={() => props?.onExport()}
            onClick={()=>props.handleExport()}
            style={{
                cursor:"pointer"
            }}
            >Export</button>)} 

            {props?.onAdd && (<button className="btn btn-primary cmnfilterHeadbtn" onClick={() => props?.onAdd()}><Image src={AddIcon} alt="add icon" /> {props?.add_text}</button>)} 

            {filterType === "Category (Name)" && (<button className="btn btn-primary cmnfilterHeadbtn" onClick={() => AddCategory()}><Image src={AddIcon} alt="add icon" /> Add Category</button>)} 
            {filterType === "Charity (Name)" && (<button className="btn btn-primary cmnfilterHeadbtn" onClick={() => AddCharity()}><Image src={AddIcon} alt="add icon" /> Add Charity</button>)}
            {filterType === "Category Fundraiser" && (<button className="btn btn-primary cmnfilterHeadbtn" onClick={() => AddFundraise()}><Image src={AddIcon} alt="add icon" /> Add Fundraise</button>)}
            {filterType === "Blog Category (Name)" && (<button className="btn btn-primary cmnfilterHeadbtn" onClick={() => AddBlogCategory()}><Image src={AddIcon} alt="add icon" /> Add Blog Fundraise</button>)}
            {filterType === "blog (Name)" && (<button className="btn btn-primary cmnfilterHeadbtn" onClick={() => AddBlog()}><Image src={AddIcon} alt="add icon" /> Add Blog</button>)}
            {filterType === "athlete (Name)" && (<button className="btn btn-primary cmnfilterHeadbtn" onClick={() => AddAthleteTbs()}><Image src={AddIcon} alt="add icon" /> Add Athlete</button>)}
            {filterType === "Campaign (Name)" && (<button className="btn btn-primary cmnfilterHeadbtn" onClick={() => AddCampaign()}><Image src={AddIcon} alt="add icon" /> Add Campaign</button>)}
            {filterType === "Package Name" && (<button className="btn btn-primary cmnfilterHeadbtn" onClick={() => AddPackage()}><Image src={AddIcon} alt="add icon" /> Add Package</button>)}
            {filterType === "Coupon Name" && (<button className="btn btn-primary cmnfilterHeadbtn" onClick={() => AddPackage()}><Image src={AddIcon} alt="add icon" /> Add Free Coupon</button>)}

            {filterType === "Donations Fundraise (Name)" && (<div className="tabheadebuttnsnew">
                <Link className="btn savchanges linksmanage" to={FUNDRSISERS_MANAGEMENT_URL} >Back</Link>
            </div>)}
        {props?.showDesign && (
                <div className="Multiple_Export_wrapper"
                ref={wrapperRef}
                >
                    <button
                    disabled={loading} onClick={() => props?.onExport("avatar")}
                    >
                    {/* Export Avatar */}
                    {loading && props?.exportType === "avatar" ? 'Loading...' : 'Export Avatars'}

                    </button>
                    <button
                    disabled={loading} onClick={() => props?.onExport("email")}
                    >
                    {/* Export Emails */}
                    {loading && props?.exportType === "email" ? 'Loading...' : 'Export Emails'}

                    </button>
                </div>
                )} 
        </div>
    </div>);
}
FiltersForm.propTypes = {
    setFilterForm: PropTypes.func,
    onExport: PropTypes.func,
    onAdd: PropTypes.func,
    FilterForm: PropTypes.any,
    add_text: PropTypes.any,
}
FiltersForm.defaultProps = {
    setFilterForm: ()=>{},
    FilterForm: {},
    add_text: "Add",
}

export default FiltersForm;