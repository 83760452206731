/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Form, Image, Button, Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";
import { PACKAGE_MANAGEMENT } from "../Shared/constant";
import {
  AddPackageList,
  SelectPackagesTab,
  UpdatePackageX,
} from "../../redux/actions/packageAction";
import AddPackage from "../../Api/AddPackage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CommonLoader from "../Common/CommonLoader";
import EditPackage from "../../Api/Editpackage";
import { getCampaignList } from "../../redux/actions/campaignAction";
import GetCampaign from "../../Api/GetCampaign";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import Scrollbars from "react-custom-scrollbars-2";

const ViewPackage = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [name, setName] = useState("");
  const [qty, setQty] = useState("");
  const [price, setPrice] = useState("");
  const [code, setCode] = useState("");
  const [minimumAmount, setMinimumAmount] = useState();
  const [LoaderStart, setLoaderStart] = useState(false);

  const { roomId } = useParams();
  const [packageData, setPackageData] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const { campaignList } = useSelector((state) => state.campaignReducers);
  const { accessToken } = useSelector((state) => state.personalReducers);
  const { packageList, CurrenUrlBaseForPackage } = useSelector(
    (state) => state.packageReducers
  );
  const [isAuto, setIsAuto] = useState(true)
  // setIsAuto(!isAuto)

  // const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { accessToken } = useSelector((state) => state.personalReducers);
  // const { campaignList } = useSelector((state) => state.campaignReducers);
  const [userSearch, setUserSearch] = useState("");
  const [userType, setUserType] = useState("all");
  const [userLimit, setUserLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [compaignData, setCompaignData] = useState([]);
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  const [paginationLength, setPaginationLength] = useState({});

  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
    fetchData(page, userLimit, userType);
  };

  // clear search box
  const ClearSearchFiled = () => {
    setUserSearch("");
  };

  // select user type
  const SelectUserTypeFnct = (e) => {
    setUserType(e.target.value);
    fetchData(1, userLimit, e.target.value);
    setCurrentPage(1);
  };
  const [abortController, setAbortController] = useState({});
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData(1, userLimit, userType);
    return () => {
      if (abortController.abort) {
        abortController.abort();
        setAbortController(new AbortController());
      }
    };
  }, [userSearch]);

  // Get data from api
  const fetchData = async (currentPage, userLimit, userType) => {
    const signal = abortController.signal;
    setCurrentPage(currentPage);
    const responce = await GetCampaign(
      accessToken,
      userSearch,
      "active",
      userLimit,
      currentPage,
      { signal }
    );
    // console.log("responce", responce);

    if (responce?.status === 200) {
      setData(responce.data);
      // dispatch(getCampaignList(responce.data));
      setPaginationLength(responce.pagination);
    } else if (responce?.status === 403) {
      CommonLogout();
      // dispatch(getCampaignList([]));
    } else {
      // dispatch(getCampaignList([]));
    }
  };

  const [error, setError] = useState({
    name: "",
    qty: "",
    price: "",
    campaign_id: "",
  });
  // const [minamount,setMinamout] = useState()
  //     useEffect(()=>{
  //         var minamount
  //         campaignList.map((element,index)=>{
  //             minamount = JSON.parse(element?.charity.replace("None", 'null').replace("False", 'false').replace("True", 'true')?.replaceAll(/'/g, '"'));
  // setMinamout(minamount?.MinimumAmount)
  //             return(
  //                 <>

  //                 </>
  //             )
  //         })

  //     },[])
  // console.log("minamount",minamount)
  //     const [selectedItem, setSelectedItem] = useState(null);
  //   const [selectedData, setSelectedData] = useState(null);

  const handleSelectChange = (event) => {
    const selectedIndex = event.target.selectedIndex;
    const selectedPackageData = data[selectedIndex - 1];
    if (selectedPackageData) {
      setSelectedCampaign(selectedPackageData);
      let CureentCharityValue = JSON.parse(
        selectedPackageData?.charity
          .replace("None", "null")
          .replace("False", "false")
          .replace("True", "true")
          ?.replaceAll(/'/g, '"')
      );
      if (
        CureentCharityValue?.MinimumAmount === undefined ||
        CureentCharityValue?.MinimumAmount === null
      ) {
        setPrice("");
      } else {
        setPrice(CureentCharityValue?.MinimumAmount);
      }
    }
  };
  // console.log("price",price)
  // console.log("selectedPackageData",selectedCampaign)
  // console.log("data",data)

  const validation = () => {
    let val = true;
    if (name == "") {
      error.name = "Enter Name of Package";
      val = false;
    }
    if (qty == "") {
      error.qty = "Enter Quantity ";
      val = false;
    }
    if (price == "") {
      error.price = "Enter Price";
      val = false;
    }
    if (selectedCampaign?.id === undefined) {
      error.campaign_id = "Please select campaign";
      val = false;
    }
    if (!isAuto && code === "") {
      error.code = "Enter coupon code ";
      val = false;
    }
    setError((data) => ({
      ...data,
      ...error,
    }));

    return val;
  };
  const validationForUpdate = () =>{
    let val = true;
    if (name == "") {
      error.name = "Enter Name of Package";
      val = false;
    }
    if (qty == "") {
      error.qty = "Enter Quantity ";
      val = false;
    }
    // if (!isAuto && code === "") {
    //   error.code = "Enter coupon code ";
    //   val = false;
    // }
    setError((data) => ({
        ...data,
        ...error,
      }));
  
      return val;
  }
  // console.log("campaignList",campaignList)
  useEffect(() => {
    const selectedPackage = packageList.find(
      (packageItem) => packageItem?.id === roomId
    );
    if (selectedPackage) {
      setPackageData(selectedPackage);
      //   setName(packageData?.name)
      //   setQty(packageData?.quantity)
      setName(selectedPackage.name || ""); // Set name from selected package
      setQty(selectedPackage.quantity || "");
      setCode(selectedPackage.code || "");
      if(selectedPackage.code){
        setIsAuto(false)
      }
    }
  }, [roomId, packageList]);

  const AddPackageFct = async () => {
    if (validation()) {
      const jsonData = {
        name: name,
        quantity: qty,
        campaign_id: selectedCampaign?.id,
        price: minimumAmount ? null : price,
        code: null
      };
      if(!isAuto){
        jsonData.code = code;
      }
      const jsonDatas = JSON.stringify(jsonData);
      const response = await AddPackage(accessToken, jsonDatas);
      // console.log("response",response)
      if (response?.status === 200) {
        setTimeout(() => {
          dispatch(AddPackageList(response?.data));
          Navigate(PACKAGE_MANAGEMENT, {state:"paid_coupon"});
          toast.success(response.msg);
        }, 100);
      } else {
        toast.error(response?.error || response.data.error);
      }
    }
  };
  useEffect(() => {
    if (CurrenUrlBaseForPackage === "edit-package") {
      // fetchData();
    }
  }, [accessToken, CurrenUrlBaseForPackage]);


  const UpdatePackage = async () => {
    // console.log("hi this is update1")
    if (validationForUpdate()) {
        // console.log("hi this is update2")
      const jsonData = {
        name: name,
        quantity: qty,
        is_active: packageData?.is_active === true ? 1 : 0,
        package_id: packageData?.id,
      };
      const jsonDatas = JSON.stringify(jsonData);
      const response = await EditPackage(accessToken, jsonDatas);
      if (response?.status === 200) {
        setTimeout(() => {
          dispatch(UpdatePackageX(response?.data));
          Navigate(PACKAGE_MANAGEMENT, {state:"paid_coupon"});
          toast.success(response.msg);
        }, 100);
      } else {
        toast.error(response?.error || response.data.error);
      }
    }
  };
  const CharityActivStatus = ( ) =>{
    setIsAuto(!isAuto)
  }
  const onChangeHandleCode = (e)=>{
    const value = e.target.value;
    const filteredValue = value.replace(/[^a-zA-Z0-9]/g, ''); // Remove non-alphanumeric characters
    if(filteredValue?.length>25 ){
      setCode(code);
    }else{
      setCode(filteredValue.toUpperCase());
    }
  }
  return (
    <>
      <ul className="tabcomnsection">
        {CurrenUrlBaseForPackage === "add-package" ? (
          <li
            className={
              CurrenUrlBaseForPackage === "add-package" ? "active" : ""
            }
            onClick={() => dispatch(SelectPackagesTab("add-package"))}
          >
            Add
          </li>
        ) : (
          <>
            <li
              className={
                CurrenUrlBaseForPackage === "edit-package" ? "active" : ""
              }
              onClick={() => dispatch(SelectPackagesTab("edit-package"))}
            >
              Edit
            </li>
          </>
        )}
        <div className="tabheadebuttns">
          {CurrenUrlBaseForPackage === "edit-package" && (
            <Button className="savchanges" onClick={() => UpdatePackage()}>
              Save
            </Button>
          )}
          {CurrenUrlBaseForPackage === "add-package" && (
            <Button className="savchanges" onClick={() => AddPackageFct()}>
              Add
            </Button>
          )}
          <Link className="btn savchanges linksmanage" to={PACKAGE_MANAGEMENT}  state={"paid_coupon"}>
            Back
          </Link>
        </div>
      </ul>

      <Scrollbars
            style={{ height: "calc(100vh - 162px)" }} 
            className="ScrollbarsWrapper"
            renderView={props => <div {...props} className="view"/>}
        >
      <section className="formsectionset px-3">

        {CurrenUrlBaseForPackage === "add-package" && (
          <>
            <div className="formloginset formeditset">
              <div className="row">
                <div className="row">
                  <div className="col-md-4">
                    <div className="formgroups">
                      <h4
                        className="label-required"
                        style={{
                          fontSize: "18px",
                        }}
                      >
                        Name of Packages
                      </h4>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Enter Title"
                        maxLength={100}
                        // onChange={(e) => setName(e.target.value)}
                        onChange={(e) => {
                             const inputValue = e.target.value;
                             const newValue = inputValue.replace(/[^A-Za-z\s]/g, ''); // Replace non-letter characters with empty string
                             setName(newValue);
                            if (newValue.trim() === '') {
                           setError(prevError => ({
                           ...prevError,
                           name: 'Enter Name of Package'
                          }));
                        } else {
                            setError(prevError => ({
                               ...prevError,
                               name: ''
                           }));
                           }

                       }}

                      />
                      {error?.name && (
                        <div className="danger-colorset">{error?.name}</div>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="formgroups">
                      <h4
                        className="label-required"
                        style={{
                          fontSize: "18px",
                        }}
                      >
                        {" "}
                        Quantity of Package
                      </h4>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="number"
                        placeholder="Enter Quantity"
                        value={qty}
                        min={0}
                        onKeyDown={(e) => {
                        if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace')) {
                               e.preventDefault();
                            }
                         }} 
                          // onChange={(e) => setQty(e.target.value)}
                        onChange={(e) => {
                            let inputValue = e.target.value;
                            inputValue = inputValue.replace(/\D/g, '').substring(0, 10);
                            if (inputValue !== '' && parseInt(inputValue) > 0) {
                                setQty(inputValue);
                              } else {
                                setQty(''); 
                                }
                         if (inputValue.trim() === '') {
                           setError(prevError => ({
                           ...prevError,
                           qty: 'Enter Quantity of Package'
                          }));
                        } else {
                            setError(prevError => ({
                               ...prevError,
                               qty: ''
                           }));
                           }

                          }}  

                      />
                      {error?.qty && (
                        <div className="danger-colorset">{error?.qty}</div>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="formgroups">
                      <h4
                        className="label-required"
                        style={{
                          fontSize: "18px",
                        }}
                      >
                        Select Compaign for this package
                      </h4>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <Form.Group className="mb-3">
                      <Form.Select
                        // type="text"
                        // value={selectedCampaign}
                        onChange={handleSelectChange}
                        placeholder="Select campaign id"
                        // name='campaign_id'
                        // disabled={CurrenUrlBaseForPackage === "edit-package"}
                      >
                        <option value={""}>Select Campaign</option>
                        {data?.map((ele, index) => {
                          {
                            /* console.log("ele",ele) */
                          }

                          return (
                            <React.Fragment key={ele?.id}>
                              <option value={ele}>{ele?.title}</option>
                            </React.Fragment>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                    {error?.campaign_id && (
                      <div className="danger-colorset">
                        {error?.campaign_id}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="formgroups">
                      <h4
                        className="label-required"
                        style={{
                          fontSize: "18px",
                        }}
                      >
                        Price of Package
                      </h4>
                    </div>
                    <small>
                      The final price will be{" "}
                      <b>(Price * Quantity of Package)</b>
                    </small>
                  </div>
                  <div className="col-md-8">
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="number"
                        placeholder="Enter Price"
                        value={price}
                        // disabled={price}
                        // onKeyDown={(e) => EmptySpaceFieldValid(e)}
                        min={0}
                        maxLength={6}
                        onKeyDown={(e) => {
                        if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace')) {
                            e.preventDefault();
                            }
                        }}                       
                            onChange={(e) => {
                            let inputValue = e.target.value;
                            inputValue = inputValue.replace(/\D/g, '').substring(0, 6);
                            if (inputValue !== '' && parseInt(inputValue) > 0) {
                               setPrice(inputValue);
                              } else {
                                  setPrice(''); 
                                }
                                if (inputValue.trim() === '') {
                           setError(prevError => ({
                           ...prevError,
                           price: 'Enter price of Package'
                          }));
                        } else {
                            setError(prevError => ({
                               ...prevError,
                               price: ''
                           }));
                           }
                                
                          }}  

                      />

                      {error.price && (
                        <div className="danger-colorset">{error?.price}</div>
                      )}
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {CurrenUrlBaseForPackage === "edit-package" ? (
          <>
            <div className="formloginset formeditset">
              <div className="row">
                <div className="col-md-4">
                  <div className="formgroups">
                    <h4
                      className="label-required"
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      Name of Packages
                    </h4>
                  </div>
                </div>
                <div className="col-md-8">
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      value={name}
                      placeholder="Enter Title"
                    //   onChange={(e) => setName(e.target.value)}
                    onChange={(e) => {
                             const inputValue = e.target.value;
                             const newValue = inputValue.replace(/[^A-Za-z\s]/g, ''); // Replace non-letter characters with empty string
                             setName(newValue);
                             if (newValue.trim() === '') {
                           setError(prevError => ({
                           ...prevError,
                           name: 'Enter Name of Package'
                          }));
                        } else {
                            setError(prevError => ({
                               ...prevError,
                               name: ''
                           }));
                           }
                       }}
                    />
                    {error?.name && (
                      <div className="danger-colorset">{error?.name}</div>
                    )}
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="formgroups">
                    <h4
                      className="label-required"
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      {" "}
                      Quantity of Package
                    </h4>
                  </div>
                </div>
                <div className="col-md-8">
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      value={qty}
                      placeholder="Enter Quantity"
                    //   onChange={(e) => setQty(e.target.value)}
                    onKeyDown={(e) => {
                        if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace')) {
                               e.preventDefault();
                            }
                         }} 
                          // onChange={(e) => setQty(e.target.value)}
                        onChange={(e) => {
                            let inputValue = e.target.value;
                            inputValue = inputValue.replace(/\D/g, '').substring(0, 10);
                            if (inputValue !== '' && parseInt(inputValue) > 0) {
                                setQty(inputValue);
                              } else {
                                setQty(''); 
                                }
                                if (inputValue.trim() === '') {
                           setError(prevError => ({
                           ...prevError,
                           qty: 'Enter Quantity of Package'
                          }));
                        } else {
                            setError(prevError => ({
                               ...prevError,
                               qty: ''
                           }));
                           }
                          }}  

                    />
                    {error?.qty && (
                      <div className="danger-colorset">{error?.qty}</div>
                    )}
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="formgroups">
                    <h4
                      className="label-required"
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      Select Compaign for this package
                    </h4>
                  </div>
                </div>

                <div className="col-md-8">
                  <Form.Group className="mb-3">
                    <Form.Control
                      value={packageData?.cart?.title}
                      placeholder="Select campaign_id"
                      disabled={CurrenUrlBaseForPackage === "edit-package"}
                    ></Form.Control>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="formgroups">
                    <h4
                      className="label-required"
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      Price of Package
                    </h4>
                  </div>
                  <small>
                    The final price will be <b>(Price * Quantity of Package)</b>
                  </small>
                </div>
                <div className="col-md-8">
                  <Form.Group className="mb-3">
                    <Form.Control
                      placeholder="Enter Price"
                      className={`${packageData?.amount ? "" : ""}`}
                      value={packageData?.amount}
                      disabled={CurrenUrlBaseForPackage === "edit-package"}
                    />
                  </Form.Group>
                </div>
              </div>

            </div>
          </>
        ) : null}
        <div className="formloginset formeditset">
          {!roomId && <div className="row">
            <div className="col-md-4">
              <div className="formgroups">
                <h4
                  className="label-required"
                  style={{
                    fontSize: "18px",
                  }}
                >
                  Auto Generate Coupon
                </h4>
              </div>
            </div>
            <div className="col-md-8">
              <Form className="switchbtn">
                  <Form.Check 
                      type="switch"
                      id="custom-switch"
                      checked={isAuto}
                      onChange={CharityActivStatus}
                  />
                  <Badge bg={isAuto ? "primary" : "danger"}>{isAuto ? "Yes" : "No"}</Badge>
              </Form>
            </div>
          </div>}
          {!isAuto && <div className="row pt-3">
            <div className="col-md-4">
              <div className="formgroups">
                <h4
                  className="label-required"
                  style={{
                    fontSize: "18px",
                  }}
                >
                  Coupon Code
                </h4>
              </div>
            </div>
            <div className="col-md-8">
              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="Enter Coupon Code"
                  // className={`${packageData?.amount ? "" : ""}`}
                  value={code}
                  // disabled={CurrenUrlBaseForPackage === "edit-package"}
                  onChange={onChangeHandleCode}
                  disabled={roomId}
                />
                 {error.code && (
                <div className="danger-colorset">{error?.code}</div>
              )}
              </Form.Group>
            </div>
          </div>}
        </div>
        {LoaderStart && <CommonLoader classNameS={"charityLoader"} />}
      </section>
      </Scrollbars>
    </>
  );
};
export default ViewPackage;
