export const ActionTypes = {
    GET_ORDER_LIST: "GET_ORDER_LIST",
    ORDER_CURRENT_TAB: "ORDER_CURRENT_TAB",
};
export const SelectOrderTab = (urls) => {
    return {
       type: ActionTypes.ORDER_CURRENT_TAB,
       payload: urls,
    }
};

export const getOrderList = (list) => {
    return {
       type: ActionTypes.GET_ORDER_LIST,
       payload: list,
    }
};