/* eslint-disable */
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { PAYMENT_LIST,ORDER_LIST,PACKAGE_MANAGEMENT,DASHBOARD_URL, LOGIN_URL, CHARITY_MANAGEMENT_URL, ATHLETE_MANAGEMENT_URL, USER_MANAGEMENT_URL, FUNDRSISERS_MANAGEMENT_URL, CATEGORY_MANAGEMENT_URL, DONATIONS_MANAGEMENT_URL, FUND_REQUEST_MANAGEMENT_URL, ADMIN_BLOG_MANAGEMENT_URL, BLOG_CATEGORY_MANAGEMENT_URL, BANK_SECTION_URL, CONTACT_MANAGEMENT_URL, FUNDRAISE_APPLICATION_MANAGEMENT_URL, CAMPAIGN_MANAGEMENT_URL, MAIL_CAMPAIGN_MANAGEMENT, TEMPLATE_SUCCESS_POPUP, MOSAIC_MANAGEMENT, SETTING_MODULE, BLOG_MANAGEMENT, FREE_COUPON } from './components/Shared/constant';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css";  
import Login from './components/Login';
import DashBoard from './components/DashBoard';
import CharityManagement from './components/CharityManagement';
import EditCharity from './components/CharityManagement/EditCharity';
import UserManagement from "./components/UserManagement";
import FundManagement from "./components/FundManagement";
import BlogManagement from "./components/BlogManagement";
import BlogCategoryManagement from './components/BlogCategoryManagement';
import UserEdit from './components/UserManagement/UserEdit';
import EditBlog from './components/BlogManagement/EditBlog';
import BankSection from "./components/BankSection";
import CategoryManagement from './components/CategoryManagement';
import DonationsManagement from './components/DonationsManagement';
import FundraisersManagement from './components/FundraisersManagement';
import FundraisersEdit from './components/FundraisersEdit';
import ContactManagement from './components/ContactManagement';
import FundraiseApplicationManagement from './components/FundraiseApplicationManagement';
import AthleteManagement from './components/AthleteManagement';
import EditAthlete from './components/AthleteManagement/EditAthlete';
import CampaignManagement from './components/CampaignManagement';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GetCategory from './Api/GetCategory';
import GetUsersList from './Api/GetUsersList';
import { useEffect } from 'react';
import { clearRedux, checkLogin } from './redux/actions/personalActions';
import { getCategoryListIdWise, getAllCategoryList } from './redux/actions/categoryActions';
import { checkMosaicLogin } from './redux/actions/personalActions';
import { getUserListIDWise } from './redux/actions/usersActions';
import { useDispatch } from 'react-redux';
import AuthLayout from './components/Shared/AuthLayout';
import ViewCampaign from './components/CampaignManagement/ViewCampaign';
import AddEmailTemplate from './components/EmailManagement/AddEmailTemplate';
import SuccessPopupDynamic from './components/EmailManagement/SuccessPopupDynamic';
import EmailManagement from './components/EmailManagement';
import MosaicManagement from './components/MosaicManagement';
import AddMosaic from './components/MosaicManagement/AddMosaic';
import PackagesManagement from './components/Packagesmanagement';
import ViewPackage from './components/Packagesmanagement/ViewPackage';
import Ordermanagement from './components/OrderList';
import ViewOrder from './components/OrderList/ViewOrder';
import PaymentListManagement from './components/PaymentList';
import SettingModule from './components/SettingManagement/SettingModule';
import AddSettingModule from './components/SettingManagement/AddSettingModule';
import SettingTab from './components/SettingManagement/SettingTab';
import AddBlog from './components/SettingManagement/AddBlog';
import ViewFreeCoupon from './components/Packagesmanagement/ViewFreeCoupon';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

function App() {
  const { accessToken, is_login,is_mosaic,selfInfo} = useSelector((state) => state.personalReducers);
  const dispatch = useDispatch();
  useEffect(() => {
    if(accessToken) {
      fetchData();
    }
  }, [ accessToken ]);
// console.log("is_login",is_login)

  const fetchData = async () => {
    // Get category list
    const categoryList = await GetCategory(accessToken, 0, "all", "5000", '1');
    if(categoryList?.status === 200) {
        dispatch(getCategoryListIdWise(categoryList.data));
        dispatch(getAllCategoryList(categoryList.data));
    } else if(categoryList?.detail === "Token expired.") {
        CommonLogout();
    } else if(categoryList?.detail === "User is inactive.") {
        CommonLogout();
    } else {
        toast.error(categoryList?.data?.error);
    };

    // Get users list
    const getUsersList = await GetUsersList(accessToken, 0, "all", "5000", '1');
    if(getUsersList?.status === 200) {
        dispatch(getUserListIDWise(getUsersList.data));
    } else if(getUsersList?.detail === "Token expired.") {
        CommonLogout();
    } else if(getUsersList?.detail === "User is inactive.") {
        CommonLogout();
    } else {
        toast.error(getUsersList?.data?.error);
    };
  };

  // common logout
  const CommonLogout = () => {
      dispatch(clearRedux({}));
      dispatch(checkLogin('no'));
      localStorage.clear();
  };

  return (
    <div className="wrapper">
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Navigate replace to={(accessToken === "" && is_login === "no") ? LOGIN_URL : is_mosaic==="yes" ? MOSAIC_MANAGEMENT:DASHBOARD_URL}/>} />
            <Route path="/" element={(accessToken === "" && is_login === "no") ? <Login /> : <Navigate  replace to={is_mosaic==="yes" ? MOSAIC_MANAGEMENT: DASHBOARD_URL}/>} >
                <Route path={LOGIN_URL} element={(accessToken === "" && is_login === "no") ? <Login /> : <Navigate  replace to={is_mosaic==="yes" ? MOSAIC_MANAGEMENT: DASHBOARD_URL}/>} />
            </Route>
            <Route path="/" element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><DashBoard /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} >
              <Route path={DASHBOARD_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><DashBoard /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            </Route>
            <Route path={CHARITY_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><CharityManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={'/view/charity/:roomId'} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><EditCharity /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={'/add/charity'} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><EditCharity /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={'/edit/charity/:roomId'} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><EditCharity /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={ATHLETE_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><AthleteManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={'/view/athlete/:roomId'} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><EditAthlete /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={'/add/athlete'} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><EditAthlete /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={'/edit/athlete/:roomId'} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><EditAthlete /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={USER_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><UserManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
          
            <Route path={PACKAGE_MANAGEMENT} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><PackagesManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={"/admin/package-order"} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><Ordermanagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={PAYMENT_LIST} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><PaymentListManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />

          
            <Route path={'/edit/:editName/:roomId'} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><UserEdit /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={FUNDRSISERS_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><FundraisersManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={'/edit/fundraiser/:roomId'} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><FundraisersEdit /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={'/add/fundraiser'} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><FundraisersEdit /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={'/view/fundraiser/:roomId'} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><FundraisersEdit /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={FUNDRAISE_APPLICATION_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><FundraiseApplicationManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={CATEGORY_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><CategoryManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            {/* <Route path={DONATIONS_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><DonationsManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} /> */}
            <Route path={FUND_REQUEST_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><FundManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={ADMIN_BLOG_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><BlogManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={'/add/blog'} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><EditBlog /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={'/edit/blog/:roomId'} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><EditBlog /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={BLOG_CATEGORY_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><BlogCategoryManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={BANK_SECTION_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><BankSection /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={CONTACT_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><ContactManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={`${MAIL_CAMPAIGN_MANAGEMENT}`} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><EmailManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={`/add${MAIL_CAMPAIGN_MANAGEMENT}/:type`} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><AddEmailTemplate /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={`/edit${MAIL_CAMPAIGN_MANAGEMENT}/:campaign_mail_id`} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><AddEmailTemplate /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={`/add${TEMPLATE_SUCCESS_POPUP}`} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><SuccessPopupDynamic /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={CAMPAIGN_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><CampaignManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={'/view/campaign/:roomId'} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><ViewCampaign /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={'/edit/campaign/:roomId'} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><ViewCampaign /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={'/add/campaign'} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><ViewCampaign /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
         
            <Route path={'/add/package'} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><ViewPackage /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={'/package/update/:roomId'} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><ViewPackage /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={'/order/list/:roomId'} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><ViewOrder /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />

            <Route path={`add${BLOG_MANAGEMENT}`} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><AddBlog /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={`edit${BLOG_MANAGEMENT}/:blog_id`} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><AddBlog /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />

            <Route path={SETTING_MODULE} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><SettingTab/></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={`/add${SETTING_MODULE}`} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><AddSettingModule /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={`/edit${SETTING_MODULE}/:id`} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><AddSettingModule /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            {/* <Route path={`/add${FREE_COUPON}`} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><AddBlog /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} /> */}
            <Route path={`/add${FREE_COUPON}`} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><ViewFreeCoupon /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={`/update${FREE_COUPON}/:coupon_id`} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><ViewFreeCoupon /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            
{
  is_mosaic ==="yes" ?
          <>
            <Route path={MOSAIC_MANAGEMENT} element={(accessToken !== "") ? <AuthLayout><MosaicManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={`/add${MOSAIC_MANAGEMENT}`} element={(accessToken !== "") ? <AuthLayout><AddMosaic /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
          {
            selfInfo?.permission==="mosaic-management,donation-management" && 
             <Route path={DONATIONS_MANAGEMENT_URL} element={(accessToken !== "") ? <AuthLayout><DonationsManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
          }
          </>:
          <>
            <Route path={DONATIONS_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><DonationsManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={MOSAIC_MANAGEMENT} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><MosaicManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={`/add${MOSAIC_MANAGEMENT}`} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><AddMosaic /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={MOSAIC_MANAGEMENT} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><MosaicManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={`/add${MOSAIC_MANAGEMENT}`} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><AddMosaic /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
          </>
}
            
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
