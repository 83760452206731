/* eslint-disable */
import React from "react";
import UploadImageIcon from "../../assets/img/uploadImage.png";
import ReactQuill from 'react-quill';
import { Scrollbars } from 'react-custom-scrollbars-2';
import 'react-quill/dist/quill.snow.css';
import { Button, Image, Form, Badge } from "react-bootstrap";

const UpdatesEdit = (props) => {
    const { coverImage, story, setStory, UploadFiles, ValueKeyPress, errorAvatar, errorDescription, setDonorMail, donorMail, formLabel } = props;
    const YouTubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/; // eslint-disable-line

    // editor code
    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video"
    ];
    const modules = {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" }
          ],
          ["link"],
          ["clean"]
        ]
    };
    
    return(<div className="maintabcontent">
        <Scrollbars 
            style={{ height: "calc(100vh - 245px)" }} 
            className="ScrollbarsWrapper"
            renderView={props => <div {...props} className="view"/>}
        >
            <div className="formloginset formeditset">
                <div className="row">
                    <div className="col-md-4">
                        <div className="formgroups">
                            <h4>Media</h4>
                            <p>Use a photo or video that represents your story well. A photo (JPG, PNG, GIF, or BMP) should be less than 20MB.</p>
                            <Button className="uploadcoverMediabtn" onClick={() => UploadFiles()}>
                                <Image src={UploadImageIcon} alt="uploadImage" /> 
                                Upload
                            </Button>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="coverUploadImage" onClick={() => UploadFiles()}>
                            {(coverImage !== "") ? (<>
                                {(YouTubeRegex.test(coverImage) === true) ? (<div className="uploadeyoutubevideo">
                                    <iframe src={`https://www.youtube.com/embed/${coverImage?.split("/")[coverImage?.split("/").length - 1].replace("watch?v=", "")}`}></iframe>
                                </div>) :
                                (<Image src={coverImage} className="uplodedimageset" alt="uploadImage" />)}
                            </>) : (<div className="coverUploadImageSet">
                                <Image src={UploadImageIcon} className="uplodimgicon" alt="uploadImage" />  
                                <h4>Upload your photo or youtube link here</h4>
                            </div>)}
                        </div>
                        {(errorAvatar && coverImage === "") && <div className="danger-colorset">{errorAvatar}</div>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="formgroups">
                            <h4>Description</h4>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="editorBox">
                            <ReactQuill 
                                formats={formats}
                                modules={modules}
                                theme="snow" 
                                value={story} 
                                onChange={setStory} 
                                onKeyDown={(e) =>setTimeout(()=> ValueKeyPress(e), 10)}
                            />
                            {(errorDescription && story === "") && <div className="danger-colorset">Description cannot be empty</div>}
                        </div>
                    </div>
                </div>
                {formLabel === "add updates" && (<div className="row">
                    <div className="col-md-4">
                        <div className="formgroups">
                            <h4>Donor’s email</h4>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <Form className="switchbtn newswitchbtn">
                            <Form.Check 
                                type="switch"
                                id="custom-switch"
                                onChange={e => {}}
                                onClick={() => setDonorMail(!donorMail)}
                            />
                            <Badge bg={donorMail ? "primary" : "danger"}>{donorMail ? "Yes" : "No"}</Badge>
                        </Form>
                    </div>
                </div>)}
            </div>
        </Scrollbars>
    </div>)
}

export default UpdatesEdit;