import { GET_FUNDRAISE_LIST_API_URL } from "../components/Shared/constant";

const GetFundraise = async (accessToken, userSearch, userType, userLimit, currentPage, {signal}={}) => {
    const responce = window.axios.get(`${GET_FUNDRAISE_LIST_API_URL}/${userType}/${userLimit}/${currentPage}/${userSearch === "" ? "0" : userSearch}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + accessToken,
    }, signal:signal, }).then(function (result) {
        return {
          ...result.data,
          status:200
        };
    }).catch(function (result) {
        return {
          ...result?.response?.data,
          status:result?.response?.status
        }
    });
    return responce;
};
  
export default GetFundraise;