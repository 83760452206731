/* eslint-disable */
import { ActionTypes } from "../actions/personalActions";

const initailData = {
    is_login: "no",
    sidebaropen: false,
    selfInfo: {},
    accessToken: "",
    is_mosaic:"no",
    parent_compaign_Id:""
};

export const personalReducers = (state = initailData, action) => {
    switch (action.type) {
        case ActionTypes.IS_LOGIN:
            return {
                ...state,
                is_login: action.payload,
            };
        case ActionTypes.MOSAIC_LOGIN:
                return {
                    ...state,
                    is_mosaic: action.payload,
           };    
        case ActionTypes.PARENT_COMPAIGN_ID:
                return {
                    ...state,
                    parent_compaign_Id: action.payload,
           };    
        case ActionTypes.SELF_DETAILS:
            return {
                ...state,
                selfInfo: action.payload,
            };
        case ActionTypes.GET_ACCESSTOKEN:
            return {
                ...state,
                accessToken: action.payload,
            };
        case ActionTypes.SIDEBAR_CLOSE:
            return {
                ...state,
                sidebaropen: action.payload,
            };
        case ActionTypes.ADMIN_LOGOUT:
            return {
                ...initailData,
            };
        default:
        return state;
    }
}