/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Button, Form, Image } from 'react-bootstrap';
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { Scrollbars } from 'react-custom-scrollbars-2';
import AddCharity from "../../Api/AddCharity";
import EditCharityList from "../../Api/EditCharityList";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CountrySelectInput from "../Common/CountrySelectInput/CountryMobileInput";
import { Country }  from 'country-state-city';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { addAthleteFnct, editAthleteListFnct, getAthleteTabs, selectAthleteList } from "../../redux/actions/athleteAction";
import CommonModal from "../Common/CommonModal";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ATHLETE_MANAGEMENT_URL } from "../Shared/constant";
import UploadFile from "../../Api/UploadFile";
import DefaultImage from "../../assets/img/DefaultProduct.jpg";
import UploadImageIcon from "../../assets/img/uploadImage.png";
import LoaderImage from "../../assets/img/spinner_transferent.svg";
import ViewAthlete from "./ViewAthlete";
import CommonLoader from "../Common/CommonLoader";
import GetCharityDetails from "../../Api/GetCharityDetails";
import AthleteFundraise from "./AthleteFundraise";

const EditAthlete = () => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const Location = useLocation()?.pathname.split('/')[3];
    const EditsLocation = useLocation()?.pathname;
    const CurrenUrlBase = "/" + EditsLocation.split('/')[1] + "/" + EditsLocation.split('/')[2];
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { athleteTabs, athleteListIdWise } = useSelector((state) => state.athleteReducers)
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
    const [ AvatarImage, setAvatarImage ] = useState("");
    const [ EditCharitys, setEditCharitys ] = useState({
        name: "",
        email: "",
        ein: "",
        phone: "",
        phone_code: "",
        country: "",
        address: ""
    });
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [ description, setDescription ] = useState("");
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [ LoaderStart, setLoaderStart ] = useState(false);

    // error message
    const [ errorAvatar, setErrorAvatar ] = useState("");
    const [ errorname, setErrorName ] = useState("");
    const [ errorEmail, setErrorEmail ] = useState("");
    const [ errorEIN, setErrorEIN ] = useState("");
    const [ errorPhone, setErrorPhone ] = useState("");
    const [ errorCountry, setErrorCountry ] = useState("");
    const [ errorDescription, setErrorDescription ] = useState(false);
    const [ errorAddress, setErrorAddress ] = useState("");

    // select country 
    const SelectCoutry = (event) => {
        setEditCharitys({...EditCharitys, country: event.target.value});
        CheckValid(event.target.value, {type: 'country', error: setErrorCountry });
    };

    // get charity details
    const fetchData = async () => {
        if(CurrenUrlBase === "/view/athlete" || CurrenUrlBase === "/edit/athlete" ) {
            setLoaderStart(true);
            const charityget = await GetCharityDetails(accessToken, Location);
            if(charityget) {   
                dispatch(selectAthleteList(charityget?.data));
                setAvatarImage((charityget?.data?.avatar === "" || charityget?.data?.avatar === null || charityget?.data?.avatar === undefined) ? "" : charityget?.data?.avatar);
                setEditCharitys({
                    ...EditCharitys,
                    name: (charityget?.data?.name === "" || charityget?.data?.name === null || charityget?.data?.name === undefined) ? "" : charityget?.data?.name,
                    email: (charityget?.data?.email === "" || charityget?.data?.email === null || charityget?.data?.email === undefined) ? "" : charityget?.data?.email,
                    ein: (charityget?.data?.ein === "" || charityget?.data?.ein === null || charityget?.data?.ein === undefined) ? "" : charityget?.data?.ein,
                    phone: (charityget?.data?.phone === "" || charityget?.data?.phone === null || charityget?.data?.phone === undefined) ? "" : charityget?.data?.phone,
                    phone_code: (charityget?.data?.phone_code === "" || charityget?.data?.phone_code === null || charityget?.data?.phone_code === undefined) ? "" : charityget?.data?.phone_code,
                    country: (charityget?.data?.country === "" || charityget?.data?.country === null || charityget?.data?.country === undefined) ? "" : charityget?.data?.country,
                    address: (charityget?.data?.address === "" || charityget?.data?.address === null || charityget?.data?.address === undefined) ? "" : charityget?.data?.address
                });
                setDescription((charityget?.data?.description === "" || charityget?.data?.description === null || charityget?.data?.description === undefined) ? "" : charityget?.data?.description)
                setTimeout(() => {
                    setLoaderStart(false);
                }, 200);
            };
        }
    };

    useEffect(() => {
        if(athleteTabs === "edit-athlete" || athleteTabs === "view-athlete") {
            fetchData();
        };
    }, [ accessToken, athleteTabs ]);

    // last key code check and remove value
    const ValueKeyPress = (e) => {
        const code = e.keycode || e.which;
        if(code === 8 && e.target.innerHTML === "<p><br></p>") {
            setDescription("");
        };
    };

    // editor code
    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link"
    ];
    const modules = {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" }
          ],
          ["link"],
          ["clean"]
        ]
    }; 

    // upload image 
    const UploadImage = async (e) => {
        setUploadFileLoader(true);
        const files = e.target.files[0];
        const formData = new FormData();
        formData.append("file", files, files.name);
        const fileresponse = await UploadFile(accessToken, formData);
        if(fileresponse) {
            setUploadFileLoader(false);
            setAvatarImage(fileresponse?.file_url_400);
            setErrorAvatar("");
        };
    };

    // edit user function
    const EditData = async () => {
        if((AvatarImage !== "" && AvatarImage !== null) && EditCharitys?.name !== "" && EditCharitys?.email !== "" && EditCharitys?.phone !== "" && EditCharitys?.description !== "" && EditCharitys?.country !== "" && EditCharitys?.address !== "") {
            setErrorDescription(false);
            if(EmailRegex.test(EditCharitys?.email) === true && EditCharitys?.phone?.length === 10) {
                if(EditsLocation === "/add/athlete") {
                    const jsonData = JSON.stringify({ 
                        avatar: AvatarImage,
                        name: EditCharitys?.name,
                        email: EditCharitys?.email,
                        ein: EditCharitys?.ein,
                        phone: EditCharitys?.phone,
                        phone_code: EditCharitys?.phone_code,
                        country: EditCharitys?.country,
                        description: description,
                        address: EditCharitys?.address,
                        is_charity: "0"
                    });
                    const response = await AddCharity(accessToken, jsonData);
                    if(response?.status === 200){
                        setTimeout(() => {
                            toast.success(response.msg);
                            dispatch(addAthleteFnct(response?.data));
                            Navigate(ATHLETE_MANAGEMENT_URL);
                        }, 100);
                    } else {
                        toast.error(response?.error || response.data.error);
                    };
                } else {
                    const jsonData = JSON.stringify({ 
                        avatar: AvatarImage,
                        name: EditCharitys?.name,
                        email: EditCharitys?.email,
                        ein: EditCharitys?.ein,
                        phone: EditCharitys?.phone,
                        phone_code: EditCharitys?.phone_code,
                        country: EditCharitys?.country,
                        description: description,
                        address: EditCharitys?.address,
                        slug: Location
                    });
                    const response = await EditCharityList(accessToken, jsonData);
                    if(response?.status === 200){
                        setTimeout(() => {
                            toast.success(response.msg);
                            dispatch(editAthleteListFnct(response?.data));
                            Navigate(ATHLETE_MANAGEMENT_URL);
                        }, 100);
                    } else {
                        toast.error(response?.error || response.data.error);
                    };
                }
            } else {
                CheckValid(EditCharitys?.email, {type: 'email', error: setErrorEmail });
                CheckValid(EditCharitys?.phone, {type: 'phone', error: setErrorPhone });
            }
        } else {
            CheckValid(AvatarImage, {type: 'avatar', error: setErrorAvatar });
            CheckValid(EditCharitys?.name, {type: 'name', error: setErrorName });
            CheckValid(EditCharitys?.email, {type: 'email', error: setErrorEmail });
            CheckValid(EditCharitys?.phone_code, {type: 'phone_code', error: setErrorPhone });
            CheckValid(EditCharitys?.phone, {type: 'phone', error: setErrorPhone });
            CheckValid(EditCharitys?.ein, {type: 'EIN', error: setErrorEIN });
            CheckValid(EditCharitys?.country, {type: 'country', error: setErrorCountry });
            setErrorDescription(true);
            CheckValid(EditCharitys?.address, {type: 'address', error: setErrorAddress })
        };
    };

    return(<>
            <ul className="tabcomnsection">
                {(CurrenUrlBase !== "/view/athlete" && CurrenUrlBase !== "/edit/athlete") && (<li className={athleteTabs === "add-athlete" ? "active" : ""} onClick={() => dispatch(getAthleteTabs("add-athlete"))}>Add</li>)}
                {CurrenUrlBase !== "/add/athlete" && (<li className={athleteTabs === "edit-athlete" ? "active" : ""} onClick={() => dispatch(getAthleteTabs("edit-athlete"))}>Edit</li>)}
                {CurrenUrlBase !== "/add/athlete" && (<li className={athleteTabs === "view-athlete" ? "active" : ""} onClick={() => dispatch(getAthleteTabs("view-athlete"))}>View</li>)}
                {CurrenUrlBase !== "/add/athlete" && (<li className={athleteTabs === "Fundraise-athlete" ? "active" : ""} onClick={() => dispatch(getAthleteTabs("Fundraise-athlete"))}>Athlete Fundraise</li>)}
                {CurrenUrlBase !== "/add/athlete" && (<li className={athleteTabs === "bank-athlete" ? "active" : ""} onClick={() => dispatch(getAthleteTabs("bank-athlete"))}>Bank Details</li>)}
                <div className="tabheadebuttns">
                    <Link className="btn savchanges linksmanage" to={ATHLETE_MANAGEMENT_URL} >Back</Link>
                </div>
            </ul>
            <section className="formsectionset">
                {(athleteTabs === "edit-athlete" || athleteTabs === "add-athlete") ? (<div className="tabcontent">
                    <Scrollbars 
                        style={{ height: "calc(100vh - 162px)" }} 
                        className="ScrollbarsWrapper"
                        renderView={props => <div {...props} className="view"/>}
                    >
                       <div className="tabsWrappers">
                            <div className="formloginset">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Athlete Avatar</Form.Label>
                                            {(AvatarImage !== undefined && AvatarImage !== "") ? (<div className="imageuploads">
                                                <Image src={AvatarImage} alt="charity avatar" />
                                                <span className="closeIcon" onClick={() => setAvatarImage("")}></span>
                                            </div>) :
                                            (<div className="uploadbuttons"
                                            onClick={() => document.getElementById('fileInput11')?.click()}

                                            >
                                                <div className={UploadFileLoader ? "fileloader active" : "fileloader"}>
                                                    {UploadFileLoader ? 
                                                    (<Image src={LoaderImage} className="uploadImgIconse uploadimagload" alt="upload image" />) : 
                                                    (<label>
                                                        <Image src={UploadImageIcon} className="uploadImgIconse" alt="upload image" />
                                                        <input type="file" id="fileInput11" className="uploadfilInput" onChange={(e) => UploadImage(e)} accept=".svg, .png, .jpg, .jpeg, .webp"/>
                                                    </label>)}
                                                </div>
                                                <div className="uploadfilebox">
                                                    <Image src={DefaultImage} alt="upload image" />
                                                </div>
                                            </div>)}
                                            {errorAvatar && <div className="danger-colorset">{errorAvatar}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Athlete Name</Form.Label>
                                            <Form.Control type="text" value={EditCharitys?.name} onKeyUp={(e) => CheckValid(e.target.value, {type: 'name', error: setErrorName })} onChange={(e) => setEditCharitys({...EditCharitys, name: e.target.value})} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter First Name" />
                                            {errorname && <div className="danger-colorset">{errorname}</div>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="text" value={EditCharitys?.email} onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: setErrorEmail })} onChange={(e) => setEditCharitys({...EditCharitys, email: e.target.value})} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter Email address" />
                                            {errorEmail && <div className="danger-colorset">{errorEmail}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>EIN(Employer Identification Number)</Form.Label>
                                            <Form.Control type="text" value={EditCharitys?.ein} onKeyUp={(e) => CheckValid(e.target.value, {type: 'EIN', error: setErrorEIN })} onChange={(e) => setEditCharitys({...EditCharitys, ein: e.target.value})} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter EIN" />
                                            {errorEIN && <div className="danger-colorset">{errorEIN}</div>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Phone number</Form.Label>
                                            <CountrySelectInput phone={EditCharitys} setPhone={setEditCharitys} CheckValid={CheckValid} setErrorPhone={setErrorPhone} />
                                            {errorPhone && <div className="danger-colorset">{errorPhone}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Description</Form.Label>
                                            <div className="editorBox">
                                                <ReactQuill 
                                                    formats={formats}
                                                    modules={modules}
                                                    theme="snow" 
                                                    value={description} 
                                                    onChange={setDescription} 
                                                    onKeyDown={(e) =>setTimeout(()=> ValueKeyPress(e), 10)}
                                                />
                                            </div>
                                            {(errorDescription && description === "") && <div className="danger-colorset">Description cannot be empty</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Country</Form.Label>
                                            <select className="form-control" defaultValue={EditCharitys?.country} onChange={(e) => SelectCoutry(e)} aria-label="Default select example">
                                                <option value="">Select Country</option>
                                                {Country.getAllCountries().map((elm, index) => {
                                                    return(<option value={elm.name}  key={index}>{elm.name}</option>)
                                                })}
                                            </select>
                                            {errorCountry && <div className="danger-colorset">{errorCountry}</div>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control type="text" value={EditCharitys?.address} onKeyUp={(e) => CheckValid(e.target.value, {type: 'address', error: setErrorAddress })} onChange={(e) => setEditCharitys({...EditCharitys, address: e.target.value})} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter Address" />
                                            {errorAddress && <div className="danger-colorset">{errorAddress}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Button variant="primary" onClick={() => EditData()}>
                                            {EditsLocation === "/add/charity/" ? "Add Charity" : "Save Changes"}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Scrollbars>
                </div>) : athleteTabs === "view-athlete" ?
                (<div className="tabcontent">
                    <Scrollbars 
                        style={{ height: "calc(100vh - 162px)" }} 
                        className="ScrollbarsWrapper"
                        renderView={props => <div {...props} className="view"/>}
                    >
                       <ViewAthlete AvatarImage={AvatarImage} EditCharitys={EditCharitys} description={description} />
                    </Scrollbars>
                </div>) : 
                athleteTabs === "Fundraise-athlete" ? 
                (<AthleteFundraise slug={Location} accessToken={accessToken} />) : 
                (<div className="tabcontent">Bank Details</div>)}
        </section>
        {showModal?.open && (<CommonModal showModal={showModal} setShowModal={setShowModal} />)}
        {/* common loader */}
        {LoaderStart && <CommonLoader classNameS={"charityLoader"} />}
    </>);
}

export default EditAthlete;