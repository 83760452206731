/* eslint-disable */
import React, { useState } from "react";
import { Modal, Button, Image, Form } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CheckValid from "./CheckValid";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import UplaodImageIcon from "../../assets/img/uploadImage.png";
import YouTubeIcon from "../../assets/img/youtube.png";
import LoaderIcon from "../../assets/img/spinner_transferent.svg";
import vimeo from "../../assets/img/vimeo.jpg";
import UploadFile from "../../Api/UploadFile";

const UploadFileModal = (props) => {
    const { showFileModal, setShowFileModal, setCoverImage, type, setAthleteYoutubevideo, setCharityYoutubevideo } = props;
    const [ ImageVideoTab, setImageVideoTab ] = useState("");
    const { accessToken } = useSelector((state) => state.personalReducers);
    const [ uploadFileLoader, setUploadFileLoader ] = useState(false);
    const [ YouTubelink, setYouTubelink ] = useState("");
    const YouTubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/; // eslint-disable-line

    const VimeoRegex = /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/)(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:$|\/|\?)/;
    // error state
    const [ errorYouTube, setErrorYouTube ] = useState("");

    // close modal
    const handleClose = () => {
        setShowFileModal({...showFileModal, open: false});
    };

    // upload avatar image fucntion
    const UploadFileFunct = async (e) => {
        setUploadFileLoader(true);
        const files = e.target.files[0];
        const formData = new FormData();
        formData.append("file", files, files.name);
        const fileresponse = await UploadFile(accessToken, formData);
        if(fileresponse) {
            toast.success(fileresponse.msg);
            setCoverImage(fileresponse?.file_url_700);
            setUploadFileLoader(false);
            handleClose();
        } else {
            toast.error(fileresponse?.error || fileresponse.data.error);
        };
    };

    // add youtube field function
    const addYouTubeBtn = () => {
        if(YouTubelink !== "") {
            if(VimeoRegex.test(YouTubelink) === true && ImageVideoTab == "vimeo" ) {
                if(showFileModal?.method === "campagin_youtube_link") {
                    if(props?.setCampingVideo){
                        props?.setCampingVideo(YouTubelink);
                        return handleClose();
                    }
                }
                if(showFileModal?.method === "athlete") {
                    setAthleteYoutubevideo(YouTubelink);
                } else if(showFileModal?.method === "charity") {
                    setCharityYoutubevideo(YouTubelink);
                }else{
                    props?.callBackSelect(YouTubelink)
                };
                return handleClose();
            }
            if(YouTubeRegex.test(YouTubelink) === true) {
                if(type === "onlyVideo") {
                    if(showFileModal?.method === "campagin_youtube_link") {
                        if(props?.setCampingVideo){
                            props?.setCampingVideo(YouTubelink);
                            return handleClose();
                        }
                    }
                    if(showFileModal?.method === "athlete") {
                        setAthleteYoutubevideo(YouTubelink);
                    } else if(showFileModal?.method === "charity") {
                        setCharityYoutubevideo(YouTubelink);
                    }else{
                        props?.callBackSelect(YouTubelink)
                    };
                } else {
                    setCoverImage(YouTubelink);
                };
                handleClose();
            } else {
                if(ImageVideoTab != "vimeo" ){
                    CheckValid(YouTubelink, {type: 'youtubelink', error: setErrorYouTube });
                }else{
                    CheckValid(YouTubelink, {type: 'vimeo_link', error: setErrorYouTube });
                }
            };
        } else {
            if(ImageVideoTab != "vimeo" ){
                CheckValid(YouTubelink, {type: 'youtubelink', error: setErrorYouTube });
            }else{
                CheckValid(YouTubelink, {type: 'vimeo_link', error: setErrorYouTube });
            }
        };
    };

    return(<Modal className="commonModal" show={showFileModal?.open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{showFileModal?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {type === "onlyVideo" ? (
                <React.Fragment>
                    <div className="uploadfielsbuttons">
                        <Button className="btn" onClick={() => setImageVideoTab("youtube")}>
                            <Image className="uploadIcon" src={YouTubeIcon} alt="upload video" />
                            Add YouTube Url
                        </Button>
                        <Button className="btn" onClick={() => setImageVideoTab("vimeo")}>
                            <Image className="uploadIcon" src={vimeo} alt="upload video" />
                            Add Vimeo Url
                        </Button>
                        {ImageVideoTab == "youtube" && (<div className="addvideoform">
                            <Form.Group className="mb-3" >
                                <Form.Control type="text" value={YouTubelink} onKeyUp={(e) => CheckValid(e.target.value, {type: 'youtubelink', error: setErrorYouTube })} onChange={(e) => setYouTubelink(e.target.value)} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter YouTube Link" />
                                {errorYouTube !== "" && (<div className="danger-colorset">{errorYouTube}</div>)}
                                <Button onClick={() => addYouTubeBtn()}>Add YouTube Url</Button>
                            </Form.Group>
                        </div>)}
                        {ImageVideoTab == "vimeo" && (<div className="addvideoform">
                            <Form.Group className="mb-3" >
                                <Form.Control type="text" value={YouTubelink} onKeyUp={(e) => CheckValid(e.target.value, {type: 'vimeo_link', error: setErrorYouTube })} onChange={(e) => setYouTubelink(e.target.value)} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter Vimeo Link" />
                                {errorYouTube !== "" && (<div className="danger-colorset">{errorYouTube}</div>)}
                                <Button onClick={() => addYouTubeBtn()}>Add Vimeo Url</Button>
                            </Form.Group>
                        </div>)}
                    </div>
                </React.Fragment>
            ) : (<div className="uploadfielsbuttons">
                <label className="btn uploadfilebuttons" onClick={() => setImageVideoTab(false)}>
                    <input type="file" accept=".svg, .png, .jpg, .jpeg, .webp" onChange={(e) => UploadFileFunct(e)} />
                    <Image className="uploadIcon" src={UplaodImageIcon} alt="upload image" />
                    Upload Image
                    {uploadFileLoader && (<Image className="uploadLoaderIcon" src={LoaderIcon} alt="loader image" />)}
                </label>
                <Button className="btn" onClick={() => setImageVideoTab(!ImageVideoTab)}>
                    <Image className="uploadIcon" src={YouTubeIcon} alt="upload video" />
                    Add YouTube Url
                </Button>
                {ImageVideoTab && (<div className="addvideoform">
                    <Form.Group className="mb-3" >
                        <Form.Control type="text" value={YouTubelink} onKeyUp={(e) => CheckValid(e.target.value, {type: 'youtubelink', error: setErrorYouTube })} onChange={(e) => setYouTubelink(e.target.value)} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter YouTube Link" />
                        {errorYouTube !== "" && (<div className="danger-colorset">{errorYouTube}</div>)}
                        <Button onClick={() => addYouTubeBtn()}>Add YouTube Url</Button>
                    </Form.Group>
                </div>)}
            </div>)}
        </Modal.Body>
    </Modal>);
}

export default UploadFileModal;