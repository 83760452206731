/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Image, Collapse } from "react-bootstrap";
import EditIcon from "../../assets/img/edit.png";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectCampaignTab,
  setStoreUpdateCampaignSequence,
} from "../../redux/actions/campaignAction";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import DeleteIcon from "../../assets/img/delete.png";
import { PostRequestAPI } from "../../Api/PostRequest";
import { toast } from "react-toastify";
import { EDIT_CAMPAIGN_API_URL } from "../Shared/constant";
import MoreIcon from "../../assets/img/more_options.png";
import CopyIcon from "../../assets/img/copy.png";

const CampaignItem = (props) => {
  const {
    user,
    DeleteCampaign,
    CharityActivStatus,
    ElseMoreDetails,
    ViewMoreDetails,
    activeTableDetails,
    CharityActivStatus2
  } = props;
  // const [DigitValue, setDigitValue] = useState(user?.sequence_no);
  const [Loader, setLoader] = useState(false);
  const { accessToken } = useSelector((state) => state.personalReducers);

  const dispatch = useDispatch();

  const [HoverText, setHoverText] = useState("Copy");
  const [showBorder, setShowBorder] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setHoverText("Copy");
    }, 2000);
  }, [HoverText]);

  const CopyPassword = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        console.log("Copied to clipboard: ", url);
        setHoverText("Copied");
        setShowBorder(true);
        setTimeout(() => {
          setShowBorder(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <>
      <tr>
        <td style={{ width: "10px" }}>
          <button
            className={
              activeTableDetails === user?.id
                ? "btn actionbtn active"
                : "btn actionbtn"
            }
            onClick={() =>
              activeTableDetails === user?.id
                ? ElseMoreDetails()
                : ViewMoreDetails(user?.id)
            }
            aria-controls="example-collapse-text"
            aria-expanded={activeTableDetails === user?.id ? true : false}
          >
            <Image src={MoreIcon} alt="more icon" />
          </button>
        </td>
        <td>{user?.title}</td>
        <td>{user?.subtitle}</td>
        <td>{moment(user.created_at).format("LL HH:mm A")}</td>
        <td>
          {/* <Form className="switchbtn" onSubmit={callUpdateIndex}>
                    <Form.Control 
                        style={{width: 60, boxShadow:"none", fontSize:12}}
                        onChange={onChange}
                        value={DigitValue}
                        size="sm"
                        type="text"
                    />
                    {(DigitValue != user?.sequence_no && DigitValue!==null && DigitValue!=="") && (
                        <button disabled={Loader} className={"btn btn-primary btn-sm ms-2 cmnfilterHeadbtn"} type="submit">
                            Save
                        </button>
                    )}
                </Form> */}
          {user?.sequence_no ? user?.sequence_no : "-"}
        </td>
        <td>
          <Form className="switchbtn">
            <Form.Check
              type="switch"
              id="custom-switch"
              checked={user?.web_appearance}
              onChange={(e) => {}}
              onClick={() => CharityActivStatus2(user)}
            />
            <Badge bg={user?.web_appearance ? "primary" : "danger"}>
              {user?.web_appearance ? "Yes" : "No"}
            </Badge>
          </Form>
        </td>
        <td>
          <Form className="switchbtn">
            <Form.Check
              type="switch"
              id="custom-switch"
              checked={user?.is_active}
              onChange={(e) => {}}
              onClick={() => CharityActivStatus(user)}
            />
            <Badge bg={user?.is_active ? "primary" : "danger"}>
              {user?.is_active ? "Yes" : "No"}
            </Badge>
          </Form>
        </td>
        <td className="space-nowrap text-nowrap">
          <div className="d-flex">
            <Link
              className="btn actionbtn"
              onClick={() => dispatch(SelectCampaignTab("edit-campaign"))}
              to={"/edit/campaign/" + user?.slug}
            >
              <Image src={EditIcon} alt="edit icon" />
            </Link>
            <button
              className="btn actionbtn"
              onClick={() => DeleteCampaign(user)}
            >
              <Image src={DeleteIcon} alt="delete icon" />
            </button>
          </div>
        </td>
      </tr>
      <Collapse in={activeTableDetails === user?.id ? true : false}>
        <tr className="tablecollapsewraps" id={"tablecollpase" + user?.id}>
          <td colSpan="8">
            <table className="table">
              <tbody>
                <tr>
                  <th style={{ width: "20%" }}> Campaign Url </th>
                  {user?.campaign_url ? (
                    <>
                      <td
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          //   style={{
                          //     border: showBorder ? "2px solid blue" : "none",
                          //     padding: "4px",
                          //     borderRadius: "5px",
                          //   }}
                          id="copypasswordid"
                        >
                          {user?.campaign_url}
                        </span>
                        {/* <button
                          type="button"
                          className="btn coppybtn"
                        > */}
                        <div
                          style={{
                            cursor: "pointer",
                            marginLeft: "10px",
                          }}
                          onClick={() => CopyPassword(user?.campaign_url)}
                        >
                          <img
                            style={{
                              cursor: "pointer",
                              marginLeft: "10px",
                            }}
                            src={CopyIcon}
                            alt="copy icon"
                          />
                          <span>{HoverText}</span>
                        </div>
                        {/* </button> */}
                      </td>
                    </>
                  ) : (
                    <td>No Url Found </td>
                  )}
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </Collapse>
    </>
  );
};

export default CampaignItem;
