import { ADD_CATEGORY_LIST_API_URL } from "../components/Shared/constant";

const AddCategory = async (accessToken, payload) => {
    const responce = window.axios.post(ADD_CATEGORY_LIST_API_URL, payload, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + accessToken,
    }}).then(function (result) {
        return {
          ...result.data,
          status:200
        };
    }).catch(function (result) {
        return {
          ...result?.response?.data,
          status:result?.response?.status
        }
    });
    return responce;
};
  
export default AddCategory;
  