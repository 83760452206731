import React, { useState, useEffect } from "react";
import { Modal, Row } from "react-bootstrap";
import DefaultProduct from "./../../assets/img/DefaultProduct.jpg";
import CommonLoader from "../Common/CommonLoader";
// import NOImg  from "../../assets/img/nope-not-here.webp"
import axios from "axios";
import { useSelector } from "react-redux";

export default function ModalPreview({ onClose, data }) {
  const [loaded, setLoaded] = useState(false);
  const [loaded1, setLoaded1] = useState(false);
  const [loaded3, setLoaded3] = useState(false);
  const [isUrlAvailable, setIsUrlAvailable] = useState(true);

  const { accessToken } = useSelector((state) => state.personalReducers);
  const [load, setLoad] = useState(false);

  // console.log("data", data);

  const [sizeInKBforMosaic, setSizeInKbForMosaic] = useState(null);
  const [sizeinKbForBlend, setSizeInKbBlend] = useState(null);

  const proxyUrl = "https://cors-anywhere.herokuapp.com/";

  const fetchImageSize = async (url, setSizeCallback) => {
    try {
      const response = await fetch(`${proxyUrl}${url}`, { method: "HEAD" });
      const size = response.headers.get("Content-Length");

      if (size) {
        // Convert bytes to kilobytes and set state
        const sizeInKB = parseInt(size, 10) / 1024;
        console.log(`Image size: ${sizeInKB.toFixed(2)} KB for ${url}`);
        setSizeCallback(sizeInKB.toFixed(2));
      } else {
        console.log("Size not available for", url);
        setSizeCallback(null);
      }
    } catch (error) {
      console.error("Error fetching image size for", url, error);
    }
  };
  useEffect(() => {
    if (data?.mosaic_url) {
      fetchImageSize(data.mosaic_url, setSizeInKbForMosaic);
    }

    if (data?.blend_url) {
      fetchImageSize(data?.blend_url, setSizeInKbBlend);
    }
  }, [data?.mosaic_url, data?.blend_url]);

  useEffect(() => {
    if (!data?.mosaic_url && !data?.blend_url) {
      setLoaded1(true);
      setLoaded3(true);
      return;
    }

    if (!data?.mosaic_url) {
      setLoaded1(true);
    }

    if (!data?.blend_url) {
      setLoaded3(true);
    }

    if (sizeInKBforMosaic !== null) {
      const sizeInMB = sizeInKBforMosaic / 1024;
      setLoaded1(sizeInMB > 25);
    }

    if (sizeinKbForBlend !== null) {
      const sizeInMB = sizeinKbForBlend / 1024;
      setLoaded3(sizeInMB > 25);
    }
  }, [data?.mosaic_url, data?.blend_url, sizeInKBforMosaic, sizeinKbForBlend]);

  const handleImageLoaded = () => {
    setLoaded(true);
    setIsUrlAvailable(false);
  };
  const handleImageLoaded1 = () => {
    setLoaded1(true);
  };

  const handleImageLoaded3 = () => {
    setLoaded3(true);
    if (sizeinKbForBlend !== null) {
      const sizeInMB = sizeinKbForBlend / 1024;
      if (sizeInMB > 50) {
        console.log("yes");
        setLoaded3(false);
      } else {
        console.log("no");
        setLoaded3(true);
      }
    }
  };
  const handleImageError = (e) => {
    e.target.src = DefaultProduct;
    setLoaded(true);
  };

  const handleImageErrorMosaci = (e) => {
    e.target.src = DefaultProduct;
    setLoaded1(false);
  };
  const handleImageErrorBlended = (e) => {
    e.target.src = DefaultProduct;
    setLoaded3(false);
  };

  const setDownloadURL = (url) => {
    window.open(url);
  };

  const downloadImage = (url) => {
    if (!url) return;

    // Open the image in a new tab
    const newTab = window.open(url, "_blank");

    // Wait for the new tab to load and then trigger the download
    newTab.onload = () => {
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "blended-image.jpg"; // Set a default file name
      anchor.click();
    };
  };

  const DonwloadFile = async (blend_url, accessToken) => {
    const authurl = blend_url;
    const url = await GetViewFilesAPI(authurl, accessToken);
    const parsedUrl = new URL(blend_url);
    const fileName = parsedUrl.pathname.split("/").pop();
    if (url) {
      var tempEl = document.createElement("a");
      tempEl.href = url;
      tempEl.download = fileName;
      tempEl.click();
      window.URL.revokeObjectURL(url);
      setLoad(false);
    }
  };

  const GetViewFilesAPI = async (url, qr_token) => {
    setLoad(true);
    try {
      const options = {
        headers: {
          Authorization: `Bearer ${qr_token}`,
        },
        responseType: "blob",
      };
      const response = await axios.get(url, options);
      // console.log("url==========>",response)
      // console.log("response", response)
      const file = window.URL.createObjectURL(response.data);
      // console.log("file",file)

      return file;
    } catch (error) {
      return error;
    }
  };
  return (
    <Modal
      centered
      className={
        data?.source_url ? "modal-preview " : "modal-preview modal-preview-new"
      }
      show={true}
      onHide={() => onClose(null)}
    >
      <button
        type="button"
        className="btn-close"
        aria-label="Close"
        onClick={() => onClose(null)}
      ></button>
      <Modal.Body>
        <Row className="m-0">
          {data?.source_url ? (
            <div className={`${data?.is_blended ? "col-4" : "col-6"} `}>
              <h5>Source Image</h5>

              <img
                onLoad={handleImageLoaded}
                src={data?.source_url}
                alt={data?.source_url}
                onError={handleImageError}
                className="image-preview w-100"
                style={{ objectFit: "cover", height: "70%" }}
              />
              <button
                style={{
                  margin: "10px",
                }}
                className="btn btn-primary cmnfilterHeadbtn"
                onClick={() => setDownloadURL(data?.source_url)}
              >
                Download Source
              </button>
            </div>
          ) : null}
          <div
            className={`${
              data?.is_blended && data?.source_url 
                ? "col-4"
                : data?.source_url
                ? "col-6"
                : "only_show_mosaic"
            } `}
          >
            <h5>Mosaic Image</h5>
            {!data?.mosaic_url ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "70%",
                  justifyContent: "center",
                  padding: "10px",
                  border: "1px solid gray",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    marginBottom: "0px",
                  }}
                >
                  Mosaic effect is in progress. Usually it takes 7 to 10 minutes
                  to get generate.
                </p>
                <p>Thank you for your patience.</p>
              </div>
            ) : data?.mosaic_url &&
              (sizeInKBforMosaic / 1024 > 25 || sizeInKBforMosaic === null) ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "70%",
                    justifyContent: "center",
                    border: "1px solid gray",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      marginBottom: "0px",
                    }}
                  >
                    The file size is too large to display a preview. Please
                    download the image to view it.
                  </p>
                </div>
                <button
                  style={{
                    margin: "10px",
                  }}
                  className="btn btn-primary cmnfilterHeadbtn"
                  onClick={() => setDownloadURL(data?.mosaic_url)}
                >
                  Download Mosaic
                </button>
              </>
            ) : (
              <>
                <img
                  className="image-preview w-100"
                  style={{ objectFit: "cover", height: "70%" }}
                  src={data?.mosaic_url}
                  alt={data?.mosaic_url}
                  onLoad={handleImageLoaded1}
                  // onLoad={() => setLoaded1(true)}
                  // onError={handleImageError}
                  onError={handleImageErrorMosaci}
                />

                <button
                  style={{
                    margin: "10px",
                  }}
                  className="btn btn-primary cmnfilterHeadbtn"
                  onClick={() => setDownloadURL(data?.mosaic_url)}
                >
                  Download Mosaic
                </button>
              </>
            )}
          </div>

          {(data?.is_blended &&data?.source_url) && (
            <div className="col-4">
              <h5>Blended Image </h5>
              {data?.blend_url ? (
                <>
                  {data?.blend_url &&
                  (sizeinKbForBlend === null ||
                    sizeinKbForBlend / 1024 > 25) ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          height: "70%",
                          justifyContent: "center",
                          border: "1px solid gray",
                          borderRadius: "10px",
                          padding: "10px",
                        }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "0px",
                          }}
                        >
                          The file size is too large to display a preview.
                          Please download the image to view it.
                        </p>
                      </div>
                      <button
                        style={{
                          margin: "10px",
                        }}
                        className="btn btn-primary cmnfilterHeadbtn"
                        onClick={() =>
                          DonwloadFile(data?.blend_url, accessToken)
                        }
                        disabled={load}
                      >
                        Download Blended
                      </button>
                    </>
                  ) : (
                    <>
                      <img
                        className="image-preview w-100"
                        style={{ objectFit: "cover", height: "70%" }}
                        src={data?.blend_url}
                        alt={data?.blend_url}
                        onError={handleImageErrorBlended}
                        // onLoad={handleImageLoaded3}
                        onLoad={() => setLoaded3(true)}
                      />
                      <button
                        style={{
                          margin: "10px",
                        }}
                        className="btn btn-primary cmnfilterHeadbtn"
                        //  onClick={() => downloadImage(data?.blend_url)}
                        onClick={() =>
                          DonwloadFile(data?.blend_url, accessToken)
                        }
                        disabled={load}
                      >
                        Download Blended
                      </button>
                    </>
                  )}
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "70%",
                    justifyContent: "center",
                    padding: "10px",
                    border: "1px solid gray",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      marginBottom: "0px",
                    }}
                  >
                    Blending effect is in progress. Usually it takes 7 to 10
                    minutes to get generate.
                  </p>
                  <p>Thank you for your patience.</p>
                </div>
              )}
            </div>
          )}
        </Row>
        {data?.source_url ? (
          <>
            {!loaded1 || !loaded ? (
              <CommonLoader classNameS={"charityLoader"} />
            ) : null}
          </>
        ) : (
          <>{!loaded1 ? <CommonLoader classNameS={"charityLoader"} /> : null}</>
        )}
      </Modal.Body>
    </Modal>
  );
}
