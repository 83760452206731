export const ActionTypes = {
    GET_CATEGORY_LIST: "GET_CATEGORY_LIST",
    GET_CATEGORY_LIST_ID_WISE: "GET_CATEGORY_LIST_ID_WISE",
    EDIT_CATEGORY_LIST: "EDIT_CATEGORY_LIST",
    ADD_CATEGORY_LIST: "ADD_CATEGORY_LIST",
    DELETE_CATEGORY_LIST: "DELETE_CATEGORY_LIST",
    GET_ALL_CATEGORY_LIST: "GET_ALL_CATEGORY_LIST"
};

export const getCategoryList = (category) => {
    return {
       type: ActionTypes.GET_CATEGORY_LIST,
       payload: category,
    }
};

export const getAllCategoryList = (categories) => {
    return {
        type: ActionTypes.GET_ALL_CATEGORY_LIST,
        payload: categories,
    }
};

export const addCategoryList = (category) => {
    return {
        type: ActionTypes.ADD_CATEGORY_LIST,
        payload: category,
    }
};

export const editCategoryLists = (category) => {
    return {
       type: ActionTypes.EDIT_CATEGORY_LIST,
       payload: category,
    }
};

export const deleteCategoryLists = (category) => {
    return {
        type:ActionTypes.DELETE_CATEGORY_LIST,
        payload: category,
    }
};

export const getCategoryListIdWise = (category) => {
    return {
       type: ActionTypes.GET_CATEGORY_LIST_ID_WISE,
       payload: category,
    }
};