/* eslint-disable */
import React from 'react'
import { Modal  } from 'react-bootstrap';

export default function ModalTemplate({data , setClose}) {
  return (
    <Modal show onHide={()=>setClose(null)}>
        <Modal.Header closeButton>
            <h5 className='m-0'>Preview</h5>
        </Modal.Header>
      <Modal.Body>
        {data?.type!="email" && <h5 className='text-center'>{data?.subject}</h5>}
        <div className={"pop-up successpymentwrapper"} dangerouslySetInnerHTML={{__html:data?.body}}></div>
      </Modal.Body>
    </Modal>
  )
}
