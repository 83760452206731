/* eslint-disable */
import { ActionTypes } from "../actions/packageAction";

const initailData = {
    packageList: [],
    CurrenUrlBaseForPackage: "",
    campaignListIdWise: {},
    campaignOptionList:[],
    mosaicList:[],
    mailCampaignList:[],
    freeCouponList:{
        data:[],
        pagination:{
            current_page: 1,
            record_limit: 10,
            total_records: 0,
        },
    }
};

export const packageReducers = (state = initailData, action) => {
    switch (action.type) {
        case ActionTypes.GET_PACKAGES_LIST:
            return {
                ...state,
                packageList: action.payload,
            };
        case ActionTypes.PACKAGES_CURRENT_TAB:
            return {
                ...state,
                CurrenUrlBaseForPackage: action.payload,
            };
        case ActionTypes.ADD_PACKAGES_NEW_LIST:
            const oldCampaign = [...state.packageList];
            oldCampaign.unshift(action.payload);
            return {
                ...state,
                packageList: oldCampaign,
            };
        case ActionTypes.DELETE_PACKAGES_LIST:
                const campaignListLists = [...state.packageList]; 
                const NewUpdatedCampaignListLists = [];
                campaignListLists && campaignListLists.filter((elm) => {
                    if(elm?.id !== action.payload.id) {
                        return NewUpdatedCampaignListLists.push(elm);
                    }
                });
    
                return {
                    ...state,
                    packageList: NewUpdatedCampaignListLists,
                }; 
        case ActionTypes.UPDATE_PACKAGES_NEW_LIST:
            const campaignListOld = [...state.packageList]; 
            const updatecampaignList = campaignListOld.map((elm) => {
                if(elm?.id === action?.payload?.id) {
                    return{
                        ...elm,
                        name:action?.payload?.name,
                        quantity:action?.payload?.quantity,
                        package_id:action?.payload?.id,
                        is_active: action?.payload?.is_active
                    }
                }else 
                return elm;
            });
            
            return {
                ...state,
                packageList: updatecampaignList,
            };
        case ActionTypes.SET_STORE_FREE_PACKAGE_LIST:{
            return{
                ...state,
                freeCouponList: action?.payload?action?.payload:initailData?.freeCouponList,
            }
        }
        case ActionTypes.SET_DELETE_COUPON_LIST:{
            const campaignListLists = [...state.freeCouponList?.data]; 
            const NewUpdatedCampaignListLists = [];
            campaignListLists && campaignListLists.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedCampaignListLists.push(elm);
                }
            });

            return {
                ...state,
                freeCouponList: {
                    ...state?.freeCouponList,
                    data: NewUpdatedCampaignListLists,
                },
            };
        }
        case ActionTypes.SET_UPDATE_COUPON_LIST:{
            const campaignListOld = [...state.freeCouponList?.data]; 
            const updatecampaignList = campaignListOld.map((elm) => {
                if(elm?.id === action?.payload?.id) {
                    return{
                        ...elm,
                       ...action?.payload,
                    }
                }else 
                return elm;
            });
            
            return {
                ...state,
                freeCouponList: {
                    ...state?.freeCouponList,
                    data: updatecampaignList
                },
            };
        }
        // case ActionTypes.SET_STORE_CAMPAIGN_LIST_OPTION:{
        //     const list = action?.payload.map((item) => {
        //         return{
        //             ...item,
        //             label: item?.title,
        //             value: item?.id,
        //         }
        //     });
            
        //     return {
        //         ...state,
        //         campaignOptionList: list,
        //     };
        // }
        // case ActionTypes.GET_CAMPAIGN_LIST_ID_WISE:
        //     const getCampaignObject = {};
        //     console.log("campaignListIdWise", state?.campaignListIdWise)
        //     action.payload && action.payload.map((elm) => {
        //         getCampaignObject[elm.slug] = elm;
        //     });
        //     return {
        //         ...state,
        //         campaignListIdWise: getCampaignObject,
        //     };

        // case ActionTypes.SET_STORE_UPDATE_CAMPAIGN_SEQUENCE:{
        //     const data = state?.campaignList?.map((elm) => {
        //         if(elm?.id == action.payload.id) {
        //             return{
        //                 ...elm,
        //                 sequence_no: action?.payload?.sequence_no,
        //             }
        //         }
        //         return elm
        //     });

        //     return {
        //         ...state,
        //         campaignList: data,
        //     };
        // }
        // case ActionTypes.SET_STORE_MAIL_CAMPAIGN_LIST:
        //     return {
        //         ...state,
        //         mailCampaignList: action.payload,
        //     };
        // case ActionTypes.SET_STORE_MOSAIC_LIST:
            // return {
            //     ...state,
            //     mosaicList: action.payload,
            // };
        default:
        return state;
    }
}