/* eslint-disable */
import React, { useState } from "react";
import { Button, Form, Image } from 'react-bootstrap';
import UploadImageIcon from "../../assets/img/uploadImage.png";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from "react-redux";
import { Country }  from 'country-state-city';
import CheckValid from "../Common/CheckValid";
import UploadFileModal from "../Common/UploadFileModal";

const Edit = (props) => {
    const { fundEditState, coverImage, setCoverImage, setFundEditState, setErrortTitle, 
            story, setStory, setErrorAmount, 
            setErrorCategory, setErrorCountry, 
            setErrorFundRaiseFor, errorFundRaiseFor,
            errorAvatar, errortitle, errorAmount, errorCategory, errorCountry, 
            errorDescription, selectedFundraise } = props;
    const { AllTotalCategory } = useSelector((state) => state.categoryReducers);
    const { charityList } = useSelector((state) => state.charityReducers);
    const { athleteList } = useSelector((state) => state.athleteReducers);
    const [ showFileModal, setShowFileModal ] = useState({
        open: false,
        title: "",
    });
    const YouTubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/; // eslint-disable-line
    const [ selectFundraiseForTab, setSelectFundraiseForTab ] = useState(selectedFundraise?.fundraise_type);

    // select country 
    const SelectCoutry = (event) => {
        setFundEditState({...fundEditState, country: event.target.value});
        CheckValid(event.target.value, {type: 'country', error: setErrorCountry });
    };

    // fundraise title
    const fundraiserTitle = (e) => {
        setFundEditState({...fundEditState, title: e.target.value})
    };

    // select category
    const SelectCategory = (event) => {
        setFundEditState({...fundEditState, category: event.target.value});
        CheckValid(event.target.value, {type: 'selectcategoryname', error: setErrorCategory });
    };

    // select fundraise for
    const SelectFundraiseFor = (event) => {
        setFundEditState({...fundEditState, fundraise_for: event.target.value});
        CheckValid(event.target.value, {type: 'fundraiseforname', error: setErrorFundRaiseFor });
    };  

    // last key code check and remove value
    const ValueKeyPress = (e) => {
        const code = e.keycode || e.which;
        if(code === 8 && e.target.innerHTML === "<p><br></p>") {
            setStory("");
        };
    };

    // editor code
    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video"
    ];
    const modules = {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" }
          ],
          ["link", "image", "video"],
          ["clean"]
        ]
    };

    // upload media files
    const UploadFiles = () => {
        setShowFileModal({
            ...showFileModal,
            open: !showFileModal?.open,
            title: "Cover Media"
        });
    };

    return(<div className="tabsWrapperset">
            <div className="formloginset formeditset">
                <div className="row">
                    <div className="col-md-4">
                        <div className="formgroups">
                            <h4>Title and goal</h4>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <Form.Group className="mb-3 formeditsetwps" >
                            <Form.Label>Fundraiser title</Form.Label>
                            <div className="titlewithcount">
                                <Form.Control type="text" maxLength={50} value={fundEditState.title} onChange={(e) => fundraiserTitle(e)} onKeyDown={(e) => EmptySpaceFieldValid(e)} onKeyUp={(e) => CheckValid(e.target.value, {type: 'name', error: setErrortTitle })} placeholder="Enter Fundraiser title" />
                                {errortitle && <div className="danger-colorset">{errortitle}</div>}
                            </div>
                            <small className="note">A good title starts with an action word and includes a name.</small>
                        </Form.Group>
                        <Form.Group className="mb-3 formeditsetwps" >
                            <Form.Label>Flexible goal</Form.Label>
                            <div className="ammountfield">
                                <span>$</span>
                                <Form.Control type="number" value={fundEditState.amount} onChange={(e) => setFundEditState({...fundEditState, amount: e.target.value})} onKeyUp={(e) => CheckValid(e.target.value, {type: "amount", error: setErrorAmount })} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter Flexible goal" />
                            </div>
                            {errorAmount && <div className="danger-colorset">{errorAmount}</div>}
                            <small className="note">You can change your goal and transfer funds raised any time.</small>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="formgroups">
                            <h4>Cover media</h4>
                            <p>Use a photo or video that represents your story well. A photo (JPG, PNG, GIF, or BMP) should be less than 20MB.</p>
                            <Button className="uploadcoverMediabtn" onClick={() => UploadFiles()}>
                                <Image src={UploadImageIcon} alt="uploadImage" /> 
                                Upload
                            </Button>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="coverUploadImage" onClick={() => UploadFiles()}>
                            {(coverImage !== "") ? (<>
                                {(YouTubeRegex.test(coverImage) === true) ? (<div className="uploadeyoutubevideo">
                                    <iframe src={`https://www.youtube.com/embed/${coverImage?.split("/")[coverImage?.split("/").length - 1].replace("watch?v=", "")}`}></iframe>
                                </div>) :
                                (<Image src={coverImage} className="uplodedimageset" alt="uploadImage" />)}
                            </>) : (<div className="coverUploadImageSet">
                                <Image src={UploadImageIcon} className="uplodimgicon" alt="uploadImage" />  
                                <h4>Upload your photo or youtube link here</h4>
                            </div>)}
                        </div>
                        {(errorAvatar && coverImage === "") && <div className="danger-colorset">{errorAvatar}</div>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="formgroups">
                            <h4>Story</h4>
                            <p>Tell donors why support is needed and how funds will be used.</p>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="editorBox">
                            <ReactQuill 
                                formats={formats}
                                modules={modules}
                                theme="snow" 
                                value={story} 
                                onChange={setStory} 
                                onKeyDown={(e) =>setTimeout(()=> ValueKeyPress(e), 10)}
                            />
                            {(errorDescription && story === "") && <div className="danger-colorset">Description cannot be empty</div>}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="formgroups">
                            <h4>More details</h4>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <Form.Group className="mb-3 formeditsetwps" >
                            <Form.Label>Country</Form.Label>
                            <select className="form-control" value={fundEditState?.country?.charAt(0).toUpperCase() + fundEditState?.country?.slice(1)} onChange={(e) => SelectCoutry(e)} aria-label="Default select example">
                                <option value="">Select Country</option>
                                {Country.getAllCountries().map((elm, index) => {
                                    return(<option value={elm.name}  key={index}>{elm.name}</option>)
                                })}
                            </select>
                            {errorCountry && <div className="danger-colorset">{errorCountry}</div>}
                        </Form.Group>
                        <Form.Group className="mb-3 formeditsetwps" >
                            <div className="selectfundraisefor">
                                <ul className="radiocustom">
                                    <li className={selectFundraiseForTab === "athlete" ? "active": ""} onClick={() => setSelectFundraiseForTab("athlete")}>Athlete</li>
                                    <li className={selectFundraiseForTab === "charity" ? "active": ""} onClick={() => setSelectFundraiseForTab("charity")}>Charity</li>
                                </ul>
                            </div>
                            <Form.Label>Fundraise for ( {selectFundraiseForTab} )</Form.Label>
                            {selectFundraiseForTab === "charity" ? (<select className="form-control" value={fundEditState?.fundraise_for} onChange={(e) => SelectFundraiseFor(e)} aria-label="Default select example">
                                <option value="">Select fundraise for</option>
                                {charityList && charityList.map((elm, index) => {
                                    return(<option  value={elm?.slug} key={index}>{elm?.name}</option>)
                                })}
                            </select>): (<select className="form-control" value={fundEditState?.fundraise_for} onChange={(e) => SelectFundraiseFor(e)} aria-label="Default select example">
                                <option value="">Select fundraise for</option>
                                {athleteList && athleteList.map((elm, index) => {
                                    return(<option  value={elm?.slug} key={index}>{elm?.name}</option>)
                                })}
                            </select>)}
                            {errorFundRaiseFor && <div className="danger-colorset">{errorFundRaiseFor}</div>}
                        </Form.Group>
                        <Form.Group className="mb-3 formeditsetwps" >
                            <Form.Label>Category</Form.Label>
                            <select className="form-control" value={fundEditState?.category} onChange={(e) => SelectCategory(e)} aria-label="Default select example">
                                <option value="">Select Category</option>
                                {AllTotalCategory && AllTotalCategory.map((elm, index) => {
                                    return(<option  value={elm?.slug} key={index}>{elm?.name}</option>)
                                })}
                            </select>
                            {errorCategory && <div className="danger-colorset">{errorCategory}</div>}
                        </Form.Group>
                    </div>
                </div>
            </div>
            {showFileModal?.open && (<UploadFileModal setCoverImage={setCoverImage} showFileModal={showFileModal} setShowFileModal={setShowFileModal} />)}
        </div>)
};

export default Edit;