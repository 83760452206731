export const ActionTypes = {
    GET_DONATIONS_LIST: "GET_DONATIONS_LIST",
    UPDATE_DONATATAION_NEW_LIST: "UPDATE_DONATATAION_NEW_LIST",

};

export const getDonationsList = (donation) => {
    return {
       type: ActionTypes.GET_DONATIONS_LIST,
       payload: donation,
    }
}
export const UpdateDonationList = (donation) => {
    return {
        type: ActionTypes.UPDATE_DONATATAION_NEW_LIST,
        payload: donation,
    }
};
