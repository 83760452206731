import React from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { Image } from "react-bootstrap";
import { selectFundraiseUpdatesOptions } from "../../redux/actions/fundActions";

const UpdatesList = (props) => {
    const { FundraiseUpdates, setMainTab } = props;
    const dispatch = useDispatch();
    const { selfInfo } = useSelector((state) => state.personalReducers);
    const { userListId } = useSelector((state) => state.userReducers);

    // fundraise name
    const updateUserName = (updates) => {
        return updates?.user_id === selfInfo?.id ? "You" : userListId[updates?.user_id]?.first_name + " " + userListId[updates?.user_id]?.last_name;
    };

    // view updates
    const ViewUpdates = () => {
        dispatch(selectFundraiseUpdatesOptions("view-updates"))
        setMainTab("updates");
    };

    return(<div className="updatedescriotionview">
        <h4 style={{ marginBottom: "20px" }}>Updates ({FundraiseUpdates === null ? 0 : FundraiseUpdates?.length})</h4>
        <ul className="updatecomment">
            {(FundraiseUpdates === null || FundraiseUpdates === "") ? 
            (<li>No Updates Available</li>) :
            (<React.Fragment>
                {FundraiseUpdates?.slice(0, 2).map((updates, index) => {
                    return(<li style={{ flexDirection: "column" }} key={index}>
                        <div className="commtsdetail">
                            <h3>{moment(updates?.created_at).format('LL')} <small>by {updateUserName(updates)}</small></h3>
                            <Image src={updates?.image} className="updatedImage" alt="" />
                            <div style={{ fontSize: "13px" }} dangerouslySetInnerHTML={{__html: updates?.description}}></div>
                        </div>
                    </li>)
                })}
            </React.Fragment>)}
        </ul>
        {FundraiseUpdates?.length > 0 && (<div className="viewmoreUpdatescheck" onClick={() => ViewUpdates()}>View More</div>)}
    </div>)
}

export default UpdatesList;