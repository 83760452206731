import { combineReducers } from "redux";
import { personalReducers } from "./personalReducers";
import { userReducers } from "./userReducers";
import { categoryReducers } from "./categoryReducers";
import { fundraiseReducers } from "./fundraiseReducers";
import { charityReducers } from "./charityReducers";
import { blogCategoryReducers } from "./blogCategoryReducers";
import { blogReducers } from "./blogReducers";
import { contactUsReducers } from "./contactUsReducers";
import { fundraiseApllicationReducers } from "./fundraiseApllicationReducers";
import { athleteReducers } from "./athleteReducers";
import { donationReducers } from "./donationReducers";
import { dashboardReducers } from "./dashboardReducers";
import { campaignReducers } from "./campaignReducers";
import { packageReducers } from "./packageReducers";
import { orderReducers } from "./orderReducers";
import { paymentReducers } from "./paymentReducers";

const reducers = combineReducers({
    personalReducers: personalReducers,
    userReducers: userReducers,
    categoryReducers: categoryReducers,
    fundraiseReducers: fundraiseReducers,
    charityReducers: charityReducers,
    blogCategoryReducers: blogCategoryReducers,
    blogReducers: blogReducers,
    contactUsReducers: contactUsReducers,
    fundraiseApllicationReducers: fundraiseApllicationReducers,
    athleteReducers: athleteReducers,
    donationReducers: donationReducers,
    dashboardReducers: dashboardReducers,
    campaignReducers: campaignReducers,
    packageReducers:packageReducers,
    orderReducers:orderReducers,
    paymentReducers:paymentReducers
})

export default reducers;