/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';
import moment from "moment";
import { Form, Badge, Image, Collapse, FormControl } from 'react-bootstrap';
import FiltersForm from "../Common/FiltersForm";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import CommonPagination from "../Common/CommonPagination";
import CommonModal from "../Common/CommonModal";
import GetEmailCampaign from "../../Api/GetEmailCampaign";
import { setStoreMailCampaignList, setStoreMosaicList } from "../../redux/actions/campaignAction";
import { CAMPAIGN_MAIL_LIST, LIST_MOSAIC_SOURCE_IMAGE, MAIL_CAMPAIGN_MANAGEMENT, MOSAIC_MANAGEMENT } from "../Shared/constant";
import EyeIcon from "../../assets/img/EyeIcon.svg";
import { GetRequestCallAPI } from "../../Api/GetRequest";
import Download from "../../assets/img/Download.svg";
import ModalPreview from "./ModalPreview";

const MosaicManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { mosaicList, campaignList } = useSelector((state) => state.campaignReducers);
    const { is_mosaic,parent_compaign_Id } = useSelector((state) => state.personalReducers);
    // console.log("parent_compaign_Id",parent_compaign_Id)
    
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const [ ShowModalTemplate, setShowModalTemplate ] = useState(null)
        const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [ paginationLength, setPaginationLength ] = useState({});

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
        fetchData(page,userLimit,userType)
    };
    
    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
        fetchData(1,userLimit,e.target.value);
    };
    const [abortController, setAbortController] = useState({});

    useEffect(() => {
        fetchData(1,userLimit,userType);
         return () => {
            if(abortController.abort){
                abortController.abort();
                setAbortController(new AbortController());
            }
        };
    }, [ userSearch ]);

    // Get data from api
    const fetchData = async (currentPage,userLimit,userType) => {
        const signal = abortController.signal;
        setCurrentPage(currentPage)
        const ContactList = await GetRequestCallAPI(`${LIST_MOSAIC_SOURCE_IMAGE}/${userLimit}/${currentPage}/${userSearch === "" ? is_mosaic==="yes" ? parent_compaign_Id:"0" : userSearch}`, accessToken );
        // console.log("ContactList", ContactList)
        if(ContactList?.status === 200) {
            dispatch(setStoreMosaicList(ContactList?.data?.data));
            setPaginationLength(ContactList?.data?.pagination);
        } else if(ContactList?.status === 403) {
            CommonLogout();
            dispatch(setStoreMosaicList([]));
            setPaginationLength({})
        } else {
            dispatch(setStoreMosaicList([]));
            setPaginationLength({})
        };
    };

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // user active or deactive
    const ContactActivStatus = (user, type) => {
        if(type === "active_action") {
            setShowModal({
                ...showModal,
                open: !showModal?.open,
                title: "Are you sure?",
                description: `Do you really want to mark this as ${user?.is_active ? 'inactive?' : 'active?'}`,
                modalType: "not form",
                button: "Yes",
                category: "Contact Active",
                Data: user
            });
        } else if(type === "contacted_action") {
            setShowModal({
                ...showModal,
                open: !showModal?.open,
                title: "Are you sure?",
                description: `${user?.is_contacted ? 'Do you really not want to be contacted about this request?' : 'Do you really want to be contacted about this request?'}`,
                modalType: "not form",
                button: "Yes",
                category: "Contacted Us",
                Data: user
            });
        } else {
            setShowModal({
                ...showModal,
                open: !showModal?.open,
                title: "Are you sure?",
                description: "Do you really want to delete?",
                modalType: "not form",
                button: "Yes",
                category: "Delete contact",
                Data: user
            });
        }
    };

    const callUserLimit = (e) =>{
        setUserLimit(e);
        fetchData(1, e,userType)
    }
    const downloadFile = (url, filename) => {
        window.axios({
            method: 'get',
            url: url,
            responseType: 'blob', // Set response type to 'blob' to handle binary data
            // headers: {
            //   Authorization: `Bearer ${accessToken}`, // Assuming it's a bearer token, adjust accordingly if not
            // },
          })
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', url.substring(url.lastIndexOf('/') + 1));
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
            })
            .catch(error => {
              console.error('Error downloading file:', error);
            });
      };
      
    const setDownloadURL = (item) =>{
        // downloadFile(item?.mosaic_url, "demo");
        window.open(item?.mosaic_url);
    }
    // console.log("mosaicList",mosaicList)
    return(<>
        <FiltersForm 
            filterType={"(Mosaic Name & Compaign)"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={callUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
            onAdd={()=>navigate(`/add${MOSAIC_MANAGEMENT}`)}
            add_text={"Add Mosaic"}
        />
        <div className="tableContent">
            <Scrollbars 
                style={{ height: paginationLength?.total_records > 10 ? "calc(100% - 87px)" : "calc(100% - 50px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                                 <thead>
                                     <tr>
                                         <th>Name</th>
                                         <th>Campaign</th>
                                         <th>Pool Count</th>

                                         <th>Quality</th>
                                         <th>Test Utilization</th>
                                         <th>Utilize Less Occurrence</th>

                                         <th>Opacity</th>
                                         <th>Utilize Blending</th>

                                         <th>Availability</th>
                                         <th>Created At</th>
                                         <th>Action</th>
                                     </tr>
                                 </thead>
                        <tbody>
                            {mosaicList && mosaicList?.map((user, index) => {
                                return(<React.Fragment key={index}>
                                <tr style={{
                                        textTransform:"capitalize"
                                    }} className={activeTableDetails === user?.id ? "active" : ""}>
                                    <td >{user?.name? user?.name:"-"}</td>
                                    <td>{user?.campaign? user?.campaign:"-"}</td>
                                    <td>{user?.pool_count?user?.pool_count:"-"}</td>
                                    <td className="space-nowrap text-nowrap">
                                        <Form className="switchbtn">
                                            <Badge bg={user?.is_high_quality ? "primary" : "danger"}>{user?.is_high_quality ? "High" : "Low"}</Badge>
                                        </Form>
                                     </td>
                                     <td className="space-nowrap text-nowrap">
                                         <Form className="switchbtn">
                                             <Badge bg={user?.is_test_utilize ? "primary" : "danger"}>{user?.is_test_utilize ? "Yes" : "No"}</Badge>
                                         </Form> 
                                     </td>  
                                     <td className="space-nowrap text-nowrap">
                                         <Form className="switchbtn">
                                             <Badge bg={user?.utilize_less_occurrence ? "primary" : "danger"}>{user?.utilize_less_occurrence ? "Yes" : "No"}</Badge>
                                         </Form> 
                                     </td>  
                                    <td>{user?.opacity ==="null" ?'-' :user?.opacity ? user?.opacity :"-"}</td>
                                   
                                     <td className="space-nowrap text-nowrap">
                                        <Form className="switchbtn">
                                            <Badge bg={user?.is_blended ? "primary" : "danger"}>{user?.is_blended ? "Yes" : "No"}</Badge>
                                        </Form>
                                     </td>
                                   
                                     <td className="space-nowrap text-nowrap">
                                         <Form className="switchbtn">
                                             <Badge bg={user?.is_available ? "primary" : "danger"}>{user?.is_available ? "Yes" : "No"}</Badge>
                                         </Form>
                                      </td>
                                      <td>{moment(user.created_at).format('LL HH:mm A')}</td>
                                      <td>
                                        <button className="btn actionbtn" onClick={()=>setShowModalTemplate(user)} >
                                            <Image  src={EyeIcon} alt="delete icon" />
                                        </button>
                                        {/* <button className="btn actionbtn" onClick={()=>setDownloadURL(user)} >
                                            <Image  src={Download} alt="delete icon" />
                                        </button> */}
                                    </td>
                                </tr>
                                <Collapse in={activeTableDetails === user?.id ? true : false}>
                                    <tr className="tablecollapsewraps" id={"tablecollpase"+user?.id}>
                                        <td colSpan="8">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Message</th>
                                                        <td>{user?.message === null ? "-" : user?.message}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </Collapse>
                            </React.Fragment>)
                            })}
                            {mosaicList && mosaicList.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>Mosaic Not Found !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
            {/* pagination */}
            {parseInt(paginationLength?.total_records) > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={paginationLength}
                currentFunction={currentFunction}
            />)}
        </div>
        {showModal?.open && (<CommonModal showModal={showModal} setShowModal={setShowModal} />)}
        {ShowModalTemplate && (
            <ModalPreview data={ShowModalTemplate} onClose={setShowModalTemplate}/>
        )}
    </>)
}

export default MosaicManagement;