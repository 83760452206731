export const ActionTypes = {
    GET_ATHLETE_LIST: "GET_ATHLETE_LIST",
    GET_ATHLETE_LIST_ID_WISE: "GET_ATHLETE_LIST_ID_WISE",
    EDIT_ATHLETE: "EDIT_ATHLETE",
    ADD_ATHLETE: "ADD_ATHLETE",
    DELETE_ATHLETE: "DELETE_ATHLETE",
    SELECT_ATHLETE_TAB: "SELECT_ATHLETE_TAB",
    SELECTED_ATHLETE: "SELECTED_ATHLETE"
};

export const selectAthleteList = (athlete) => {
    return {
       type: ActionTypes.SELECTED_ATHLETE,
       payload: athlete,
    }
}

export const getAthleteList = (athlete) => {
    return {
       type: ActionTypes.GET_ATHLETE_LIST,
       payload: athlete,
    }
}

export const getAthleteListIDWise = (athlete) => {
    return {
       type: ActionTypes.GET_ATHLETE_LIST_ID_WISE,
       payload: athlete,
    }
}

export const getAthleteTabs = (tabs) => {
    return {
       type: ActionTypes.SELECT_ATHLETE_TAB,
       payload: tabs,
    }
}

export const editAthleteListFnct = (athlete) => {
    return {
       type: ActionTypes.EDIT_ATHLETE,
       payload: athlete,
    }
}

export const addAthleteFnct = (athlete) => {
    return {
       type: ActionTypes.ADD_ATHLETE,
       payload: athlete,
    }
}

export const deleteAthleteFnct = (athlete) => {
    return {
       type: ActionTypes.DELETE_ATHLETE,
       payload: athlete,
    }
}