export const ActionTypes = {
    GET_BLOG_CATEGORY_LIST: "GET_BLOG_CATEGORY_LIST",
    ADD_BLOG_CATEGORY_LIST: "ADD_CATEGORY_LIST",
    EDIT_BLOG_CATEGORY_LIST: "EDIT_CATEGORY_LIST",
    DELETE_BLOG_CATEGORY_LIST: "DELETE_CATEGORY_LIST"
};

export const getBlogCategoryList = (category) => {
    return {
       type: ActionTypes.GET_BLOG_CATEGORY_LIST,
       payload: category,
    }
};

export const addBlogCategoryList = (category) => {
    return {
        type: ActionTypes.ADD_BLOG_CATEGORY_LIST,
        payload: category,
    }
};

export const editBlogCategoryLists = (category) => {
    return {
       type: ActionTypes.EDIT_BLOG_CATEGORY_LIST,
       payload: category,
    }
};

export const deleteBlogCategoryLists = (category) => {
    return {
        type:ActionTypes.DELETE_BLOG_CATEGORY_LIST,
        payload: category,
    }
};