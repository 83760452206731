/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Form, Button} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";
import { ADD_COUPON_API_URL, PACKAGE_MANAGEMENT, UPDATE_COUPON_API_URL } from "../Shared/constant";
import {
  AddPackageList,
  SelectPackagesTab,
} from "../../redux/actions/packageAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GetCampaign from "../../Api/GetCampaign";
import Scrollbars from "react-custom-scrollbars-2";
import { PostRequestAPI } from "../../Api/PostRequest";
import { AuthenticateResponse } from "../Shared/utils";

const ViewFreeCoupon = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [name, setName] = useState("");
  const [qty, setQty] = useState("");
  const [code, setCode] = useState("");
  const param = useParams();
  const [couponData, setCouponData] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const { accessToken } = useSelector((state) => state.personalReducers);
  const { freeCouponList, CurrenUrlBaseForPackage } = useSelector(
    (state) => state.packageReducers
  );
  const [isAuto, setIsAuto] = useState(false)
  const [abortController, setAbortController] = useState({});
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData(1, 5000, "all");
    return () => {
      if (abortController.abort) {
        abortController.abort();
        setAbortController(new AbortController());
      }
    };
  }, []);

  const fetchData = async (currentPage, userLimit, userType) => {
    const signal = abortController.signal;
    const responce = await GetCampaign( accessToken, "", "all", userLimit, currentPage, { signal } );

    if (responce?.status === 200) {
      setData(responce.data);
    }else{
      AuthenticateResponse(responce)
    }
  };

  const [error, setError] = useState({
    name: "",
    qty: "",
    price: "",
    campaign_id: "",
  });

  const handleSelectChange = (event) => {

    if (event?.target.value) {
      setSelectedCampaign(event?.target.value);
    }
    if(event.target.value != ""){
      setError(prevError => ({
        ...prevError,
        campaign_id: ''
       }));
    }
  };

  const validation = () => {
    let val = true;
    if (name == "") {
      error.name = "Enter Name of Coupon";
      val = false;
    }
    if (qty == "") {
      error.qty = "Enter Quantity ";
      val = false;
    }
    if (!selectedCampaign) {
      error.campaign_id = "Please select campaign";
      val = false;
    }
    if (!isAuto && code === "") {
      error.code = "Enter coupon code ";
      val = false;
    }
    setError((data) => ({
      ...data,
      ...error,
    }));

    return val;
  };
  const validationForUpdate = () =>{
    let val = true;
    if (name == "") {
      error.name = "Enter Name of Coupon";
      val = false;
    }
    if (qty == "") {
      error.qty = "Enter Quantity ";
      val = false;
    }
    setError((data) => ({
        ...data,
        ...error,
      }));
  
      return val;
  }

  useEffect(() => {
    const selectedCoupon = freeCouponList?.data.find(
      (packageItem) => packageItem?.id === param?.coupon_id
    );
    if (selectedCoupon) {
      setCouponData(selectedCoupon);
      setName(selectedCoupon.name || ""); // Set name from selected package
      setQty(selectedCoupon.quantity || "");
      setCode(selectedCoupon.code || "");
      setSelectedCampaign(selectedCoupon?.campaign_id);
      if(selectedCoupon.code){
        setIsAuto(false)
      }
    }
  }, [param?.coupon_id, freeCouponList?.data]);

  const AddPackageFct = async () => {
    if (validation()) {
      const jsonData = {
        name: name,
        quantity: qty,
        campaign_id: selectedCampaign,
        code: code
      };

      const response = await PostRequestAPI(ADD_COUPON_API_URL, jsonData, accessToken);
      if(response?.status == 200){
          dispatch(AddPackageList(response?.data?.data));
          Navigate(PACKAGE_MANAGEMENT, {state:"free_coupon"});
          toast.success(response.msg);
      }else{
        AuthenticateResponse(response);
      }
    }
  };

  const UpdatePackage = async () => {
    if (validationForUpdate()) {
      const jsonData = {
        name: name,
        quantity: qty,
        is_active: couponData?.is_active === true ? 1 : 0,
        coupon_id: couponData?.id,
        code: code
      };
      const response = await PostRequestAPI(UPDATE_COUPON_API_URL, jsonData, accessToken);
      // console.log("response", response)
      if(response?.status == 200){
          toast.success(response.msg);
          dispatch(AddPackageList(response?.data?.data));
          Navigate(PACKAGE_MANAGEMENT, {state:"free_coupon"});
      }else{
        AuthenticateResponse(response);
      }
    }
  };

  const onChangeHandleCode = (e)=>{
    const value = e.target.value;
    const filteredValue = value.replace(/[^a-zA-Z0-9]/g, '')
    if(filteredValue?.length>25 ){
      setCode(code);
    }else{
      setCode(filteredValue.toUpperCase());
    }
    setError(prevError => ({
      ...prevError,
      code: ''
     }));
  }

  return (
    <>
      <ul className="tabcomnsection">
        {CurrenUrlBaseForPackage === "add-package" ? (
          <li
            className={
              CurrenUrlBaseForPackage === "add-package" ? "active" : ""
            }
            onClick={() => dispatch(SelectPackagesTab("add-package"))}
          >
            Add Free Coupon
          </li>
        ) : (
          <>
            <li
              className={
                CurrenUrlBaseForPackage === "edit-package" ? "active" : ""
              }
              onClick={() => dispatch(SelectPackagesTab("edit-package"))}
            >
              Edit Free Coupon
            </li>
          </>
        )}
        <div className="tabheadebuttns">
          {CurrenUrlBaseForPackage === "edit-package" && (
            <Button className="savchanges" onClick={() => UpdatePackage()}>
              Save
            </Button>
          )}
          {CurrenUrlBaseForPackage === "add-package" && (
            <Button className="savchanges" onClick={() => AddPackageFct()}>
              Add
            </Button>
          )}
          <Link className="btn savchanges linksmanage" to={PACKAGE_MANAGEMENT} state={"free_coupon"}>
            Back
          </Link>
        </div>
      </ul>

      <Scrollbars
            style={{ height: "calc(100vh - 162px)" }} 
            className="ScrollbarsWrapper"
            renderView={props => <div {...props} className="view"/>}
        >
      <section className="formsectionset px-3 d-flex flex-column">

        {CurrenUrlBaseForPackage === "add-package" && (
          <>
            <div className="formloginset formeditset">
                <div className="row">
                  <div className="col-md-4">
                    <div className="formgroups">
                      <h4
                        className="label-required"
                        style={{
                          fontSize: "18px",
                        }}
                      >
                        Name of Coupon
                      </h4>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Enter Title"
                        maxLength={100}
                        onChange={(e) => {
                             const inputValue = e.target.value;
                             const newValue = inputValue.replace(/[^A-Za-z\s]/g, '');
                             setName(newValue);
                            if (newValue.trim() === '') {
                           setError(prevError => ({
                           ...prevError,
                           name: 'Enter Name of Coupon'
                          }));
                        } else {
                            setError(prevError => ({
                               ...prevError,
                               name: ''
                           }));
                           }

                       }}

                      />
                      {error?.name && (
                        <div className="danger-colorset">{error?.name}</div>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="formgroups">
                      <h4
                        className="label-required"
                        style={{
                          fontSize: "18px",
                        }}
                      >
                        {" "}
                        Quantity of Coupon
                      </h4>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="number"
                        placeholder="Enter Quantity"
                        value={qty}
                        min={0}
                        onKeyDown={(e) => {
                        if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace')) {
                               e.preventDefault();
                            }
                         }} 
                          // onChange={(e) => setQty(e.target.value)}
                        onChange={(e) => {
                            let inputValue = e.target.value;
                            inputValue = inputValue.replace(/\D/g, '').substring(0, 10);
                            if (inputValue !== '' && parseInt(inputValue) > 0) {
                                setQty(inputValue);
                              } else {
                                setQty(''); 
                                }
                         if (inputValue.trim() === '') {
                           setError(prevError => ({
                           ...prevError,
                           qty: 'Enter Quantity of Coupon'
                          }));
                        } else {
                            setError(prevError => ({
                               ...prevError,
                               qty: ''
                           }));
                           }

                          }}  

                      />
                      {error?.qty && (
                        <div className="danger-colorset">{error?.qty}</div>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="formgroups">
                      <h4
                        className="label-required"
                        style={{
                          fontSize: "18px",
                        }}
                      >
                        Select Campaign for this Coupon
                      </h4>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <Form.Group className="mb-3">
                      <Form.Select
                        onChange={handleSelectChange}
                        placeholder="Select campaign id"
                        value={selectedCampaign}
                      >
                        <option disabled selected value={""}>Select Campaign</option>
                        {data?.length>0 ?(
                          <React.Fragment>
                             {data?.filter((item)=>item?.is_active)?.map((ele, index) => {
                              return (
                                <React.Fragment key={ele?.id}>
                                  <option value={ele?.id}>{ele?.title}</option>
                                </React.Fragment>
                              );
                            })}
                          </React.Fragment>
                        ):(
                          <React.Fragment></React.Fragment>
                        )}
                      </Form.Select>
                    </Form.Group>
                    {error?.campaign_id && (
                      <div className="danger-colorset">
                        {error?.campaign_id}
                      </div>
                    )}
                  </div>
                </div>
            </div>
          </>
        )}

        {CurrenUrlBaseForPackage === "edit-package" ? (
          <>
            <div className="formloginset formeditset">
              <div className="row">
                <div className="col-md-4">
                  <div className="formgroups">
                    <h4
                      className="label-required"
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      Name of Coupon
                    </h4>
                  </div>
                </div>
                <div className="col-md-8">
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      value={name}
                      placeholder="Enter Title"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const newValue = inputValue.replace(/[^A-Za-z\s]/g, ''); // Replace non-letter characters with empty string
                          setName(newValue);
                          if (newValue.trim() === '') {
                            setError(prevError => ({
                            ...prevError,
                            name: 'Enter Name of Coupon'
                            }));
                          } else {
                              setError(prevError => ({
                                ...prevError,
                                name: ''
                            }));
                          }
                       }}
                    />
                    {error?.name && (
                      <div className="danger-colorset">{error?.name}</div>
                    )}
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="formgroups">
                    <h4
                      className="label-required"
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      {" "}
                      Quantity of Coupon
                    </h4>
                  </div>
                </div>
                <div className="col-md-8">
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      value={qty}
                      placeholder="Enter Quantity"
                    onKeyDown={(e) => {
                        if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace')) {
                               e.preventDefault();
                            }
                         }} 
                          // onChange={(e) => setQty(e.target.value)}
                        onChange={(e) => {
                            let inputValue = e.target.value;
                            inputValue = inputValue.replace(/\D/g, '').substring(0, 10);
                            if (inputValue !== '' && parseInt(inputValue) > 0) {
                                setQty(inputValue);
                              } else {
                                setQty(''); 
                                }
                                if (inputValue.trim() === '') {
                           setError(prevError => ({
                           ...prevError,
                           qty: 'Enter Quantity of Coupon'
                          }));
                        } else {
                            setError(prevError => ({
                               ...prevError,
                               qty: ''
                           }));
                           }
                          }}  

                    />
                    {error?.qty && (
                      <div className="danger-colorset">{error?.qty}</div>
                    )}
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="formgroups">
                    <h4
                      className="label-required"
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      Select Campaign for this Coupon
                    </h4>
                  </div>
                </div>

                <div className="col-md-8">{console.log("selectedCampaign", selectedCampaign)}
                    <Form.Group className="mb-3">
                      <Form.Select
                        value={selectedCampaign}
                        placeholder="Select campaign id"
                        disabled={CurrenUrlBaseForPackage === "edit-package"}
                      >
                        <option value={""}>Select Campaign</option>
                        {data?.map((ele, index) => {
                          return (
                            <React.Fragment key={ele?.id}>
                              <option value={ele?.id}>{ele?.title}</option>
                            </React.Fragment>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                </div>
              </div>
            </div>
          </>
        ) : null}
        <div className="formloginset formeditset">
          {!isAuto && <div className="row">
            <div className="col-md-4">
              <div className="formgroups">
                <h4
                  className="label-required"
                  style={{
                    fontSize: "18px",
                  }}
                >
                  Coupon Code
                </h4>
              </div>
            </div>
            <div className="col-md-8">
              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="Enter Coupon Code"
                  value={code}
                  onChange={onChangeHandleCode}
                  disabled={param?.coupon_id}
                />
                 {error.code && (
                <div className="danger-colorset">{error?.code}</div>
              )}
              </Form.Group>
            </div>
          </div>}
        </div>
      </section>
      </Scrollbars>
    </>
  );
};
export default ViewFreeCoupon;
