/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import EditIcon from "../../assets/img/edit.png";
import DeleteIcon from '../../assets/img/delete.png';
import ViewIcon from '../../assets/img/show.png';
import SettingIcon from '../../assets/img/settings.png';
import { Scrollbars } from 'react-custom-scrollbars-2';
import FiltersForm from "../Common/FiltersForm";
import { Link, useNavigate } from "react-router-dom";
import GetFundraise from "../../Api/GetFundraise";
import { useDispatch, useSelector } from "react-redux";
import { getFundraiseList, optionsFundraise, getFundraiseListIDWISE } from "../../redux/actions/fundActions";
import CommonLogout from "../Common/CommonLogout";
import Avatar from "../Common/Avatar";
import moment from "moment";
import CommonModal from "../Common/CommonModal";
import CommonPagination from "../Common/CommonPagination";

const FundraisersManagement = () => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const { fundraiserList } = useSelector((state) => state.fundraiseReducers);
    const { accessToken } = useSelector((state) => state.personalReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [ paginationLength, setPaginationLength ] = useState({});

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
        fetchData(page,userLimit,userType);
    };

    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
        fetchData(1,userLimit,e.target.value);
    };

    // add new fundraise
    const AddFundraise = () => {
        Navigate("/add/fundraiser");
        dispatch(optionsFundraise("edit-fundraiser"))
    };
    const [abortController, setAbortController] = useState({});

    useEffect(() => {
        fetchData(1,userLimit,userType);
        return () => {
            if(abortController.abort){
                abortController.abort();
                setAbortController(new AbortController());
            }
        };
    }, [ userSearch, currentPage ]);

    // Get data from api
    const fetchData = async (currentPage,userLimit,userType) => {
        const signal = abortController.signal;
        setCurrentPage(currentPage)
        const userList = await GetFundraise(accessToken, userSearch, userType, userLimit, currentPage, {signal});
        if(userList?.status === 200) {
            dispatch(getFundraiseList(userList.data));
            dispatch(getFundraiseListIDWISE(userList.data));
            setPaginationLength(userList.pagination);
        } else if(userList?.detail === "Token expired.") {
            CommonLogout();
            dispatch(getFundraiseList([]));
        } else {
            dispatch(getFundraiseList([]));
        };
    };

    // add category function
    const DeleteFundraise = (fundraise) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            description: "Do you really want to delete",
            modalType: "not form",
            button: "Delete Fundraise",
            category: "Delete Fundraise",
            Data: fundraise
        });
    };

    const callUserLimit = (e) =>{
        setUserLimit(e);
        fetchData(1, e,userType)
    }

    return(<>
        <FiltersForm 
            filterType={"Category Fundraiser"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={callUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
            AddFundraise={AddFundraise}
        />
            <div className="tableContent">
                <Scrollbars 
                    style={{ maxHeight: paginationLength?.total_records > 10 ? "calc(100% - 87px)" : "calc(100% - 50px)"}} 
                    className="ScrollbarsWrapper"
                    renderView={props => <div {...props} className="view"/>}
                >
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Picture</th>
                                    <th style={{ width: "20%" }}>Fundraiser Title</th>
                                    <th>Amount</th>
                                    <th>Country</th>
                                    <th>Category</th>  
                                    <th>Created At</th>               
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fundraiserList && fundraiserList?.map((user, index) => {
                                    return(<React.Fragment key={index}>
                                    <tr>
                                        <td>
                                            <Avatar type={"product"} src={user?.cover_image} title={user?.title}/>
                                        </td>
                                        <td>{user?.title}</td>
                                        <td>${user?.amount === null ? "00" : user?.amount} / {user?.raised_amount === null ? "00" : user?.raised_amount}</td>
                                        <td>{user?.country}</td>
                                        <td>{user?.category}</td>
                                        <td>{moment(user.created_at).format('LL')}</td>
                                        <td>
                                            <Link className="btn actionbtn" onClick={() => dispatch(optionsFundraise("view-fundraiser"))} to={"/view/fundraiser/" + user?.slug} >
                                                <Image src={ViewIcon} alt="view icon" />
                                            </Link>
                                            <Link className="btn actionbtn" onClick={() => dispatch(optionsFundraise("edit-fundraiser"))} to={"/edit/fundraiser/" + user?.slug} >
                                                <Image src={EditIcon} alt="edit icon" />
                                            </Link>
                                            <Link className="btn actionbtn" onClick={() => dispatch(optionsFundraise("settings-fundraiser"))} to={"/edit/fundraiser/" + user?.slug} >
                                                <Image src={SettingIcon} alt="edit icon" />
                                            </Link>
                                            <button className="btn actionbtn" onClick={() => DeleteFundraise(user)}>
                                                <Image src={DeleteIcon} alt="delete icon" />
                                            </button>
                                        </td>
                                    </tr>
                                </React.Fragment>)
                                })}
                                {fundraiserList.length === 0 && (<tr><td style={{ textAlign: "center" }} colSpan={7}>Admins fundraise not found..</td></tr>)}
                            </tbody>
                        </table>
                    </div>
                </Scrollbars>
                {/* pagination */}
                {parseInt(paginationLength?.total_records) > 10 && (<CommonPagination 
                    currentPage={currentPage}
                    paginationLength={paginationLength}
                    currentFunction={currentFunction}
                />)}
            </div>
            {showModal?.open && (<CommonModal showModal={showModal} setShowModal={setShowModal} />)}
    </>);
}

export default FundraisersManagement;