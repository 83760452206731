/* eslint-disable */
import { ActionTypes } from "../actions/usersActions";

const initailData = {
    userList: [],
    userListId: {},
};

export const userReducers = (state = initailData, action) => {
    switch (action.type) {
        case ActionTypes.GET_USER_LIST:
            return {
                ...state,
                userList: action.payload,
            };
        case ActionTypes.GET_USERLIST_WISE_ID:
            let arrayuserlist = {};
            action.payload && action.payload.map((elm) => {
                arrayuserlist[elm.id] = elm;
            });
            return {
                ...state,
                userListId: arrayuserlist,
            };
        case ActionTypes.EDIT_USER_LIST:
            const userListOld = [...state.userList]; 
            const updateUserList = userListOld.map((elm) => {
                if(elm?.id === action?.payload?.id) {
                    return{
                        ...elm,
                        first_name : action.payload.first_name,
                        last_name : action.payload.last_name,
                        phone : action.payload.phone,
                        phone_code: action.payload.phone_code,
                        email : action.payload.email,
                        is_active : action.payload.is_active,
                        country: action.payload.country,
                        updated_at : action.payload.updated_at,
                        slug: action.payload.slug
                    }
                }else 
                return elm;
            });
            let arrayuserlistupdate = {};
            updateUserList && updateUserList?.map((elm) => {
                arrayuserlistupdate[elm?.id] = elm;
            });

            return {
                ...state,
                userList: updateUserList,
                userListId: arrayuserlistupdate,
            };
        default:
        return state;
    }
}