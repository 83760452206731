export const PostRequestAPI = async (apiurl, payload, access_token, contentType, responseType) => {
    const headers = {
      'Accept':"application/json",
    }
    if(access_token){
      headers.Authorization = `Bearer ${access_token}`
    }
    if(contentType){
      headers['Content-Type']='multipart/form-data'
    }
    const contentMethod = {
      headers:headers,
    }
    if(responseType){
      contentMethod.responseType = responseType
    }
    const getResponse = window.axios.post(`${apiurl}`, payload, contentMethod).then(function (result) {
      return result;
    }).catch((e)=>e.response);
    return getResponse;
};
