/* eslint-disable */
import React, { useState, useEffect } from "react";
import FiltersForm from "../Common/FiltersForm";
import { useSelector, useDispatch } from "react-redux";
import GetFundraiseApplicationList from "../../Api/GetFundraiseApplicationList";
import { getFundraiseApplicationsList } from "../../redux/actions/fundraiseApllicationActions";
import CommonLogout from "../Common/CommonLogout";
import Scrollbars from "react-custom-scrollbars-2";
import { Form, Image, Badge, Collapse } from "react-bootstrap";
import DeleteIcon from "../../assets/img/delete.png";
import MoreIcon from '../../assets/img/more_options.png';
import CommonPagination from "../Common/CommonPagination";
import moment from "moment";
import CommonModal from "../Common/CommonModal";

const FundraiseApplicationManagement = () => {
    const dispatch = useDispatch();
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { fundraiseApplicationList } = useSelector((state) => state.fundraiseApllicationReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [ paginationLength, setPaginationLength ] = useState({});
    const [ activeTableDetails, setActiveTableDetails ] = useState("");

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
        fetchData(page,userLimit,userType)
    };
    
    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
        fetchData(1,userLimit,e.target.value);
    };
    const [abortController, setAbortController] = useState({});

    useEffect(() => {
        fetchData(1,userLimit,userType);
        return () => {
            if(abortController.abort){
                abortController.abort();
                setAbortController(new AbortController());
            }
        };
    }, [ userSearch]);

    // Get data from api
    const fetchData = async (currentPage,userLimit,userType) => {
        const signal = abortController.signal;
        setCurrentPage(currentPage)
        const FundraiseApplicationLists = await GetFundraiseApplicationList(accessToken, userSearch, userType, userLimit, currentPage, {signal});
        if(FundraiseApplicationLists?.status === 200) {
            dispatch(getFundraiseApplicationsList(FundraiseApplicationLists.data));
            setPaginationLength(FundraiseApplicationLists.pagination);
        } else if(FundraiseApplicationLists?.status === 403) {
            CommonLogout();
            dispatch(getFundraiseApplicationsList([]));
        } else {
            dispatch(getFundraiseApplicationsList([]));
        };
    };

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // fundraise application active or deactive
    const FundraiseAppsActivStatus = (user) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            description: `Do you really want to ${user?.is_active ? 'deactivate' : 'active'}`,
            modalType: "not form",
            button: "Yes",
            category: "fundraise application Active",
            Data: user
        });
    };

    // fundraise application active or deactive
    const FundraiseAppsVerifiedStatus = (user) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            description: `Do you really want to ${user?.is_verified ? 'remove the verification email' : 'verify email'}`,
            modalType: "not form",
            button: "Yes",
            category: "fundraise application verified",
            Data: user
        });
    };

    // fundraise application active or deactive
    const DeleteFundraiseApps = (user) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            description: "Do you really want to delete",
            modalType: "not form",
            button: "Delete Campaign Application",
            category: "fundraise application delete",
            Data: user
        });
    };

    const callUserLimit = (e) =>{
        setUserLimit(e);
        fetchData(1, e,userType)
    }

    return(<>
        <FiltersForm 
            filterType={"application (Name and email)"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={callUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
            // AddBlog={AddBlog}
        />
        <div className="tableContent">
            <Scrollbars 
                style={{ height: paginationLength?.total_records > 10 ? "calc(100% - 87px)" : "calc(100% - 50px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Organization Name</th>
                                <th>Created At</th>
                                <th>Active</th>
                                <th>Verified</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fundraiseApplicationList && fundraiseApplicationList?.map((user, index) => {
                                return(<React.Fragment key={index}>
                                <tr>
                                    <td style={{ width: '10px' }}>
                                        <button 
                                            className={activeTableDetails === user?.id ? "btn actionbtn active" : "btn actionbtn"}
                                            onClick={() => activeTableDetails === user?.id ? ElseMoreDetails() : ViewMoreDetails(user?.id)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={activeTableDetails === user?.id ? true : false}
                                        >
                                            <Image src={MoreIcon} alt="more icon" />
                                        </button>
                                    </td>
                                    <td>{user?.user_name}</td>
                                    <td>{user?.email}</td>
                                    <td>{user?.organization_name}</td>
                                    <td>{moment(user.created_at).format('LL HH:mm A')}</td>
                                    <td>
                                        <Form className="switchbtn">
                                            <Form.Check 
                                                type="switch"
                                                id="custom-switch"
                                                checked={user?.is_active}
                                                onChange={e => {}}
                                                onClick={() => FundraiseAppsActivStatus(user)}
                                            />
                                            <Badge bg={user?.is_active ? "primary" : "danger"}>{user?.is_active ? "Yes" : "No"}</Badge>
                                        </Form>
                                    </td>
                                    <td>
                                        <Form className="switchbtn">
                                            <Form.Check 
                                                type="switch"
                                                id="custom-switch"
                                                checked={user?.is_verified}
                                                onChange={e => {}}
                                                onClick={() => FundraiseAppsVerifiedStatus(user)}
                                            />
                                            <Badge bg={user?.is_verified ? "primary" : "danger"}>{user?.is_verified ? "Yes" : "No"}</Badge>
                                        </Form>
                                    </td>
                                    <td>
                                        <button className="btn actionbtn" onClick={() => DeleteFundraiseApps(user)}>
                                            <Image src={DeleteIcon} alt="delete icon" />
                                        </button>
                                    </td>
                                </tr>
                                <Collapse in={activeTableDetails === user?.id ? true : false}>
                                    <tr className="tablecollapsewraps" id={"tablecollpase"+user?.id}>
                                        <td colSpan="8">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Description</th>
                                                        <td dangerouslySetInnerHTML={{__html: user?.description}}></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </Collapse>
                            </React.Fragment>)
                            })}
                            {fundraiseApplicationList && fundraiseApplicationList.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>Campaign Not Found !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
            {/* pagination */}
            {parseInt(paginationLength?.total_records) > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={paginationLength}
                currentFunction={currentFunction}
            />)}
        </div>
        {showModal?.open && (<CommonModal showModal={showModal} setShowModal={setShowModal} />)}
    </>)
}

export default FundraiseApplicationManagement;