/* eslint-disable */
import { ActionTypes } from "../actions/fundraiseApllicationActions";

const initailData = {
    fundraiseApplicationList: [],
};

export const fundraiseApllicationReducers = (state = initailData, action) => {
    switch (action.type) {
        case ActionTypes.GET_FUNDRAISE_APPLICATIONS_LIST:
            return {
                ...state,
                fundraiseApplicationList: action.payload,
            };
        case ActionTypes.ADD_FUNDRAISE_APPLICATIONS_LIST:
            const fundraiseApplicationListz = [...state.fundraiseApplicationList];
            fundraiseApplicationListz.unshift(action.payload);
            return {
                ...state,
                fundraiseApplicationList: fundraiseApplicationListz,
            };
        case ActionTypes.EDIT_FUNDRAISE_APPLICATIONS:
            const fundraiseApplicationListOld = [...state.fundraiseApplicationList]; 
            const updateFundraiseApplicationList = fundraiseApplicationListOld.map((elm) => {
                if(elm?.id === action?.payload?.id) {
                    return{
                        ...elm,
                        is_verified: action?.payload?.is_verified,
                        is_active: action?.payload?.is_active,
                        slug: action?.payload?.slug,
                    }
                }else 
                return elm;
            });
            return {
                ...state,
                fundraiseApplicationList: updateFundraiseApplicationList,
            };
        case ActionTypes.DELETE_FUNDRAISE_APPLICATIONS:
            const fundraiseApplicationListOlds = [...state.fundraiseApplicationList]; 
            const NewUpdatedfundraiseApplicationList = [];
            fundraiseApplicationListOlds && fundraiseApplicationListOlds.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedfundraiseApplicationList.push(elm);
                }
            });
            return {
                ...state,
                fundraiseApplicationList: NewUpdatedfundraiseApplicationList,
            };
        default:
        return state;
    }
}