/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import EditIcon from "../../assets/img/edit.png";
import DeleteIcon from "../../assets/img/delete.png";
import Form from 'react-bootstrap/Form';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Badge from 'react-bootstrap/Badge';
import FiltersForm from "../Common/FiltersForm";
import GetBlogCategory from "../../Api/GetBlogCategory";
import { useSelector, useDispatch } from "react-redux";
import { getBlogCategoryList } from "../../redux/actions/blogCategoryActions";
import CommonModal from "../Common/CommonModal";
import moment from "moment";
import CommonLogout from "../Common/CommonLogout";
import CommonPagination from "../Common/CommonPagination";

const BlogCategoryManagement = () => {
    const dispatch = useDispatch();
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { blogCategoryList } = useSelector((state) => state.blogCategoryReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [ paginationLength, setPaginationLength ] = useState({});

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    
    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    useEffect(() => {
        fetchData();
    }, [ userSearch, userType, userLimit, currentPage ]);

    // Get data from api
    const fetchData = async () => {
        const blogcategoryList = await GetBlogCategory(accessToken, userSearch, userType, userLimit, currentPage);
        if(blogcategoryList?.status === 200) {
            dispatch(getBlogCategoryList(blogcategoryList.data));
            setPaginationLength(blogcategoryList.pagination);
        } else if(blogcategoryList?.detail === "Token expired.") {
            CommonLogout();
            dispatch(getBlogCategoryList([]));
        } else {
            dispatch(getBlogCategoryList([]));
        };
    };

    // category activate & deactivate function
    const CategoryActivStatus = (user) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            description: `Do you really want to ${user?.is_active ? 'deactivate' : 'active'}`,
            modalType: "not form",
            button: "Yes",
            category: "Blog Category active",
            Data: user
        });
    };

    // category edit function
    const EditBlogCategory = (category) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Edit Blog Category",
            description: "",
            modalType: "form",
            button: "Edit",
            category: "Edit Blog Category",
            Data: category
        });
    };

    // add category function
    const AddBlogCategory = () => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Add Blog Category",
            description: "",
            modalType: "form",
            button: "Add",
            category: "Add Blog Category",
        });
    };

    // delete category function
    const DeleteBlogCategory = (category) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            description: "Do you really want to delete",
            modalType: "not form",
            button: "Delete Blog category",
            category: "Delete Blog category",
            Data: category
        });
    };

    return(<>
        <FiltersForm 
            filterType={"Blog Category (Name)"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
            AddBlogCategory={AddBlogCategory}
        />
        <div className="tableContent">
            <Scrollbars 
                style={{ maxHeight: paginationLength?.total_records > 10 ? "calc(100% - 87px)" : "calc(100% - 50px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Created At</th>
                                <th>Active</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {blogCategoryList && blogCategoryList?.map((user, index) => {
                                return(<React.Fragment key={index}>
                                <tr>
                                    <td>{user?.name}</td>
                                    <td>{moment(user.created_at).format('LL')}</td>
                                    <td>
                                        <Form className="switchbtn">
                                            <Form.Check 
                                                type="switch"
                                                id="custom-switch"
                                                checked={user?.is_active}
                                                onChange={e => {}}
                                                onClick={() => CategoryActivStatus(user)}
                                            />
                                            <Badge bg={user?.is_active ? "primary" : "danger"}>{user?.is_active ? "Yes" : "No"}</Badge>
                                        </Form>
                                    </td>
                                    <td>
                                        <button className="btn actionbtn" onClick={() => EditBlogCategory(user)}>
                                            <Image src={EditIcon} alt="edit icon" />
                                        </button>
                                        <button className="btn actionbtn" onClick={() => DeleteBlogCategory(user)}>
                                            <Image src={DeleteIcon} alt="delete icon" />
                                        </button>
                                    </td>
                                </tr>
                            </React.Fragment>)
                            })}
                            {blogCategoryList && blogCategoryList.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={4}>Blog category not found.</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>

            {/* pagination */}
            {parseInt(paginationLength?.total_records) > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={paginationLength}
                currentFunction={currentFunction}
            />)}
        </div>
        {showModal?.open && (<CommonModal showModal={showModal} setShowModal={setShowModal} />)}
    </>)
}

export default BlogCategoryManagement;