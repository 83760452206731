/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { ADD_CAMPAIGN_MAIL, ADMIN_BLOG_MANAGEMENT_URL, GET_CAMPAIGN_MAIL, MAIL_CAMPAIGN_MANAGEMENT, UPDATE_CAMPAIGN_MAIL } from '../Shared/constant'
import { Button, Form, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import ReactQuill from 'react-quill'
import UploadFile from "../../Api/UploadFile";
import LoaderImage from "../../assets/img/spinner_transferent.svg";
import DefaultImage from "../../assets/img/DefaultProduct.jpg";
import UploadImageIcon from "../../assets/img/uploadImage.png";
import GetCampaign from '../../Api/GetCampaign'
import { setStoreCampaignListOption } from '../../redux/actions/campaignAction'
import { PostRequestAPI } from '../../Api/PostRequest'
import { toast } from 'react-toastify'
import { GetRequestCallAPI } from '../../Api/GetRequest'

export default function AddEmailTemplate() {
    const modules = {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{ 'align': [] }],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image'],
          ['clean'],
        ],
    };
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ];
    const location = useLocation();
    const param = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { CurrenUrlBase, campaignOptionList } = useSelector((state) => state.campaignReducers);
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [ SponsorsLogo, setSponsorsLogo ] = useState([]);
    const [ SponsoredBy, setSponsoredBy ] = useState([]);
    const [ LoaderPrecented, setLoaderPrecented ] = useState(false);

    const [formData, setFormData] = useState({
        subject:"",
        body:"",
        presented_by:null,
        campaign_id:"",
        sponsored_by:[],
    });
    const [error, setError] = useState({
        subject:"",
        body:"",
        presented_by:[],
    });

    
    useEffect(()=>{
        if(param?.campaign_mail_id && location?.state){
            console.log("location?.state", location?.state)
            setFormData((data)=>({
                ...data,
                subject: location?.state?.subject,
                body: location?.state?.body?.replace("_:_campain_name_:_", "{campaign_name}")?.replace("_:_email_:_", "{email}")?.replace("_:_name_:_", "{name}")?.replace("_:_amount_:_", "{amount}")?.replace( "_:_campaign_name_:_", "{campaign_name}"),
                // presented_by: location?.state?.presented_by,
                campaign_id: location?.state?.campaign_id,
                type: location?.state?.type,
                campaign: location?.state?.campaign,
                presented_by: location?.state?.presented_by,
            }))
            // getMailCampaignDetails(param?.campaign_mail_id)
            setSponsorsLogo(location?.state?.presented_by)
            setSponsoredBy(location?.state?.sponsored_by)
        }
    },[])

    console.log("location23443243", SponsorsLogo, formData)

    useEffect(()=>{
        if(param?.type && ( param?.type != "email" && param?.type != "popup" )){
            navigate(MAIL_CAMPAIGN_MANAGEMENT);
        }
        if(param?.type && ( param?.type == "email" || param?.type == "popup" )){
            setFormData((data)=>({
                ...data,
                type:param?.type
            }))
        }
    },[param?.type])
    useEffect(()=>{
        fetchData(1, 5000, "all");
    },[])

    // Get data from api
    const fetchData = async (currentPage,userLimit,userType) => {
        const responce = await GetCampaign(accessToken, "", "active", userLimit, currentPage );
        if(responce?.status === 200) {
            dispatch(setStoreCampaignListOption(responce.data));
            // console.log("responce.data",responce.data)
        } else {
            dispatch(setStoreCampaignListOption([]));
        };
    };
    // const getMailCampaignDetails = async (id) =>{
    //     const response = await GetRequestCallAPI(`${GET_CAMPAIGN_MAIL}/${id}`, accessToken);
    //     console.log("response", response)
    // }
    const onChange = (e) =>{
        const cleanedValue = e.target.value.replace(/\s+/g, ' ');
        e.target.value = cleanedValue;
        if(e.target.value == " "){
            e.target.value = "";
        }
        const inputValue = {
            [e.target.name]:e.target.value
        }
        setFormData((data)=>({
            ...data,
            ...inputValue,
        }))
        setError((data)=>({
            ...data,
            [e.target.name]:""
        }))
    }
    const allowedFileTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];

    const UploadImage = async (e, url) => {
        const files = e.target.files[0];
        const maxFileSize = 5 * 1024 * 1024; 
        if (files.size > maxFileSize) {
            toast.error("File size should not exceed 5MB");
            return; 
        }
        if (!allowedFileTypes.includes(files.type)) {
            toast.info('Invalid file type. Please select an image file (jpg, jpeg, png, webp).');
            e.target.value = null;
            return;
        }
        const formData1 = new FormData();
        formData1.append("file", files, files.name);
        if(url === "SponsorsLogo"){
            setUploadFileLoader(true);
        }else{
            setLoaderPrecented(true);
        }
        const fileresponse = await UploadFile(accessToken, formData1);
        console.log("fileresponse", fileresponse)
        if(fileresponse) {
            // setFormData((data)=>({
            //     ...data,
            //     presented_by: fileresponse,
            // }))
            if(url === "SponsorsLogo"){
                const oldImg = [];
                SponsorsLogo?.map((item)=>{
                    oldImg.push(item);
                })
                oldImg.push(fileresponse?.file_url_400);
                setSponsorsLogo(oldImg);
            }else{
                const oldImg = [];
                SponsoredBy?.map((item)=>{
                    oldImg.push(item);
                })
                oldImg.push(fileresponse?.file_url_400);
                setSponsoredBy(oldImg);
            }
        };
        e.target.value = null;
        setUploadFileLoader(false);
        setLoaderPrecented(false);
    }
    

    const callRemoveSponsorsLogo = (e) =>{
        const items = SponsorsLogo?.filter((item, index)=>index !== e);
        setSponsorsLogo(items);
        
    }
    const callRemoveSponsorsBy = (e) =>{
        const items = SponsoredBy?.filter((item, index)=>index !== e);
        setSponsoredBy(items);
        
    }
    console.log("filterFormData",SponsorsLogo)
    // console.log("SponsorsLogo",SponsorsLogo)
    const validation = () =>{
        let val = true;
        const amountError = formData?.body?.includes("{amount}");
        const campaign_name = formData?.body?.includes("{campaign_name}");
        const name = formData?.body?.includes("{name}");
        const email = formData?.body?.includes("{email}");
        console.log("name", name, formData?.body, email)
        if(param?.campaign_mail_id && location?.state){
            if(formData?.type == "email"){
                if(!amountError || !campaign_name || !name){
                    error.body = <React.Fragment>Please enter <b className='px-1'>{`{name}`}</b>, <b className='px-1'>{`{amount}`}</b> and <b className='px-1'>{`{campaign_name}`}</b> within these brackets to specify the dynamic donation content.</React.Fragment>;
                    val = false;
                }
            }else{
                if(!amountError || !email){
                    error.body = <React.Fragment>Please enter <b className='px-1'>{`{email}`}</b>, <b className='px-1'>{`{amount}`}</b> and <b className='px-1'>{`{campaign_name}`}</b> within these brackets to specify the dynamic donation content.</React.Fragment>
                    val = false;
                }
            }
        }else{
            if(param?.type == "email"){
                if(!amountError || !campaign_name || !name){
                    error.body = <React.Fragment>Please enter <b className='px-1'>{`{name}`}</b>, <b className='px-1'>{`{amount}`}</b> and <b className='px-1'>{`{campaign_name}`}</b> within these brackets to specify the dynamic donation content.</React.Fragment>;
                    val = false;
                }
            }else{
                if(!amountError || !email){
                    error.body = <React.Fragment>Please enter <b className='px-1'>{`{email}`}</b>, <b className='px-1'>{`{amount}`}</b> and <b className='px-1'>{`{campaign_name}`}</b> within these brackets to specify the dynamic donation content.</React.Fragment>
                val = false;
            }
            }
        }
        if(formData?.subject == ""){
            error.subject = "Enter subject";
            val = false;
        }
        if(formData?.campaign_id == ""){
            error.campaign_id = "Please select campaign";
            val = false;
        }
        if(formData?.body == "<p><br></p>" || formData?.body == ""){
            error.body = "Enter body";
            val = false;
        }
        setError((data)=>({
            ...data,
            ...error,
        }))
        return val;
    }
    const onSubmit = async ( e ) =>{
        e.preventDefault();
        if(validation()){
            const campaign = campaignOptionList?.find((item)=>item?.value == formData?.campaign_id);
            const replaceBody = formData?.body //?.replace("{email}", "_:_email_:_")?.replace("{name}", "_:_name_:_")?.replace("{amount}", "_:_amount_:_")?.replace("{campaign_name}", "_:_campaign_name_:_")
            // console.log("replaceBody", replaceBody, campaign?.title)
            const payload = {
                campaign_id: formData?.campaign_id,
                campaign:campaign?.slug,
                type: param?.type,
                subject: formData?.subject,
                body: replaceBody,
                presented_by: SponsorsLogo,
                sponsored_by: SponsoredBy,
            }
            let URL = ADD_CAMPAIGN_MAIL;
            if(param?.campaign_mail_id && location?.state){
                payload.campaign_mail_id = param?.campaign_mail_id ;
                delete payload.campaign;
                delete payload.campaign_id;
                delete payload.type;

                URL = UPDATE_CAMPAIGN_MAIL;
            }
            const response = await PostRequestAPI(URL, payload, accessToken)
            console.log("response",response)
            if(response?.status == 200){
                toast.success(response?.data?.msg);
                navigate(MAIL_CAMPAIGN_MANAGEMENT, { replace: true })
            }else{
                if(response?.data?.error){
                    toast.error(response?.data?.error)
                }
                if(response?.data?.error?.type){
                    toast.info(response?.data?.error?.type)
                }
                if(response?.data?.error?.body){
                    toast.info(response?.data?.error?.body)
                }
                if(response?.data?.error?.campaign){
                    toast.info(response?.data?.error?.campaign)
                }
                if(response?.data?.error?.campaign_id){
                    toast.info(response?.data?.error?.campaign_id)
                }
                if(response?.data?.error?.subject){
                    toast.info(response?.data?.error?.subject)
                }
                if(response?.data?.error?.presented_by){
                    toast.info(response?.data?.error?.presented_by)
                }
                if(response?.data?.error?.type){
                    toast.info(response?.data?.error?.type)
                }
            }
        }
    }
    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
        ['link', 'image', 'video', 'formula'],
      
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction
      
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],
      
        ['clean']                                         // remove formatting button
      ];
      const ClearFormDetails = () =>{
        setFormData({
            subject:"",
            body:"",
            presented_by:null,
            campaign_id:""
        })
        setError({
            subject:"",
            body:"",
            presented_by:[],
        })
      }
      const handleCampaignTypeClick = (type) => {
        ClearFormDetails();
        navigate(`/add${MAIL_CAMPAIGN_MANAGEMENT}/${type}`);
    };
    
    console.log("formData",formData)
    return (
    <>
        <ul className="tabcomnsection">
            {param?.campaign_mail_id && location?.state ? (
                <React.Fragment>
                    <li className={ "active text-capitalized"} >
                        {formData?.type} Campaign
                    </li>
                </React.Fragment>
            ):(
                <React.Fragment>
                    <li className={param?.type == "email" ? "active":""}  onClick={() => handleCampaignTypeClick('email')}>
                            Email Campaign
                    </li>
                    <li className={param?.type == "popup" ? "active":""} onClick={() => handleCampaignTypeClick('popup')}>
                            Popup Campaign
                    </li>
                </React.Fragment>
            )}
            <div className="tabheadebuttns">
            
                <Button className="savchanges" onClick={onSubmit}>Save</Button>
                <Link className="btn savchanges linksmanage" to={MAIL_CAMPAIGN_MANAGEMENT} >Back</Link>
            </div>

        </ul>
        <section className="formsectionset">
        <div className="tabcontent">
            <Scrollbars
                    style={{ height: "calc(100vh - 162px)" }} 
                    className="ScrollbarsWrapper"
                    renderView={props => <div {...props} className="view"/>}
                >
                        <div className="formloginset formeditset">
                            <form onSubmit={onSubmit} className="row">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-required">Select Campaign</h4>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="mb-3" >
                                            <Form.Select 
                                                type="text" 
                                                value={formData?.campaign_id} 
                                                onChange={onChange} 
                                                placeholder="Select campaign_id" 
                                                name='campaign_id'
                                                disabled={param?.campaign_mail_id && location?.state}
                                            >
                                                <option value={""} disabled >Select Campaign</option>
                                                {campaignOptionList?.map((item, index)=>(
                                                    <React.Fragment key={index}>
                                                        <option value={item?.value} >{item?.label}</option>
                                                    </React.Fragment>
                                                ))}
                                            </Form.Select>
                                            {error?.campaign_id && <div className="danger-colorset">{error?.campaign_id}</div>}
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-required">Subject</h4>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="mb-3" >
                                            <Form.Control 
                                                type="text" 
                                                value={formData?.subject} 
                                                onChange={onChange} 
                                                placeholder="Enter subject" 
                                                name='subject'
                                            />
                                            {error?.subject && <div className="danger-colorset">{error?.subject}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-required">Body</h4>
                                        </div>
                                        {param?.campaign_mail_id && location?.state ? (
                                            <React.Fragment>
                                                {formData?.type == "email" && (
                                                    <small>Please enter <b className='px-1'>{`{name}`}</b>, <b className='px-1'>{`{amount}`}</b> and <b className='px-1'>{`{campaign_name}`}</b>  within these brackets to specify the dynamic donation content.</small>
                                                )}
                                                {formData?.type == "popup" && (
                                                    <small><React.Fragment>Please enter <b className='px-1'>{`{email}`}</b>, <b className='px-1'>{`{amount}`}</b> and <b className='px-1'>{`{campaign_name}`}</b> within these brackets to specify the dynamic donation content.</React.Fragment></small>
                                                )}
                                            </React.Fragment>
                                        ):(
                                            <React.Fragment>
                                                {param?.type == "email" &&(
                                                    <small>Please enter <b className='px-1'>{`{name}`}</b>, <b className='px-1'>{`{amount}`}</b> and <b className='px-1'>{`{campaign_name}`}</b>  within these brackets to specify the dynamic donation content.</small>
                                                )}
                                                {param?.type != "email" &&(
                                                    <small><React.Fragment>Please enter <b className='px-1'>{`{email}`}</b>, <b className='px-1'>{`{amount}`}</b> and <b className='px-1'>{`{campaign_name}`}</b> within these brackets to specify the dynamic donation content.</React.Fragment></small>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </div>
                                    <div className="col-md-8">
                                        <div className="editorBox">
                                            <ReactQuill
                                                value={formData?.body}
                                                onChange={(e)=>onChange({target:{name:"body", value:e}})}
                                                modules={modules}
                                                formats={formats}
                                            />
                                            {error?.body && <div className="danger-colorset">{error?.body}</div>}
                                        </div>
                                    </div>
                                </div>
                                {formData?.type == "email" &&(
                                   <React.Fragment>
                                     <div className='row'>
                                        <div className="col-md-4">
                                            <div className="formgroups">
                                                <h4>Presented By</h4>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <Form.Group className="mb-3" >
                                                <div className="uploadbuttons me-2 mb-2"
                                                   onClick={() => document.getElementById('fileInput8')?.click()}

                                                >
                                                    <div className={UploadFileLoader ? "fileloader active" : "fileloader"}>
                                                        {UploadFileLoader ? 
                                                        (<Image src={LoaderImage} className="uploadImgIconse uploadimagload" alt="upload image" />) : 
                                                        (<>
                                                            <Image src={UploadImageIcon} className="uploadImgIconse" alt="upload image" />
                                                            <input type="file" id="fileInput8" className="uploadfilInput" onChange={(e) => UploadImage(e, "SponsorsLogo")} accept=".svg, .png, .jpg, .jpeg, .webp"/>
                                                        </>)}
                                                    </div>
                                                    <div className="uploadfilebox">
                                                        <Image src={DefaultImage} alt="upload image" />
                                                    </div>
                                                </div>
                                                {SponsorsLogo?.map((item, index)=> {
                                                    return(<React.Fragment key={index}>
                                                        <div className="imageuploads bg-grey me-2 mb-2">
                                                            <Image src={item} alt="charity avatar" />
                                                            <span className="closeIcon" onClick={() => callRemoveSponsorsLogo(index)}></span>
                                                        </div>
                                                    </React.Fragment>)
                                                })}
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-4">
                                            <div className="formgroups">
                                                <h4>Sponsors By</h4>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <Form.Group className="mb-3" >
                                                <div className="uploadbuttons me-2 mb-2"
                                                  onClick={() => document.getElementById('fileInput9')?.click()}

                                                >
                                                    <div className={LoaderPrecented ? "fileloader active" : "fileloader"}>
                                                        {LoaderPrecented ? 
                                                        (<Image src={LoaderImage} className="uploadImgIconse uploadimagload" alt="upload image" />) : 
                                                        (<>
                                                            <Image src={UploadImageIcon} className="uploadImgIconse" alt="upload image" />
                                                            <input type="file" id="fileInput9" className="uploadfilInput" onChange={(e) => UploadImage(e, "SponsoredBy")} accept=".svg, .png, .jpg, .jpeg, .webp"/>
                                                        </>)}
                                                    </div>
                                                    <div className="uploadfilebox">
                                                        <Image src={DefaultImage} alt="upload image" />
                                                    </div>
                                                </div>
                                                {SponsoredBy?.map((item, index)=> {
                                                    return(<React.Fragment key={index}>
                                                        <div className="imageuploads bg-grey me-2 mb-2">
                                                            <Image src={item} alt="charity avatar" />
                                                            <span className="closeIcon" onClick={() => callRemoveSponsorsBy(index)}></span>
                                                        </div>
                                                    </React.Fragment>)
                                                })}
                                            </Form.Group>
                                        </div>
                                    </div>
                                   </React.Fragment>
                                )}
                            </form>
                    </div> 
                </Scrollbars>
            </div>
        </section>
                
        </>
    )
}
