import React from "react";
import { Image } from "react-bootstrap";
import DummyUserImage from "../../assets/img/dummyimage.png";
import DummyGalleryImage from "../../assets/img/DefaultProduct.jpg";

const Avatar = (props) => {
    const { src, title, type } = props;
    const YouTubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/; // eslint-disable-line

    // normal image and video thumbnail
    const imagePreview = () => {
        if(type === "user") {
            if(src !== "" && src !== null) {
                return src;
            } else {
                return DummyUserImage;
            }
        } else {
            if(src !== "" && src !== null) {
                if(YouTubeRegex.test(src) === true) {
                    return `https://img.youtube.com/vi/${src?.split("/")[src?.split("/").length - 1].replace("watch?v=", "")}/0.jpg`
                } else {
                    return src;
                }
            } else {
                return DummyGalleryImage;
            }
        }
    };

    return(<div className="avatar">
        <Image className="userprofile" src={imagePreview()} alt={title} />
    </div>)
};

export default Avatar;