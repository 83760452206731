/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Button, Dropdown, Image } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars-2';
import 'react-quill/dist/quill.snow.css';
import UploadFileModal from "../Common/UploadFileModal";
import { useDispatch, useSelector } from "react-redux";
import { addFundraiseUpdatesList, editFundraiseUpdateLists, selectFundraiseUpdatesOptions, getFundraiseUpdateList, selectFundraiseUpdate } from "../../redux/actions/fundActions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddFundraiseUpdatesAPI from "../../Api/AddFundraiseUpdatesAPI";
import GetFundraiseUpdateListAPI from "../../Api/GetFundraiseUpdateListAPI";
import EditFundraiseUpdatesAPI from "../../Api/EditFundraiseUpdatesAPI";
import CheckValid from "../Common/CheckValid";
import moment from "moment";
import Avatar from "../Common/Avatar";
import DeleteIcon from '../../assets/img/delete.png';
import EditIcon from '../../assets/img/edit.png'
import MoreMenuIcon from '../../assets/img/more_Menu.png';
import UpdatesEdit from "./UpdatesEdit";
import CommonModal from "../Common/CommonModal";

const Updates = (props) => {
    const { setLoaderStart } = props;
    const dispatch = useDispatch();
    const { fundraiseUpdateSelect, fundraiseUpdatesOptions, fundraiseUpdateList } = useSelector((state) => state.fundraiseReducers);
    const { accessToken, selfInfo } = useSelector((state) => state.personalReducers);
    const { userListId } = useSelector((state) => state.userReducers);
    const LocationUrl = useLocation()?.pathname.split('/')[3];
    const [ coverImage, setCoverImage ] = useState("");
    const [ story, setStory ] = useState("");
    const [ showFileModal, setShowFileModal ] = useState({
        open: false,
        title: "",
    });
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [ donorMail, setDonorMail ] = useState(false);

    // error message
    const [ errorAvatar, setErrorAvatar ] = useState("");
    const [ errorDescription, setErrorDescription ] = useState(false);

    // last key code check and remove value
    const ValueKeyPress = (e) => {
        const code = e.keycode || e.which;
        if(code === 8 && e.target.innerHTML === "<p><br></p>") {
            setStory("");
        };
    };

    // upload media files
    const UploadFiles = () => {
        setShowFileModal({
            ...showFileModal,
            open: !showFileModal?.open,
            title: "Cover Media"
        });
    };

    // get fundraise details
    const fetchData = async () => {
        setLoaderStart(true);
        const fundraisUpdatesget = await GetFundraiseUpdateListAPI(accessToken, LocationUrl);
        if(fundraisUpdatesget?.status === 200) {  
           dispatch(getFundraiseUpdateList(fundraisUpdatesget?.data));
           setTimeout(() => {
                setLoaderStart(false);
           }, 100);
        } else {
           dispatch(getFundraiseUpdateList([]));
           setLoaderStart(false);
        };
    };

    useEffect(() => {
        fetchData();
    }, [ accessToken, LocationUrl ]);

    // edit fudnraise update
    const EditUpdate = (updates) => {
        dispatch(selectFundraiseUpdatesOptions("edit-updates"))
        dispatch(selectFundraiseUpdate(updates));
    };

    // back fundraise updates
    const backUpdates = () => {
        dispatch(selectFundraiseUpdatesOptions("view-updates"))
        dispatch(selectFundraiseUpdate(""));
    };

    // edit updated states
    useEffect(() => {
        setCoverImage((fundraiseUpdateSelect?.image === "" || fundraiseUpdateSelect?.image === null || fundraiseUpdateSelect?.image === undefined) ? "" : fundraiseUpdateSelect?.image)
        setStory((fundraiseUpdateSelect?.description === "" || fundraiseUpdateSelect?.description === null || fundraiseUpdateSelect?.description === undefined) ? "" : fundraiseUpdateSelect?.description)
    }, [ fundraiseUpdateSelect ]);

    // add and edit fundraise updates
    const AddFundraiseUpdates = async () => {
        if((coverImage !== "" && coverImage !== null) && story !== "") {
            setErrorDescription(false);
            if(Object.keys(fundraiseUpdateSelect).length === 0) {
                const jsonData = JSON.stringify({ 
                    image: coverImage,
                    fundraise: LocationUrl,
                    description: story,
                    to_donors: donorMail === false ? true : false, 
                });
                const response = await AddFundraiseUpdatesAPI(accessToken, jsonData);
                if(response?.status === 200){
                    setTimeout(() => {
                        toast.success(response.msg);
                        dispatch(addFundraiseUpdatesList(response?.data));
                        backUpdates();
                    }, 100);
                } else {
                    toast.error(response?.error || response.data.error);
                };
            } else {
                const jsonData = JSON.stringify({ 
                    image: coverImage,
                    id: fundraiseUpdateSelect?.id,
                    description: story
                });
                const response = await EditFundraiseUpdatesAPI(accessToken, jsonData);
                if(response?.status === 200){
                    setTimeout(() => {
                        toast.success(response.msg);
                        dispatch(editFundraiseUpdateLists(response?.data));
                        backUpdates();
                    }, 100);
                } else {
                    toast.error(response?.error || response.data.error);
                };
            }
        } else {
            CheckValid(coverImage, {type: 'coverMedia', error: setErrorAvatar });
            setErrorDescription(true);
        };
    };

    // delete fundraise updates
    const DeleteUpdates = async (updates) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            description: "Do you really want to delete this update",
            modalType: "not form",
            button: "Delete Fundraise Updates",
            category: "Delete Fundraise Updates",
            Data: updates
        });
    };

    // fundraise name
    const updateUserName = (updates) => {
        return updates?.user_id === selfInfo?.id ? "You" : userListId[updates?.user_id]?.first_name + " " + userListId[updates?.user_id]?.last_name;
    };
    
    return(<>
        <ul className="tabcomnsection">
            <li className={fundraiseUpdatesOptions === "view-updates" ? "active" : ""} onClick={() => dispatch(selectFundraiseUpdatesOptions("view-updates"))}>View</li>
            <li className={fundraiseUpdatesOptions === "edit-updates" ? "active" : ""} onClick={() => dispatch(selectFundraiseUpdatesOptions("edit-updates"))}>{Object.keys(fundraiseUpdateSelect).length > 0 ? "Edit" : "Add"}</li>
            <div className="tabheadebuttns">
                <Button className="btn-link savchanges" onClick={() => backUpdates()}>Back</Button>
                {fundraiseUpdatesOptions !== "view-updates" && <Button className="savchanges" onClick={() => AddFundraiseUpdates()}>{Object.keys(fundraiseUpdateSelect).length > 0 ? "Save Changes" : "Add New Update"}</Button>}
            </div>
        </ul>
        {fundraiseUpdatesOptions === "view-updates" ? (<div className="maintabcontent">
            <Scrollbars 
                style={{ height: "calc(100vh - 230px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="updatedescriotionview">
                    <h4 style={{ marginBottom: "20px" }}>Updates ({(fundraiseUpdateList === null && fundraiseUpdateList?.length === 0) ? 0 : fundraiseUpdateList?.length})</h4>
                    <ul className="updatecomment">
                        {(fundraiseUpdateList?.length === 0) ? (<li>No Updates Available</li>) :
                        (<React.Fragment>
                            {fundraiseUpdateList?.map((updates, index) => {
                                return(<li key={index}>
                                    <Avatar type={"user"} src={userListId[updates?.user_id]?.user_avatar} title={userListId[updates?.user_id]?.first_name}/>
                                    <div className="commtsdetail">
                                        <h3>{moment(updates?.created_at).format('LL')} <small>by {updateUserName(updates)}</small></h3>
                                        <Image src={updates?.image} className="updatedImage" alt="" />
                                        <div style={{ fontSize: "13px" }} dangerouslySetInnerHTML={{__html: updates?.description}}></div>
                                    </div>
                                    <Dropdown className="deletUpdates">
                                        <Dropdown.Toggle id="dropdown-basic">
                                            <Image src={MoreMenuIcon} className="rounded" alt="user"/>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => EditUpdate(updates)}>
                                                <Image src={EditIcon} alt="edit icon" />
                                                Edit
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => DeleteUpdates(updates)}>
                                                <Image src={DeleteIcon} alt="delete icon" />
                                                Delete
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>)
                            })}
                        </React.Fragment>
                        )}
                    </ul>
                </div>
            </Scrollbars>
        </div>) : (<UpdatesEdit 
            formLabel={Object.keys(fundraiseUpdateSelect).length > 0  ? "edit updates" : "add updates"}
            coverImage={coverImage}
            story={story} 
            setStory={setStory}
            UploadFiles={UploadFiles}
            ValueKeyPress={ValueKeyPress}
            errorAvatar={errorAvatar}
            errorDescription={errorDescription}
            setDonorMail={setDonorMail}
            donorMail={donorMail}
        />)}
        {showFileModal?.open && (<UploadFileModal setCoverImage={setCoverImage} showFileModal={showFileModal} setShowFileModal={setShowFileModal} />)}
        {showModal?.open && (<CommonModal showModal={showModal} setShowModal={setShowModal} />)}
    </>)
};

export default Updates;