/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Button, Image } from "react-bootstrap";
import Avatar from "../Common/Avatar";
import { useSelector } from "react-redux";
import moment from "moment";
import InfoIcon from "../../assets/img/information.png";
import ProgressBar from "@ramonak/react-progress-bar";
import UpdatesList from "./UpdatesList";
import GetFundraiseDonationsAPI from "../../Api/GetFundraiseDonationsAPI";
import CommonLogout from "../Common/CommonLogout";

const View = (props) => {
    const { selectedFundraise, setMainTab, setLoaderStart } = props;
    const { userListId } = useSelector((state) => state.userReducers);
    const { selfInfo, accessToken } = useSelector((state) => state.personalReducers);
    const [ donationFundraiswiseList, setdonationFundraiswiseList ] = useState([]);
    const YouTubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/; // eslint-disable-line

    // Get data from api
    const fetchData = async () => {
        setLoaderStart(true);
        const FundraisedonationList = await GetFundraiseDonationsAPI(accessToken, selectedFundraise?.slug, 5000, 1);
        if(FundraisedonationList?.status === 200) {
            setdonationFundraiswiseList(FundraisedonationList?.data);
            setLoaderStart(false);
        } else if(FundraisedonationList?.status === 403) {
            CommonLogout();
            setdonationFundraiswiseList([]);
            setLoaderStart(false);
        } else {
            setdonationFundraiswiseList([]);
            setLoaderStart(false);
        };
    };

    useEffect(() => {
        fetchData();
    }, [ selectedFundraise, setMainTab ]);
    
    return(<div className="tabsWrapper">
        <div className="row">
            <div className="col-md-8">
                <div className="funderviewer">
                    <h1>{selectedFundraise?.title}</h1>
                    <div className="bannerfundraise">
                        {YouTubeRegex.test(selectedFundraise?.cover_image) === true ? 
                        (<iframe src={`https://www.youtube.com/embed/${selectedFundraise?.cover_image?.split("/")[selectedFundraise?.cover_image?.split("/").length - 1].replace("watch?v=", "")}`}></iframe>) 
                        : (<Image src={selectedFundraise?.cover_image} alt={"fundraiser image"} />)}
                    </div>
                    <div className="fundraiserDetails">
                        <Avatar type={"user"} src={null} title={"Vivek Rathod"}/>
                        <div className="userdetails">
                            <h5>{selectedFundraise?.owner === selfInfo?.id ? "You" : userListId[selectedFundraise?.owner]?.first_name + " " + userListId[selectedFundraise?.owner]?.last_name} organizing this fundraiser for <span>{selectedFundraise?.fundraise_for}</span> event.</h5>
                        </div>
                    </div>
                    <div className="fundraiseCharitys">
                        <span>Created {moment(selectedFundraise?.created_at).format('LL')}</span>
                        <div className="categorytitle">
                            <img src={InfoIcon} alt="info icon" />
                            {selectedFundraise?.category}
                        </div>
                    </div>
                    <div className="descriptionview" dangerouslySetInnerHTML={{__html: selectedFundraise?.description}}></div>
                    
                    {/* updates list */}
                    <UpdatesList setMainTab={setMainTab} FundraiseUpdates={selectedFundraise?.fundraise_updates}/>

                    {/* words of support */}
                    <div className="updatedescriotionview">
                        <h4>Words of support (2)</h4>
                        <p className="updatecommentpass">Please donate to share words of support.</p>
                        <ul className="updatecomment">
                            <li>
                                <Avatar type={"user"} src={null} title={"Vivek Rathod"}/>
                                <div className="commtsdetail">
                                    <h3>Vivek Rathod</h3>
                                    <p>I wanted to help the family with expenses and I plan to donate more so that they can love on him and worry less about medical bills or any other expenses. It’s by the grace of God he’s still with us. I will continue to pray for him and his family. He certainly is anointed</p>
                                </div>
                            </li>
                            <li>
                                <Avatar type={"user"} src={null} title={"Vivek Rathod"}/>
                                <div className="commtsdetail">
                                    <h3>Tushar Sonsare</h3>
                                    <p>I wanted to help the family with expenses and I plan to donate more so that they can love on him and worry less about medical bills or any other expenses. It’s by the grace of God he’s still with us. I will continue to pray for him and his family. He certainly is anointed</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="fundraisedonatswrps">
                    <div className="progressAmount">
                        <h5><span>${selectedFundraise?.raised_amount === null ? 0 : selectedFundraise?.raised_amount}</span> USD raised of ${selectedFundraise?.amount} goal</h5>
                        <ProgressBar
                            completed={selectedFundraise?.raised_amount === null ? 0 : selectedFundraise?.raised_amount}
                            animateOnRender={true}
                            bgColor={"#02a95c"}
                            baseBgColor={"#eee"}
                            className="progressbar-ammounts"
                            customLabel={selectedFundraise?.raised_amount === null ? '0' : selectedFundraise?.raised_amount}
                            maxCompleted={selectedFundraise?.amount}
                            initCompletedOnAnimation={selectedFundraise?.amount}
                        />
                        <small>2,604 people just donated</small>
                    </div>
                    <ul className="updatecomment">
                        {donationFundraiswiseList.map((elm, index) => {
                            return(<li key={index}>
                                <Avatar type={"user"} src={null} title={"Vivek Rathod"}/>
                                <div className="commtsdetail">
                                    <h3>{elm?.user_name}</h3>
                                    <p><b>${elm?.amount}</b> <span>Recent donation</span></p>
                                </div>
                            </li>)
                        })}
                        {donationFundraiswiseList?.length === 0 && (<div className="nodonations">There is no donation available at this time.</div>)}
                    </ul>
                    {donationFundraiswiseList?.length > 4 && (<Button className="seeallbtn">See All</Button>)}
                </div>
            </div>
        </div>
    </div>);
}

export default View;