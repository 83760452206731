import LogoutAPI from "../../Api/LogoutAPI";
import { useDispatch } from "react-redux";
import { clearRedux, checkLogin } from "../../redux/actions/personalActions";
import { useNavigate } from "react-router-dom";
import { LOGIN_URL } from "../Shared/constant";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CommonLogout = async () => {
    const { accessToken } = useSelector((state) => state.personalReducers);
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const responce = await LogoutAPI(accessToken);
    toast.success(responce.msg);
    dispatch(clearRedux());
    dispatch(checkLogin('no'));
    localStorage.removeItem("accessToken");
    Navigate(LOGIN_URL);
}

export default CommonLogout;