/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';
import moment from "moment";
import { Form, Badge, Image, Collapse } from 'react-bootstrap';
import FiltersForm from "../Common/FiltersForm";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { getContactList } from "../../redux/actions/contactusActions";
import GetContactList from "../../Api/GetContactList";
import DeleteIcon from "../../assets/img/delete.png";
import MoreIcon from '../../assets/img/more_options.png';
import CommonPagination from "../Common/CommonPagination";
import CommonModal from "../Common/CommonModal";

const ContactManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { contactList } = useSelector((state) => state.contactUsReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [ paginationLength, setPaginationLength ] = useState({});

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
        fetchData(page,userLimit,userType)
    };
    
    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
        fetchData(1,userLimit,e.target.value);
    };
    const [abortController, setAbortController] = useState({});

    useEffect(() => {
        fetchData(1,userLimit,userType);
        return () => {
            if(abortController.abort){
                abortController.abort();
                setAbortController(new AbortController());
            }
        };
    }, [ userSearch ]);

    // Get data from api
    const fetchData = async (currentPage,userLimit,userType) => {
        const signal = abortController.signal;
        setCurrentPage(currentPage)
        const ContactList = await GetContactList(accessToken, userSearch.trim(), userType, userLimit, currentPage,{signal});
        if(ContactList?.status === 200) {
            dispatch(getContactList(ContactList.data));
            setPaginationLength(ContactList.pagination);
        } else if(ContactList?.status === 403) {
            CommonLogout();
            dispatch(getContactList([]));
        } else {
            dispatch(getContactList([]));
        };
    };

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // user active or deactive
    const ContactActivStatus = (user, type) => {
        if(type === "active_action") {
            setShowModal({
                ...showModal,
                open: !showModal?.open,
                title: "Are you sure?",
                description: `Do you really want to mark this as ${user?.is_active ? 'inactive?' : 'active?'}`,
                modalType: "not form",
                button: "Yes",
                category: "Contact Active",
                Data: user
            });
        } else if(type === "contacted_action") {
            setShowModal({
                ...showModal,
                open: !showModal?.open,
                title: "Are you sure?",
                description: `${user?.is_contacted ? 'Do you really not want to be contacted about this request?' : 'Do you really want to be contacted about this request?'}`,
                modalType: "not form",
                button: "Yes",
                category: "Contacted Us",
                Data: user
            });
        } else {
            setShowModal({
                ...showModal,
                open: !showModal?.open,
                title: "Are you sure?",
                description: "Do you really want to delete?",
                modalType: "not form",
                button: "Yes",
                category: "Delete contact",
                Data: user
            });
        }
    };

    const callUserLimit = (e) =>{
        setUserLimit(e);
        fetchData(1, e,userType)
    }

    return(<>
        <FiltersForm 
            filterType={"Contact ( Username & email)"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={callUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
        />
        <div className="tableContent">
            <Scrollbars 
                style={{ height: paginationLength?.total_records > 10 ? "calc(100% - 87px)" : "calc(100% - 50px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>User Name</th>
                                <th>Email</th>
                                <th>Created At</th>
                                <th>Active</th>
                                {/* <th>Contacted</th> */}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contactList && contactList?.map((user, index) => {
                                return(<React.Fragment key={index}>
                                <tr className={activeTableDetails === user?.id ? "active" : ""}>
                                    <td style={{ width: '10px' }}>
                                        <button 
                                            className={activeTableDetails === user?.id ? "btn actionbtn active" : "btn actionbtn"}
                                            onClick={() => activeTableDetails === user?.id ? ElseMoreDetails() : ViewMoreDetails(user?.id)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={activeTableDetails === user?.id ? true : false}
                                        >
                                            <Image src={MoreIcon} alt="more icon" />
                                        </button>
                                    </td>
                                    <td>{user?.user_name}</td>
                                    <td>{user?.from_email}</td>
                                    <td>{moment(user.created_at).format('LL HH:mm A')}</td>
                                    <td>
                                        <Form className="switchbtn">
                                            <Form.Check 
                                                type="switch"
                                                id="custom-switch"
                                                checked={user?.is_active}
                                                onChange={e => {}}
                                                onClick={() => ContactActivStatus(user, "active_action")}
                                            />
                                            <Badge bg={user?.is_active ? "primary" : "danger"}>{user?.is_active ? "Yes" : "No"}</Badge>
                                        </Form>
                                    </td>
                                    {/* <td>
                                        <Form className="switchbtn">
                                            <Form.Check 
                                                type="switch"
                                                id="custom-switch"
                                                checked={user?.is_contacted}
                                                onChange={e => {}}
                                                onClick={() => ContactActivStatus(user, "contacted_action")}
                                            />
                                            <Badge bg={user?.is_contacted ? "primary" : "danger"}>{user?.is_contacted ? "Yes" : "No"}</Badge>
                                        </Form>
                                    </td> */}
                                    <td>
                                        <button className="btn actionbtn" onClick={() => ContactActivStatus(user, "delete_action")}>
                                            <Image src={DeleteIcon} alt="delete icon" />
                                        </button>
                                    </td>
                                </tr>
                                <Collapse in={activeTableDetails === user?.id ? true : false}>
                                    <tr className="tablecollapsewraps" id={"tablecollpase"+user?.id}>
                                        <td colSpan="8">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Message</th>
                                                        <td>{user?.message === null ? "-" : user?.message}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </Collapse>
                            </React.Fragment>)
                            })}
                            {contactList && contactList.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>Contact List Not Found !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
            {/* pagination */}
            {parseInt(paginationLength?.total_records) > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={paginationLength}
                currentFunction={currentFunction}
            />)}
        </div>
        {showModal?.open && (<CommonModal showModal={showModal} setShowModal={setShowModal} />)}
    </>)
}

export default ContactManagement;