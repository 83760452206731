/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { MOSAIC_MANAGEMENT, SETTING_MODULE, UPDATE_SETTING_MODULE } from '../Shared/constant'
import { Button, Form, Modal, Image, Table, Badge, FormControl } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import LoaderImage from "../../assets/img/spinner_transferent.svg";
import DefaultImage from "../../assets/img/DefaultProduct.jpg";
import UploadImageIcon from "../../assets/img/uploadImage.png";
import GetCampaign from '../../Api/GetCampaign'
import { setStoreCampaignListOption } from '../../redux/actions/campaignAction'
import { PostRequestAPI } from '../../Api/PostRequest'
import { toast } from 'react-toastify'
import CommonLoader from '../Common/CommonLoader'
import UploadFile from '../../Api/UploadFile'
import { getSettingModuleList, uuidV4 } from '../Shared/utils.jsx'

export default function AddSettingModule() {

    const location = useLocation();
    const param = useParams();
    console.log("param", param)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { CurrenUrlBase, sponsorsData } = useSelector((state) => state.campaignReducers);
    const [ loader, setLoader ] = useState(false);
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [formData, setFormData] = useState({
        sponsors_logo:null,
        sponsors_name:"",
        status: true,
    });
    const [error, setError] = useState({
        sponsors_name:"",
        sponsors_logo:[],
    });

    useEffect(()=>{
        if(param.id){
            const checkFind = sponsorsData?.facefunding_sponsors?.find((item)=>item?.id === param.id);
            if(checkFind){
                setFormData((data)=>({
                    ...data,
                    sponsors_logo: checkFind?.sponsors_logo,
                    sponsors_name: checkFind?.sponsors_name,
                    status: checkFind?.status,
                    created_at: checkFind?.created_at,
                    id: checkFind?.id,
                }))
            }else{
                navigate(SETTING_MODULE,{ state:"sponsors"});
            }
        }
    },[sponsorsData])
    useEffect(()=>{
        fetchList()
    },[]);
    const fetchList = async () =>{
        dispatch(await getSettingModuleList());
    }

    const onChange = (e) => {
        let cleanedValue = e.target.value;
    
        const inputValue = {
            [e.target.name]: cleanedValue
        }
    
        setFormData((data) => ({
            ...data,
            ...inputValue,
        }));
    
        setError((data) => ({
            ...data,
            [e.target.name]: ""
        }));
    }    

    const allowedFileTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];

    const UploadImage = async (e, url) => {
        if (e.target.files?.length) {
            const file = e.target.files[0];
            
            if (!allowedFileTypes.includes(file.type)) {
                toast.info('Invalid file type. Please select an image file (jpg, jpeg, png, webp).');
                e.target.value = null;
                return;
            }
            const maxFileSize = 5 * 1024 * 1024;  
            if (file.size > maxFileSize) { 
                toast.error("File size should not exceed 5MB");
                return;  
            } 
    
            const formData1 = new FormData();
            formData1.append("file", file, file.name);
            setUploadFileLoader(true);
    
            try {
                const response = await UploadFile(accessToken, formData1);
                if (response) {
                    setFormData((data) => ({
                        ...data,
                        sponsors_logo: response,
                    }));
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            } finally {
                e.target.value = null;
                setUploadFileLoader(false);
            }
        }
    };    
    const callRemoveSponsorsLogo = (e) =>{
        setFormData((data)=>({
            ...data,
            sponsors_logo:null
        }))
    }
    const validation = () =>{
        let val = true;
        if(!formData?.sponsors_logo){
            error.sponsors_logo = "Upload sponsors logo";
            val = false;
        }
        if(formData?.sponsors_name == ""){
            error.sponsors_name = "Enter sponsors name";
            val = false;
        }
        setError((data)=>({
            ...data,
            ...error,
        }))
        return val;
    }
    const onSubmit = async ( e ) =>{
        e.preventDefault();
        if(validation()){
            setLoader(true)
            const payload = {
                sponsors_logo: formData?.sponsors_logo,
                sponsors_name: formData?.sponsors_name,
                created_at: new Date().toISOString(),
                id: uuidV4(),
                status: true,
            }
            if(param.id){
                payload.created_at = formData?.created_at;
                payload.id = param.id;
                const oldSponsors = sponsorsData?.facefunding_sponsors?.map((item, index)=>{
                    if(item?.id === param.id){
                        return{
                            ...item,
                            ...payload
                        }
                    }else{
                        return item;
                    }
                });
                const response = await PostRequestAPI(UPDATE_SETTING_MODULE, {data:{facefunding_sponsors: oldSponsors}}, accessToken);
                if(response?.status == 200){
                    toast.success("Sponsors updated successfully");
                    navigate(SETTING_MODULE, { replace: true, state:"sponsors" })
                }
                
            }else{
                const oldSponsors = sponsorsData?.facefunding_sponsors
                oldSponsors.push(payload);
                const response = await PostRequestAPI(UPDATE_SETTING_MODULE, {data:{facefunding_sponsors: oldSponsors}}, accessToken);
                if(response?.status == 200){
                    toast.success("Sponsors added successfully.");
                    navigate(SETTING_MODULE, { replace: true, state:"sponsors" })
                }
                // console.log("response", response)
            }
            setLoader(false)
        }
    }

    return (
    <>
        <ul className="tabcomnsection">
            <li className={ "active text-capitalized"} >
                {param?.id ? "Update Sponsors":"Create Sponsors"}
            </li>
            <div className="tabheadebuttns">
                <Button className="savchanges" onClick={onSubmit} disabled={loader} >{param?.id ?"Update":`Save`}</Button>
                <Link className="btn savchanges linksmanage" to={SETTING_MODULE} state="sponsors" >Back</Link>
            </div>

        </ul>
        <section className="formsectionset">
        <div className="tabcontent">
            <Scrollbars
                    style={{ height: "calc(100vh - 162px)" }} 
                    className="ScrollbarsWrapper"
                    renderView={props => <div {...props} className="view"/>}
                >
                        <div className="formloginset formeditset">
                            <form onSubmit={onSubmit} className="row">
                                
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-required">Sponsors Name</h4>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="mb-3" >
                                            <FormControl
                                                type="text" 
                                                value={formData?.sponsors_name} 
                                                onChange={onChange} 
                                                placeholder="Sponsors Name" 
                                                name='sponsors_name'
                                            />
                                            {error?.sponsors_name && <div className="danger-colorset">{error?.sponsors_name}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-required">Sponsors Image</h4>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="" >
                                            {!formData?.sponsors_logo && (
                                                <div className="uploadbuttons me-2 mb-2"
                                                onClick={() => document.getElementById('fileInput15')?.click()}

                                                >
                                                    <div className={UploadFileLoader ? "fileloader active" : "fileloader"}>
                                                        {UploadFileLoader ? 
                                                        (<Image src={LoaderImage} className="uploadImgIconse uploadimagload" alt="upload image" />) : 
                                                        (<>
                                                            <Image src={UploadImageIcon} className="uploadImgIconse" alt="upload image" />
                                                            <input type="file" id="fileInput15" className="uploadfilInput" onChange={(e) => UploadImage(e, "SponsorsLogo")} accept=".png, .jpg, .jpeg, .webp"/>
                                                        </>)}
                                                    </div>
                                                    <div className="uploadfilebox">
                                                        <Image src={DefaultImage} alt="upload image" />
                                                    </div>
                                                </div>
                                            )}
                                            {formData?.sponsors_logo && (
                                                <React.Fragment>
                                                    <div className="imageuploads bg-grey me-2 mb-2">
                                                        <Image src={formData?.sponsors_logo?.view_file_url} alt="charity avatar" />
                                                        <span className="closeIcon" onClick={() => callRemoveSponsorsLogo()}></span>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </Form.Group>
                                        {error?.file && <div className="danger-colorset">{error?.file}</div>}
                                    </div>
                                </div>
                        </form>
                    </div> 
                </Scrollbars>
            </div>
        </section>
        {loader && <CommonLoader classNameS={"charityLoader"}  />}
        </>
    )
}
